import { useEffect, useState } from "react";
import { effectButton } from "./BookingFuncions";
import { ReactComponent as IconNxtLvlRoom } from "../../assets/icons/booking/23_icon_next_level_room.svg";
import { ReactComponent as IconRetroRoom } from "../../assets/icons/booking/24_icon_retro_room.svg";
import { ReactComponent as IconPlatinoRoom } from "../../assets/icons/booking/25_icon_platino_room.svg";
import { sendMail } from "../../services/Booking";
import { validateMail, validateString, validatePhone } from "../../utils/Validators";
import { IDCompany } from "../../global/global";
import { getCurrentStringDate } from "../../utils/Formats";
import { Fade } from "react-awesome-reveal";
import BookingModel from "../../models/Booking";
import Select from "../../components/select/Select";
import ImageTop from "../../assets/images/booking/21_img_reservacion.png";
import IconCalendar from "../../assets/icons/booking/22_icon_reservas.png";
import IconVersusRoom from "../../assets/icons/booking/61_versus_icon.svg";
import IconImmersiveRoom from "../../assets/icons/booking/62_inmersive_icon.svg";

const BookingForm = ({
    friends,
    duration,
    venues,
    events,
    statusPop,
    onSuccess,
    onWarning,
    onStatus
}: {
    friends: Array<Object>,
    duration: Array<Object>,
    venues: Array<Object>,
    events: Array<Object>,
    statusPop: any,
    onSuccess: any,
    onWarning: any,
    onStatus: any
}) => {

    // console.log(friends, "friends")
    const [terms, setTerms] = useState<boolean>(false);
    const [currentDate, setCurrentDate] = useState<string>("");
    // console.log(currentDate)
    const [booking, setBooking] = useState({
        companyID: { value: `${IDCompany}`, isCorrect: false },
        venueID: { value: "", isCorrect: false },
        userName: { value: "", isCorrect: false },
        userMail: { value: "", isCorrect: false },
        reserveRoom: { value: "", isCorrect: false },
        numberOfPeople: { value: 0, isCorrect: false },
        reservationDate: { value: "", isCorrect: false },
        reservationHour: { value: "", isCorrect: false },
        reservationTime: { value: "", isCorrect: false },
        userPhone: { value: "", isCorrect: false },
        durationTime: duration
    });

    const [reservation, setReservation] = useState({
        hoursR: { value: "", isCorrect: false },
        minutesR: { value: "", isCorrect: false }
    })

    const hours = [
        { label: '12', value: "12" },
        { label: '13', value: "13" },
        { label: '14', value: "14" },
        { label: '15', value: "15" },
        { label: '16', value: "16" },
        { label: '17', value: "17" },
        { label: '18', value: "18" },
        { label: '19', value: "19" },
        { label: '20', value: "20" },
    ]
    const minutes = [
        { label: '00', value: "00" },
        { label: '01', value: "01" },
        { label: '02', value: "02" },
        { label: '03', value: "03" },
        { label: '04', value: "04" },
        { label: '05', value: "05" },
        { label: '06', value: "06" },
        { label: '07', value: "07" },
        { label: '08', value: "08" },
        { label: '09', value: "09" },
        { label: '10', value: "10" },
        { label: '11', value: "11" },
        { label: '12', value: "12" },
        { label: '13', value: "13" },
        { label: '14', value: "14" },
        { label: '15', value: "15" },
        { label: '16', value: "16" },
        { label: '17', value: "17" },
        { label: '18', value: "18" },
        { label: '19', value: "19" },
        { label: '20', value: "20" },
        { label: '21', value: "21" },
        { label: '22', value: "22" },
        { label: '23', value: "23" },
        { label: '24', value: "24" },
        { label: '25', value: "25" },
        { label: '26', value: "26" },
        { label: '27', value: "27" },
        { label: '28', value: "28" },
        { label: '29', value: "29" },
        { label: '30', value: "30" },
        { label: '31', value: "31" },
        { label: '32', value: "32" },
        { label: '33', value: "33" },
        { label: '34', value: "34" },
        { label: '35', value: "35" },
        { label: '36', value: "36" },
        { label: '37', value: "37" },
        { label: '38', value: "38" },
        { label: '39', value: "39" },
        { label: '40', value: "40" },
        { label: '41', value: "41" },
        { label: '42', value: "42" },
        { label: '43', value: "43" },
        { label: '44', value: "44" },
        { label: '45', value: "45" },
        { label: '46', value: "46" },
        { label: '47', value: "47" },
        { label: '48', value: "48" },
        { label: '49', value: "49" },
        { label: '50', value: "50" },
        { label: '51', value: "51" },
        { label: '52', value: "52" },
        { label: '53', value: "53" },
        { label: '54', value: "54" },
        { label: '55', value: "55" },
        { label: '56', value: "56" },
        { label: '57', value: "57" },
        { label: '58', value: "58" },
        { label: '59', value: "59" },
    ]

    const onResponseSelect = (opt: any) => {
        let arrCurrent: Array<Object> = [];

        if (opt.target.name === "numberOfPeople") {
            arrCurrent = friends;
        } else if (opt.target.name === "reservationTime") {
            arrCurrent = duration;
        } else if (opt.target.name === "venueID") {
            arrCurrent = venues;
        }
        setBooking({ ...booking, [opt.target.name]: { value: Object(arrCurrent[opt.target.value]).value, isCorrect: true } });
    }

    const onResponseHour = (opt: any) => {
        setReservation({ ...reservation, hoursR: { value: Object(hours[opt.target.value]).value, isCorrect: true } });
    }
    const onResponseMinutes = (opt: any) => {
        setReservation({ ...reservation, minutesR: { value: Object(minutes[opt.target.value]).value, isCorrect: true } });
    }


    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        // console.log(evt.target.name)
        if (evt.target.name === "reservationDate") {


            if (booking.venueID.isCorrect) {

                if (new Date().getFullYear() <= (new Date(evt.target.value).getFullYear() + 1)) {


                    let currentVenue: string = "";

                    let selectVenue: any = null;
                    if (window.innerWidth <= 640) {
                        selectVenue = document.getElementById("select-arenaM") as HTMLSelectElement | null;
                    } else {
                        selectVenue = document.getElementById("select-arenaD") as HTMLSelectElement | null;
                    }
                    if (selectVenue !== null) currentVenue = `${Object(venues[Number(selectVenue.value)]).data.venueName}`;

                    const eventFound = events.find((evtEle: any) => {
                        if (evtEle.eventDate === evt.target.value) {
                            const venueExist = evtEle.eventVenue.find((vneEle: string) => vneEle === currentVenue);
                            if (venueExist) return evtEle
                        }
                    });

                    if (eventFound) {
                        onWarning(true, "No se puede reservar en esa fecha ya que existe actualmente un evento de Arena Movistar");

                        let inputDate: any = null;
                        if (window.innerWidth <= 640) {
                            inputDate = document.getElementById("inputdate-arenaM") as HTMLSelectElement | null;
                        } else {
                            inputDate = document.getElementById("inputdate-arenaD") as HTMLSelectElement | null;
                        }
                        if (inputDate !== null) inputDate.value = "";
                    } else {
                        document.getElementsByName(evt.target.name)[0].style.borderColor = "rgb(243 244 246)";
                        setBooking({ ...booking, [evt.target.name]: { value: evt.target.value, isCorrect: true } });
                    }

                }

            } else {
                onWarning(true, "Favor de seleccionar primero la Sucursal");
                let inputDate: any = null;
                if (window.innerWidth <= 640) {
                    inputDate = document.getElementById("inputdate-arenaM") as HTMLSelectElement | null;
                } else {
                    inputDate = document.getElementById("inputdate-arenaD") as HTMLSelectElement | null;
                }
                if (inputDate !== null) inputDate.value = "";
            }

        } else {
            let validateInput: boolean = true;
            // console.log(evt.target.name)
            if (evt.target.name === "userName" || evt.target.name === "reserveRoom") {
                validateInput = validateString(evt.target.value);
            } else if (evt.target.name === "userMail") {
                validateInput = validateMail(evt.target.value);
            } else if (evt.target.name === "userPhone") {
                validateInput = validatePhone(evt.target.value);
                console.log(validateInput, "entro")
            } else {
                validateInput = true;
            }

            if (!validateInput) {
                document.getElementsByName(evt.target.name)[0].style.borderColor = "red";
                setBooking({ ...booking, [evt.target.name]: { value: evt.target.value, isCorrect: false } });
            } else {
                document.getElementsByName(evt.target.name)[0].style.borderColor = "rgb(243 244 246)";
                setBooking({ ...booking, [evt.target.name]: { value: evt.target.value, isCorrect: true } });
            }
        }
    }

    const onClickCategory = (option: string) => {
        const specificTime = [
            { label: "1 hora", value: "1 hora" },
            { label: "3 horas", value: "3 horas" },
            { label: "Todo el día", value: "Todo el día" }
        ]
        const durationOptions = [
            { label: "1 hora", value: "1 hora" },
            { label: "1 hora y media", value: "1 hora y media" },
            { label: "3 horas", value: "3 horas" },
            { label: "Todo el día", value: "Todo el día" }
        ];
        if (option === "Retro Room" || option === "Immersive Zone") {
            duration = specificTime
        } else {
            duration = durationOptions
        }
        setBooking({ ...booking, reserveRoom: { value: option, isCorrect: true }, durationTime: duration });
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();


        if (
            booking.userName.isCorrect &&
            booking.userMail.isCorrect &&
            booking.reserveRoom.isCorrect &&
            booking.numberOfPeople.isCorrect &&
            booking.reservationDate.isCorrect &&
            booking.reservationTime.isCorrect &&
            booking.userPhone.isCorrect && terms
        ) {
            onStatus(true);


            if (reservation.hoursR.isCorrect && reservation.minutesR.isCorrect) {
                const bookingData: BookingModel = {
                    companyID: booking.companyID.value,
                    venueID: booking.venueID.value,
                    userName: booking.userName.value,
                    userMail: booking.userMail.value,
                    reserveRoom: booking.reserveRoom.value,
                    numberOfPeople: booking.numberOfPeople.value,
                    reservationDate: booking.reservationDate.value,
                    reservationHour: `${reservation.hoursR.value}:${reservation.minutesR.value}`,
                    reservationTime: booking.reservationTime.value,
                    userPhone: booking.userPhone.value
                }

                new Promise<void>((resolve, reject) => {
                    sendMail(bookingData).then(res => {
                        onStatus(false);
                        if (res && res.data) {
                            onSuccess(true, `¡Reservación hecha correctamente! \n (Esta reservación puede estar sujeta a cambios inesperados por parte de Arena Movistar)`);
                            setBooking({
                                ...booking,
                                companyID: { value: `${IDCompany}`, isCorrect: false },
                                venueID: { value: "", isCorrect: false },
                                userName: { value: "", isCorrect: false },
                                userMail: { value: "", isCorrect: false },
                                reserveRoom: { value: "", isCorrect: false },
                                numberOfPeople: { value: 0, isCorrect: false },
                                reservationDate: { value: "", isCorrect: false },
                                reservationHour: { value: "", isCorrect: false },
                                reservationTime: { value: "", isCorrect: false }
                            });
                            setTerms(false);

                            const inputDate1 = document.getElementById("inputdate-arenaM") as HTMLSelectElement | null;
                            const inputDate2 = document.getElementById("inputdate-arenaD") as HTMLSelectElement | null;
                            const selectVenue1 = document.getElementById("select-arenaM") as HTMLSelectElement | null;
                            const selectVenue2 = document.getElementById("select-arenaD") as HTMLSelectElement | null;

                            if (inputDate1 !== null) inputDate1.value = "";
                            if (inputDate2 !== null) inputDate2.value = "";
                            if (selectVenue1 !== null) selectVenue1.value = "";
                            if (selectVenue2 !== null) selectVenue2.value = "";

                            const numberOfPeopleInput = document.getElementsByName("numberOfPeople") as any;
                            if (numberOfPeopleInput !== null) for (let i = 0; i < numberOfPeopleInput.length; i++) numberOfPeopleInput[i].value = "";

                            const reservationTimeInput = document.getElementsByName("reservationTime") as any;
                            if (reservationTimeInput !== null) for (let i = 0; i < reservationTimeInput.length; i++) reservationTimeInput[i].value = "";

                            const termsInput = document.getElementsByName("terms") as any;
                            if (termsInput !== null) for (let i = 0; i < termsInput.length; i++) termsInput[i]['checked'] = false;
                        } else {
                            statusPop(true, "error");
                        }
                    });
                    setTimeout(function () {
                        resolve();
                    }, 1000);
                });
            } else {
                onStatus(false);
                onWarning(true, "Favor de seleccionar un horario.");
            }
        } else {

            onWarning(true, "Favor de llenar todos los campos del formulario");
        }
    }

    useEffect(() => {
        effectButton();
        setCurrentDate(getCurrentStringDate());
    }, []);

    return (
        <>
            {/* Desktop */}
            <div className="relative hidden md:flex w-full justify-center">
                <div className="absolute top-[10px] lg:top-[-20px] mr-[120px] lg:mr-[170px] z-30">
                    <Fade>
                        <img className="w-full h-[190px] lg:h-[250px] img-boxs" src={ImageTop} />
                    </Fade>
                </div>
                <div className="static">
                    <form onSubmit={handleSubmit}>
                        <div className="row mt-[180px]">
                            <div className="w-[650px] lg:w-[900px] m-auto rounded overflow-hidden shadow-lg glassmorphism mb-5">
                                <Fade>
                                    <div className="px-6 py-4">
                                        <div className="container">
                                            <div className="row pl-1 pt-2 pb-7">
                                                <span className="input-group-addon"><img className="w-[70px]" src={IconCalendar} /></span>
                                            </div>
                                            <div className="row pb-5">
                                                <div className="grid grid-cols-2">
                                                    <div className="text-left">
                                                        <h3 className="txt-arena-white font-bold pb-2">Crea una reservación</h3>
                                                        <p className="txt-arena-white text-sm font-light">
                                                            Realiza tu reservación hasta con 15 días de anticipación y asegúrate de tener un lugar disponible en Arena Movistar.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pb-5">
                                                <div className="grid grid-cols-2">
                                                    <div className="text-left p-3">
                                                        <div className="pb-3">
                                                            <h3 className="txt-arena-white font-bold">01. Proporciona tus datos</h3>
                                                        </div>
                                                        <div>
                                                            <div className="mb-4">
                                                                <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="nameLabel">
                                                                    Tu nombre
                                                                </label>
                                                                <input
                                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="nameInput"
                                                                    type="text"
                                                                    name="userName"
                                                                    onChange={handleChange}
                                                                    value={booking.userName.value}
                                                                />
                                                            </div>
                                                            <div className="mb-4">
                                                                <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="emailLabel">
                                                                    Correo Electrónico
                                                                </label>
                                                                <input
                                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="emailInput"
                                                                    type="text"
                                                                    name="userMail"
                                                                    onChange={handleChange}
                                                                    value={booking.userMail.value}
                                                                />
                                                            </div>
                                                            <div className="mb-4">
                                                                <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="dateLabel">
                                                                    Número celular
                                                                </label>
                                                                <input
                                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="inputdate-arenaD"
                                                                    type="text"
                                                                    name="userPhone"
                                                                    onChange={handleChange}
                                                                    value={booking.userPhone.value}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-left p-3">
                                                        <div className="pb-3">
                                                            <h3 className="txt-arena-white font-bold">02. Selecciona una sucursal</h3>
                                                        </div>
                                                        <div>
                                                            <div className="mb-4">
                                                                <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="locationLabel">
                                                                    Sucursal
                                                                </label>
                                                                <Select
                                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                                    options={venues}
                                                                    onResponse={onResponseSelect}
                                                                    name="venueID"
                                                                    id="select-arenaD"
                                                                    placeholder="Sucursal..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-[650px] lg:w-[900px] w-full m-auto rounded overflow-hidden shadow-lg glassmorphism mb-5">
                                <Fade>
                                    <div className="px-6 py-4">
                                        <div className="container">
                                            <div className="row pb-5">
                                                <div className="text-left">
                                                    <h3 className="txt-arena-white font-bold pb-2">03. Selecciona la categoría</h3>
                                                </div>
                                                <div className="grid grid-cols-2">
                                                    <div className="p-3">
                                                        <div className="border-cut py-[10px] scale-95 hover:scale-100 ease-in duration-150">
                                                            <button className="btn-category-booking" id="btn-cb-1" type="button" onClick={() => onClickCategory("Next Level Room")}>
                                                                <div className="flex justify-end">
                                                                    <IconNxtLvlRoom />
                                                                </div>
                                                                <h3 className="text-[18px] txt-arena-white font-bold">Next Level Zone</h3>
                                                                <p>Compite como pro en una PC diseñada especialmente para gamers.</p>
                                                            </button>
                                                        </div>
                                                        <div className="border-cut py-[10px] scale-95 hover:scale-100 ease-in duration-150">
                                                            <button className="btn-category-booking" id="btn-cb-2" type="button" onClick={() => onClickCategory("Retro Room")}>
                                                                <div className="flex justify-end">
                                                                    <IconRetroRoom />
                                                                </div>
                                                                <h3 className="text-[18px] txt-arena-white font-bold">Retro Room</h3>
                                                                <p>Regresa en el tiempo con Atari, NES, SuperNES, SEGA, PS4, Xbox One, Wii U y Nintendo Switch.</p>
                                                            </button>
                                                        </div>
                                                        <div className="border-cut py-[10px] scale-95 hover:scale-100 ease-in duration-150">
                                                            <button className="btn-category-booking" id="btn-cb-3" type="button" onClick={() => onClickCategory("Platinum Room")}>
                                                                <div className="flex justify-end">
                                                                    <IconPlatinoRoom />
                                                                </div>
                                                                <h3 className="text-[18px] txt-arena-white font-bold">Platinum Room</h3>
                                                                <p>Disfruta de nuestra sala mejor equipada para PS4, Xbox One, Wii U y Nintendo Switch.</p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="p-3">
                                                        <div className="border-cut py-[10px] scale-95 hover:scale-100 ease-in duration-150">
                                                            <button className="btn-category-booking" id="btn-cb-4" type="button" onClick={() => onClickCategory("Versus Zone")}>
                                                                <div className="flex justify-end">
                                                                    <img src={IconVersusRoom} />
                                                                </div>
                                                                <h3 className="text-[18px] txt-arena-white font-bold">Versus Zone</h3>
                                                                <p>Juega con tus amigos en las mejores consolas: PS4, Xbox One, Wii U y Nintendo Switch.</p>
                                                            </button>
                                                        </div>
                                                        <div className="border-cut py-[10px] scale-95 hover:scale-100 ease-in duration-150">
                                                            <button className="btn-category-booking" id="btn-cb-5" type="button" onClick={() => onClickCategory("Immersive Zone")}>
                                                                <div className="flex justify-end">
                                                                    <img src={IconImmersiveRoom} />
                                                                </div>
                                                                <h3 className="text-[18px] txt-arena-white font-bold">Immersive Zone</h3>
                                                                <p>Vive la realidad Virtual en nuestra sala especial con PS4, Xbox One, Wii U y Nintendo Switch.</p>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                        <div className="row">
                            <div className="w-[650px] lg:w-[900px] w-full m-auto rounded overflow-hidden shadow-lg glassmorphism mb-5">
                                <Fade>
                                    <div className="px-6 py-4">
                                        <div className="container">
                                            <div className="row pb-5">
                                                <div className="grid grid-col">
                                                    <div className="text-left">
                                                        <h3 className="txt-arena-white font-bold pb-2">04. Acompañantes y fecha</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pb-5">
                                                <div className="grid grid-cols-2">
                                                    <div className="text-left p-3">
                                                        <div>
                                                            <div className="mb-4">
                                                                <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="teamLabel">
                                                                    Acompañantes
                                                                </label>
                                                                <Select
                                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                                    options={friends}
                                                                    onResponse={onResponseSelect}
                                                                    name="numberOfPeople"
                                                                    id="select-arena"
                                                                    placeholder="Acompañantes..."
                                                                />
                                                            </div>
                                                            <div className="mb-4">
                                                                <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="dateLabel">
                                                                    Día
                                                                </label>
                                                                <input
                                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                                    id="inputdate-arenaD"
                                                                    type="date"
                                                                    min={currentDate}
                                                                    name="reservationDate"
                                                                    onChange={handleChange}
                                                                    value={booking.reservationDate.value}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="text-left p-3">
                                                        <div>
                                                            <div className="mb-4">
                                                                <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="hourLabel">
                                                                    Hora
                                                                </label>
                                                                <div className="flex  items-center">
                                                                    {/* <input
                                                                        className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                                        id="hourInput"
                                                                        type="time"
                                                                        name="reservationHour"
                                                                        onChange={handleChange}
                                                                        value={booking.reservationHour.value}
                                                                    /> */}
                                                                    <Select
                                                                        className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline w-[70px]"
                                                                        options={hours}
                                                                        onResponse={onResponseHour}
                                                                        name="numberOfHours"
                                                                        id="select-arena"
                                                                        placeholder="Hrs"
                                                                    />
                                                                    <div className="txt-arena-white flex mx-2">  :  </div>
                                                                    <Select
                                                                        className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline w-[70px]"
                                                                        options={minutes}
                                                                        onResponse={onResponseMinutes}
                                                                        name="numberOfMinutes"
                                                                        id="select-arena"
                                                                        placeholder="Min"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="mb-4">
                                                                <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="timeLabel">
                                                                    Duración
                                                                </label>
                                                                <Select
                                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                                    options={booking.durationTime}
                                                                    onResponse={onResponseSelect}
                                                                    name="reservationTime"
                                                                    id="select-arena"
                                                                    placeholder="Duración..."
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="mb-4">
                                                        <div className="flex space-x-[12px] py-[12px] m-auto text-[14px] txt-arena-white font-bold">
                                                            <input type="checkbox" name="terms" onChange={() => { setTerms(!terms) }} />
                                                            <p>
                                                                {'Acepto el '}
                                                                <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "privacy" }) }}>
                                                                    aviso de privacidad
                                                                </button>
                                                                {' , '}
                                                                <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "terms_conditions" }) }}>
                                                                    términos y condiciones
                                                                </button>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row pb-5">
                                                <div className="ml-auto py-3 w-[35%] scale-100 hover:scale-110 ease-in duration-150">
                                                    <button className="btn-arena-primary" type="submit">
                                                        <span className="text-[12px] tracking-[2px]">CREAR RESERVACIÓN</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            {/* Mobile */}
            <div className="relative flex md:hidden w-full justify-center">
                <div className="absolute top-0 ml-[60px] z-30">
                    <Fade>
                        <img className="img-boxs w-[250px]" src={ImageTop} />
                    </Fade>
                </div>
                <div className="static glassmorphism mt-[60px]">
                    <form onSubmit={handleSubmit}>
                        <div className="row pl-5 pt-[95px] pb-3">
                            <Fade>
                                <img className="w-[70px]" src={IconCalendar} />
                            </Fade>
                        </div>
                        <div className="row">
                            <div className="text-left py-3 px-5">
                                <Fade cascade damping={0.2}>
                                    <h3 className="txt-arena-white font-semibold pb-2">Crea una reservación</h3>
                                    <p className="txt-arena-white text-sm font-light">
                                        Realiza tu reservación hasta con 15 días de anticipación y asegúrate de tener un lugar disponible en Arena Movistar.
                                    </p>
                                </Fade>
                            </div>
                            <div className="text-left py-3 px-5">
                                <Fade cascade damping={0.1}>
                                    <div className="pb-3">
                                        <h3 className="txt-arena-white font-semibold">01. Proporciona tus datos</h3>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="nameLabel">
                                                Tu nombre
                                            </label>
                                            <input
                                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                id="nameInput"
                                                type="text"
                                                name="userName"
                                                onChange={handleChange}
                                                value={booking.userName.value}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="emailLabel">
                                                Correo Electrónico
                                            </label>
                                            <input
                                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                id="emailInput"
                                                type="text"
                                                name="userMail"
                                                onChange={handleChange}
                                                value={booking.userMail.value}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="dateLabel">
                                                Número celular
                                            </label>
                                            <input
                                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                id="inputdate-arenaD"
                                                type="text"
                                                name="userPhone"
                                                onChange={handleChange}
                                                value={booking.userPhone.value}
                                            />
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="text-left py-3 px-5">
                                <Fade cascade damping={0.1}>
                                    <div className="pb-5">
                                        <h3 className="txt-arena-white font-bold">02. Selecciona una sucursal</h3>
                                    </div>
                                    <div>
                                        <div className="mb-4">
                                            <Select
                                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                options={venues}
                                                onResponse={onResponseSelect}
                                                name="venueID"
                                                id="select-arenaM"
                                                placeholder="Sucursal..."
                                            />
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="text-left py-3 px-5">
                                <Fade cascade damping={0.1}>
                                    <div className="pb-3">
                                        <h3 className="txt-arena-white font-bold pb-2">03. Selecciona la categoría</h3>
                                    </div>
                                </Fade>
                                <div className="pb-3 text-sm">
                                    <Fade cascade damping={0.1}>
                                        <div className="border-cut py-2">
                                            <button className="btn-category-booking" id="btn-cbm-1" type="button" onClick={() => onClickCategory("Next Level Room")}>
                                                <div className="flex justify-end">
                                                    <IconNxtLvlRoom />
                                                </div>
                                                <h3 className="txt-arena-white font-bold">Next Level Zone</h3>
                                                <p>Compite como pro en una PC diseñada especialmente para gamers.</p>
                                            </button>
                                        </div>
                                        <div className="border-cut py-2">
                                            <button className="btn-category-booking" id="btn-cbm-2" type="button" onClick={() => onClickCategory("Retro Room")}>
                                                <div className="flex justify-end">
                                                    <IconRetroRoom />
                                                </div>
                                                <h3 className="txt-arena-white font-bold">Retro Room</h3>
                                                <p>Regresa en el tiempo con Atari, NES, SuperNES, SEGA, PS4, Xbox One, Wii U y Nintendo Switch.</p>
                                            </button>
                                        </div>
                                        <div className="border-cut py-2">
                                            <button className="btn-category-booking" id="btn-cbm-3" type="button" onClick={() => onClickCategory("Platinum Room")}>
                                                <div className="flex justify-end">
                                                    <IconPlatinoRoom />
                                                </div>
                                                <h3 className="txt-arena-white font-bold">Platinum Room</h3>
                                                <p>Disfruta de nuestra sala mejor equipada para PS4, Xbox One, Wii U y Nintendo Switch.</p>
                                            </button>
                                        </div>
                                        <div className="border-cut py-2">
                                            <button className="btn-category-booking" id="btn-cbm-4" type="button" onClick={() => onClickCategory("Versus Zone")}>
                                                <div className="flex justify-end">
                                                    <img src={IconVersusRoom} />
                                                </div>
                                                <h3 className="txt-arena-white font-bold">Versus Zone</h3>
                                                <p>Juega con tus amigos en las mejores consolas: PS4, Xbox One, Wii U y Nintendo Switch.</p>
                                            </button>
                                        </div>
                                        <div className="border-cut py-2">
                                            <button className="btn-category-booking" id="btn-cbm-5" type="button" onClick={() => onClickCategory("Immersive Zone")}>
                                                <div className="flex justify-end">
                                                    <img src={IconImmersiveRoom} />
                                                </div>
                                                <h3 className="txt-arena-white font-bold">Immersive Zone</h3>
                                                <p>Vive la realidad Virtual en nuestra sala especial con PS4, Xbox One, Wii U y Nintendo Switch.</p>
                                            </button>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                            <div className="text-left py-3 px-5">
                                <Fade>
                                    <div className="pb-3">
                                        <h3 className="txt-arena-white font-bold">04. Acompañantes y fecha</h3>
                                    </div>
                                </Fade>
                                <div>
                                    <Fade cascade damping={0.1}>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="teamLabel">
                                                Acompañantes
                                            </label>
                                            <Select
                                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                options={friends}
                                                onResponse={onResponseSelect}
                                                name="numberOfPeople"
                                                id="select-arena"
                                                placeholder="Acompañantes..."
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="dateLabel">
                                                Día
                                            </label>
                                            <div>
                                                <input
                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="inputdate-arenaM"
                                                    type="date"
                                                    min={currentDate}
                                                    name="reservationDate"
                                                    onChange={handleChange}
                                                    value={booking.reservationDate.value}
                                                />
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="hourLabel">
                                                Hora
                                            </label>
                                            <div className="flex items-center">
                                                {/* <input
                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                    id="hourInput"
                                                    type="time"
                                                    placeholder="Hora..."
                                                    name="reservationHour"
                                                    onChange={handleChange}
                                                    value={booking.reservationHour.value}
                                                /> */}
                                                <Select
                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline w-[70px]"
                                                    options={hours}
                                                    onResponse={onResponseSelect}
                                                    name="numberOfHours"
                                                    id="select-arena"
                                                    placeholder="Hrs"
                                                />
                                                <div className="txt-arena-white flex mx-2">  :  </div>
                                                <Select
                                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline w-[70px]"
                                                    options={minutes}
                                                    onResponse={onResponseSelect}
                                                    name="numberOfMinutes"
                                                    id="select-arena"
                                                    placeholder="Min"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-sm font-bold mb-2" htmlFor="timeLabel">
                                                Duración
                                            </label>
                                            <Select
                                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                options={duration}
                                                onResponse={onResponseSelect}
                                                name="reservationTime"
                                                id="select-arena"
                                                placeholder="Duración..."
                                            />
                                        </div>
                                    </Fade>
                                </div>
                                <div>
                                    <div className="mb-4">
                                        <div className="flex space-x-[12px] py-[12px] m-auto text-[14px] txt-arena-white font-bold">
                                            <input type="checkbox" name="terms" onChange={() => { setTerms(!terms) }} />
                                            <p>
                                                {'Acepto el '}
                                                <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "privacy" }) }}>
                                                    aviso de privacidad
                                                </button>
                                                {' y, '}
                                                <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "terms_conditions" }) }}>
                                                    términos y condiciones
                                                </button>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row pb-3">
                            <div className="py-5 px-5 m-auto">
                                <Fade>
                                    <button className="btn-arena-primary" type="submit">CREAR RESERVACIÓN</button>
                                </Fade>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default BookingForm;



