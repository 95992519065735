import { auth, sendPasswordResetEmail } from "../../firebase";
import { useState } from "react";
import PopNotify from "../../components/Pop/PopNotify";

interface PopInfo {
    status: boolean,
    type: string
}

const LoginResetPop = ({ onCloseModal } : { onCloseModal: any }) => {
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [mailData, setMailData] = useState({
        mail: "",
        mailAux: ""
    });

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setMailData({...mailData, [evt.currentTarget.name]: evt.currentTarget.value});
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (mailData.mail === mailData.mailAux) {
            await sendPasswordResetEmail(auth, mailData.mail);
            setPopStatus({...popStatus, status: true, type: "success"});
        } else {
            setPopStatus({...popStatus, status: true, type: "warning"});
        }
    }

    return (
        <>
            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[85vw] md:w-[600px] lg:w-[1000px]">
                        <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-[5px] lg:p-[10px] border-b border-solid border-gray-500 rounded-t">
                                <button
                                    className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                    onClick={() => onCloseModal(false)}
                                >
                                    X
                                </button>
                            </div>
                            <div className="relative p-[20px] lg:p-[50px] flex-auto">
                                <form onSubmit={handleSubmit}>
                                    <div className="space-y-[3vw] md:space-y-2">
                                        <div className="txt-arena-white text-left font-bold">
                                            <h3>Recuperar contraseña</h3>
                                        </div>
                                        <div className="space-y-5">
                                            <div>
                                                <input
                                                    className="shadow appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                    type="text"
                                                    name="mail"
                                                    placeholder="Favor de escribir tu correo electronico"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div>
                                                <input
                                                    className="shadow appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                                    type="text"
                                                    name="mailAux"
                                                    placeholder="Escribe nuevamente tu correo electronico"
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="ml-auto py-3 w-[55vw] md:w-[35%] scale-100 hover:scale-110 ease-in duration-150">
                                                <button className="btn-arena-primary" type="submit">
                                                    <span className="text-[12px] tracking-[2px]">ENVIAR CORREO</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default LoginResetPop;