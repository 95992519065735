import { useEffect, useState } from "react";
import { getVenues } from "../../services/Venues";
import { getEvents } from "../../services/Events";
import { Fade } from "react-awesome-reveal";
import IconBg from "../../assets/icons/06_hexagon.svg";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import BirthdayCard from "./BirthdayCard";
import ReactPlayer from 'react-player';
import EventsModel from "../../models/Events";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import BirthdayImages from "./BirthdayImages";
import PopNotify from "../../components/Pop/PopNotify";
import PopWarning from "../../components/Pop/PopWarning";
import PopSuccess from "../../components/Pop/PopSuccess";
import Pop from "../../components/Pop/Pop";
import Loading from "../../components/loading/Loading";
import Rellax from "rellax";

interface PopInfo {
    status: boolean,
    type: string
}

const VideoArena = require("../../assets/video/arena_the_place_to_play.mp4");

const Birthday = () => {
    const [venuesOpt, setVenuesOpt] = useState<Array<Object>>([]);
    const [events, setEvents] = useState<Array<EventsModel>>([]);

    const [statusModal, setStatusModal] = useState({
        status: false,
        indexImage: 0
    });

    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [popWarningStatus, setPopWarningStatus] = useState<PopInfo>({ status: false, type: "" });
    const [popSuccessStatus, setPopSuccessStatus] = useState<PopInfo>({ status: false, type: "" });
    const [closeStatus, setCloseStatus] = useState<boolean>(false);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

    const durationOptions = [
        { label: "1 hora", value: "1 hora" },
        { label: "1 hora y media", value: "1 hora y media" },
        { label: "3 horas", value: "3 horas" },
        { label: "Todo el día", value: "Todo el día" }
    ];

    useEffect(() => {
        new Rellax(".animatev0", { speed: 3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev1", { speed: -1, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev2", { speed: -2, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev3", { speed: -3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev4", { speed: -4, center: false, round: true, vertical: true, horizontal: false });

        new Promise<void>((resolve, reject) => {
            getVenues().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        if (element.venueIsActive) {
                            newArr.push({
                                label: element['venueName'],
                                value: element.venueID,
                                data: element
                            });
                        }
                    }
                    setVenuesOpt(newArr);
                }
            });

            // Get all events
            getEvents().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setEvents(newArr);
                }
            });

            setTimeout(function () {
                resolve();
            }, 1000);
        });
    }, []);

    return (
        <>
            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({ ...popupTP, status: false, documentStr: "" }) }}
                    />
                    : null
            }

            {/* {
                statusModal.status ?
                    <BirthdayImages
                        imageIndex={statusModal.indexImage}
                        onCloseModal={() =>
                            setStatusModal({ ...statusModal, status: false, indexImage: 0 })
                        }
                    />
                    :
                    null
            } */}

            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({ ...popStatus, status: false, type: "" }) }} />
                    : null
            }

            {
                popWarningStatus.status ?
                    <PopWarning body={popWarningStatus.type} onClose={() => { setPopWarningStatus({ ...popWarningStatus, status: false, type: "" }) }} />
                    : null
            }

            {
                popSuccessStatus.status ?
                    <PopSuccess body={popSuccessStatus.type} onClose={() => { setPopSuccessStatus({ ...popSuccessStatus, status: false, type: "" }) }} />
                    : null
            }

            {
                closeStatus ?
                    <Pop title="Términos y condiciones" message="..." onClose={() => setCloseStatus(false)} onResponse={() => setCloseStatus(false)} />
                    : null
            }

            {loadingStatus ? <Loading /> : null}

            <div id="animatev0" style={{ scrollBehavior: 'auto' }} className="bg-arena-gray">
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-40">
                        <Navbar />
                    </div>
                </div>
                <div className="relative w-[100%] md:w-[650px] lg:w-[950px] m-auto">
                    <div className="absolute md:relative w-[100vw] h-[56vw] left-0 top-[50px] m-auto z-0 md:w-[500px] md:h-[280px] md:top-[70px] lg:w-[760px] lg:h-[428px] lg:top-[70px]">
                        <ReactPlayer
                            url={VideoArena}
                            className='react-player'
                            playing
                            width='100%'
                            height='100%'
                            controls={false}
                            loop={true}
                            muted={true}
                        />
                    </div>
                    <div className="relative w-[90%] md:w-[650px] lg:w-[950px] m-auto">
                        <div className="animatev2">
                            <img className="absolute w-[50vw] mt-[58vw] ml-[55vw] md:w-[250px] md:mt-[-2%] md:ml-[67%] lg:mt-[-8%] lg:ml-[83%] z-0" src={IconBg} />
                        </div>
                        <div className="animatev1">
                            <img className="absolute w-[50vw] mt-[70vw] ml-[-40vw] md:w-[250px] md:mt-[15%] md:ml-[-18%] lg:mt-[17%] lg:ml-[-10%] z-0" src={IconBg} />
                        </div>
                        <div className="animatev2">
                            <img className="absolute w-[50vw] mt-[200vw] ml-[55vw] md:w-[250px] md:mt-[100%] md:ml-[-18%] lg:mt-[100%] lg:ml-[-10%] z-0 md:z-10" src={IconBg} />
                        </div>

                        <BirthdayCard
                            duration={durationOptions}
                            venues={venuesOpt}
                            events={events}
                            statusPop={(opt: any) => {
                                setPopupTP({ ...popupTP, status: opt.status, documentStr: opt.documentStr })
                            }}
                            onModalCarrousel={(sts: boolean, idx: number) => {
                                setStatusModal({ ...statusModal, status: sts, indexImage: idx })
                            }}
                            onWarning={(message: any) => setPopWarningStatus({ ...popWarningStatus, status: true, type: message })}
                            onSuccess={() => { }}
                            onStatus={(message: any) => setPopStatus({ ...popStatus, status: true, type: message })}
                            onClosePop={() => setCloseStatus(true)}
                            onLoading={(opt: any) => { setLoadingStatus(opt) }}
                        />
                    </div>
                </div>

                <div className="pt-10">
                    <Fade>
                        <Footer
                            statusPop={(opt: any) => {
                                setPopupTP({ ...popupTP, status: opt.status, documentStr: opt.documentStr })
                            }}
                        />
                    </Fade>
                </div>
            </div>
        </>
    );
}

export default Birthday;