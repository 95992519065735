import RealeseModel from "../../models/Realese";
import { AiFillStar } from "react-icons/ai";
import { useEffect, useState } from "react";

const arrConsoleBg: any = {
    "Multiplataforma": "bg-arena-mp",
    "Nintendo Switch": "bg-arena-ndo",
    "Windows PC": "bg-arena-pc",
    "Steam": "bg-arena-pcstm",
    "Playstation 4/Pro": "bg-arena-ps4",
    "Playstation 5": "bg-arena-ps5",
    "Xbox One": "bg-arena-xo",
    "Xbox Series X/S": "bg-arena-xos",
    "Android": "bg-arena-aod",
    "iOS": "bg-arena-ios",
};

const RealesesCard = ({ realeses, onResponseModal } : { realeses: RealeseModel, onResponseModal: any }) => {
    
    const [rating, setRating] = useState<Array<string>>([
        "text-gray-200",
        "text-gray-200",
        "text-gray-200",
        "text-gray-200",
        "text-gray-200"
    ]);

    useEffect(() => {
        const newRating = rating.map((ratingElement: string, i: number) => {
            if ((i+1) <= realeses.gameScore) {
                return "text-yellow-500";
            } else {
                return ratingElement;
            }
        });
        setRating(newRating);
    }, [realeses]);

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block pb-[35px]">
                <div className="pb-[15px]">
                    <div className="w-[200px] h-[280px] overflow-hidden rounded-md">
                        <button type="button" onClick={() => onResponseModal(realeses) }>
                            <img className="w-[200px] h-[280px] duration-500 ease-in-out hover:scale-110 rounded-md img-boxs" src={realeses.gameImageURL} />
                        </button>
                    </div>
                    <div className="relative txt-arena-white text-[12px]">
                        <span className={"absolute bottom-[12px] left-[15px] px-[15px] py-[8px] rounded-md " + arrConsoleBg[`${realeses.gamePlatform}`]} >{realeses.gamePlatform}</span>
                    </div>
                </div>
                <div className="w-[200px] text-left txt-arena-white text-[14px] font-bold py-[8px]">
                    <h3>{realeses.gameTitle}</h3>
                </div>
                <div className="flex text-[18px] space-x-[5px]">
                    <span className={rating[0]}><AiFillStar /></span>
                    <span className={rating[1]}><AiFillStar /></span>
                    <span className={rating[2]}><AiFillStar /></span>
                    <span className={rating[3]}><AiFillStar /></span>
                    <span className={rating[4]}><AiFillStar /></span>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div className="pb-0">
                    <button type="button" onClick={() => onResponseModal(realeses) }>
                        <img className="w-[55vw] h-[70vw] rounded-md img-boxs" src={realeses.gameImageURL} />
                        <div className="relative txt-arena-white text-[3vw]">
                            <span className={"absolute bottom-[3vw] left-[3vw] px-[2.5vw] py-[1vw] rounded-md " + arrConsoleBg[`${realeses.gamePlatform}`]} >{realeses.gamePlatform}</span>
                        </div>
                    </button>
                </div>
                <div className="text-left txt-arena-white text-[3.8vw] font-bold py-[0.7vw]">
                    <h3>{realeses.gameTitle}</h3>
                </div>
                <div className="flex text-[4vw] space-x-[0.5vw]">
                    <span className={rating[0]}><AiFillStar /></span>
                    <span className={rating[1]}><AiFillStar /></span>
                    <span className={rating[2]}><AiFillStar /></span>
                    <span className={rating[3]}><AiFillStar /></span>
                    <span className={rating[4]}><AiFillStar /></span>
                </div>
            </div>
        </>
    );
}

export default RealesesCard;