import { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
    
  const [globalState, setGlobalState] = useState({
    name_location:'',
    venues:[],
    userData:null,
    videosURL:[]
  });


  return (
    <GlobalContext.Provider
      value={{
        globalState,
        setGlobalState
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
