import React, { useState, useEffect } from "react";
import { HiPhotograph } from "react-icons/hi";
import { uploadImages } from "../../../firebase";
import { saveRealeses, updateRealeses } from "../../../services/Realese";
import { IDCompany } from "../../../global/global";
import Select from "../../../components/select/Select";
import RealeseModel from "../../../models/Realese";
import PopNotify from "../../../components/Pop/PopNotify";
import Loading from "../../../components/loading/Loading";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsPlusCircleFill } from "react-icons/bs";

interface StepData {
    step: string;
    status: boolean;
}

interface ImageData {
    name: string;
    file: any;
}

interface PopInfo {
    status: boolean,
    type: string
}

const RealesesForm = ({
        arrPlataform,
        arrESRB,
        arrStars,
        realese,
        onRefresh
    } : {
        arrPlataform: Array<any>,
        arrESRB: Array<any>,
        arrStars: Array<any>,
        realese: any,
        onRefresh: any
    }) => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [genre, setGenre] = useState<string>("");

    const [imageUpload, setImageUpload] = useState<ImageData>({
        name: "",
        file: null
    });

    const [steps, setSteps] = useState<Array<StepData>>([
        { step: "Step 1", status: true },
        { step: "Step 2", status: false },
        { step: "Step 3", status: false }
    ]);

    const [realeses, setRealeses] = useState({
        companyID: { value: `${IDCompany}`, isCorrect: true },
        gameTitle: { value: "", isCorrect: false },
        gameDeveloper: { value: "", isCorrect: false },
        gameBody: { value: "", isCorrect: false },
        gameGenre: { value: [], isCorrect: false },
        gameESRB: { value: "", isCorrect: false },
        gamePlatform: { value: "", isCorrect: false },
        gameReleaseDate: { value: "", isCorrect: false },
        gameScore: { value: "", isCorrect: false },
        gameCreatedBy: { value: "", isCorrect: true },
        gameImageURL: { value: "", isCorrect: false },
        dropVideoURL: { value: "", isCorrect: false }
    });

    const deleteGenreElement = (genre: string) => {
        const newGenreArr = realeses.gameGenre.value.filter(elem => elem !== genre);
        setRealeses({...realeses, gameGenre: { value: newGenreArr, isCorrect: true }});
    }

    const deleteImage = () => {
        setImageUpload({...imageUpload, name: "", file: null});
    }

    const handleUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const file = evt.currentTarget.files;
        if (file !== null) {
            setImageUpload({...imageUpload, name: file[0].name, file: file[0]});
        }
    }

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        if (evt.target.name === "gameGenre") {
            if (String(evt.target.value).includes(",")) {
                let newGenre: any = realeses.gameGenre.value;
                newGenre.push(genre);
                setRealeses({...realeses, gameGenre: { value: newGenre, isCorrect: true } });
                setGenre("");
            } else {
                setGenre(evt.target.value);
            }
        } else {
            setRealeses({...realeses, [evt.target.name]: { value: evt.target.value, isCorrect: true }});
        }
    }

    const onResponseSelect = (opt: any) => {
        let arrCurrent: Array<Object> = [];
        
        if (opt.target.name === "gameESRB") {
            arrCurrent = arrESRB;
        } else if (opt.target.name === "gamePlatform") {
            arrCurrent = arrPlataform;
        } else if (opt.target.name === "gameScore") {
            arrCurrent = arrStars
        }
        setRealeses({...realeses, [opt.target.name]: { value: Object(arrCurrent[opt.target.value]).value, isCorrect: true }});
    }

    const nextStep = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        if ((evt.currentTarget.name === "Step 2" &&
            (
                !realeses.gameTitle.isCorrect || !realeses.gameDeveloper.isCorrect || !realeses.gameReleaseDate.isCorrect ||
                !realeses.gamePlatform.isCorrect || !realeses.gameESRB.isCorrect || !realeses.gameScore.isCorrect
            ))
            ||
            (evt.currentTarget.name === "Step 3" && (!realeses.gameGenre.isCorrect || !realeses.gameBody.isCorrect))
        ) {
            return 0;
        }

        const newStep = steps.map((stepElement, i) => {
            if (stepElement.step === evt.currentTarget.name) {
                return { ...stepElement, status: true };
            } else {
                return { ...stepElement, status: false };
            }
        });
        setSteps(newStep);
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (
            realeses.gameTitle.isCorrect && realeses.gameDeveloper.isCorrect && realeses.gameBody.isCorrect &&
            realeses.gameGenre.isCorrect && realeses.gameESRB.isCorrect && realeses.gamePlatform.isCorrect &&
            realeses.dropVideoURL.isCorrect && realeses.gameScore.isCorrect
        ) {
            setLoadingStatus(true);
            let imageUrlStr: string = "";
            if (imageUpload.file) {
                imageUrlStr = await uploadImages(imageUpload.file, "releases");
            }  else if (realese && !imageUpload.file) {
                imageUrlStr = realese.gameImageURL;
            }

            const realeseData: RealeseModel = {
                companyID: `${IDCompany}`,
                gameID: realese ? realese.gameID : "",
                gameTitle: realeses.gameTitle.value,
                gameDeveloper: realeses.gameDeveloper.value,
                gameBody: realeses.gameBody.value,
                gameGenre: realeses.gameGenre.value,
                gameESRB: realeses.gameESRB.value,
                gamePlatform: realeses.gamePlatform.value,
                gameReleaseDate: realeses.gameReleaseDate.value,
                gameScore: Number(realeses.gameScore.value),
                gameCreatedBy: realeses.gameCreatedBy.value,
                gameImageURL: imageUrlStr,
                dropVideoURL: realeses.dropVideoURL.value,
            }
            new Promise<void>((resolve, reject) => {
                if (realese !== null) {
                    updateRealeses(realeseData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data) {
                            setPopStatus({...popStatus, status: true, type: "success"});
                            onRefresh(true);
                        } else {
                            setPopStatus({...popStatus, status: true, type: "error"});
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                } else {
                    saveRealeses(realeseData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data) {
                            onRefresh(true);
                            setPopStatus({...popStatus, status: true, type: "success"});
                            setImageUpload({...imageUpload, name: "", file: null});
                            setRealeses({...realeses,
                                companyID: { value: `${IDCompany}`, isCorrect: true },
                                gameTitle: { value: "", isCorrect: false },
                                gameDeveloper: { value: "", isCorrect: false },
                                gameBody: { value: "", isCorrect: false },
                                gameGenre: { value: [], isCorrect: false },
                                gameESRB: { value: "", isCorrect: false },
                                gamePlatform: { value: "", isCorrect: false },
                                gameReleaseDate: { value: "", isCorrect: false },
                                gameScore: { value: "", isCorrect: false },
                                gameCreatedBy: { value: "", isCorrect: true },
                                gameImageURL: { value: "", isCorrect: false },
                                dropVideoURL: { value: "", isCorrect: false }
                            });

                            // Se identifican los campos
                            let gamePlatformInput = document.getElementById("gamePlatformInput") as HTMLSelectElement | null;
                            let gameScoreInput = document.getElementById("gameScoreInput") as HTMLSelectElement | null;
                            let gameESRBInput = document.getElementById("gameESRBInput") as HTMLSelectElement | null;

                            // Se ingresan los valores
                            if (gamePlatformInput !== null) gamePlatformInput.value = "";
                            if (gameScoreInput !== null) gameScoreInput.value = "";
                            if (gameESRBInput !== null) gameESRBInput.value = "";
                        } else {
                            setPopStatus({...popStatus, status: true, type: "error"});
                        }
                    });
                    setTimeout(function() {
                        resolve();
                    }, 1000);
                }
            });
        } else {
            setPopStatus({...popStatus, status: true, type: "warning"});
        }
    }

    useEffect(() => {
        if (realese !== null) {
            const newRealese = {
                companyID: { value: `${IDCompany}`, isCorrect: true },
                gameTitle: { value: realese.gameTitle, isCorrect: true },
                gameDeveloper: { value: realese.gameDeveloper, isCorrect: true },
                gameBody: { value: realese.gameBody, isCorrect: true },
                gameGenre: { value: realese.gameGenre, isCorrect: true },
                gameESRB: { value: realese.gameESRB, isCorrect: true },
                gamePlatform: { value: realese.gamePlatform, isCorrect: true },
                gameReleaseDate: { value: realese.gameReleaseDate, isCorrect: true },
                gameScore: { value: realese.gameScore, isCorrect: true },
                gameCreatedBy: { value: realese.gameCreatedBy, isCorrect: true },
                gameImageURL: { value: realese.gameImageURL, isCorrect: true },
                dropVideoURL: { value: realese.dropVideoURL, isCorrect: true }
            }
            setRealeses(newRealese);
            setImageUpload({...imageUpload, name: realese.gameImageURL, file: null});

            // Se identifican los campos
            let gamePlatformInput = document.getElementById("gamePlatformInput") as HTMLSelectElement | null;
            let gameScoreInput = document.getElementById("gameScoreInput") as HTMLSelectElement | null;
            let gameESRBInput = document.getElementById("gameESRBInput") as HTMLSelectElement | null;

            // Se ingresan los valores
            if (gamePlatformInput !== null) gamePlatformInput.value = `${arrPlataform.findIndex((el, i) => el.value === realese.gamePlatform)}`;
            if (gameScoreInput !== null) gameScoreInput.value = `${arrStars.findIndex((el, i) => el.value === Number(realese.gameScore))}`;
            if (gameESRBInput !== null) gameESRBInput.value = `${arrESRB.findIndex((el, i) => el.value === realese.gameESRB)}`;
        }
    }, [realese]);

    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="block">
                <form onSubmit={handleSubmit}>
                    <div className="px-2 text-left">
                        {/* Primera parte del formulario */}
                        {
                            steps[0].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[290px] bg-zinc-300 border border-zinc-300"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-zinc-300 rounded-full">
                                                <span className="text-[18px] text-zinc-300 font-bold">2</span>
                                            </div>
                                            <div className="w-[290px] bg-zinc-300 border border-zinc-300"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-zinc-300 rounded-full">
                                                <span className="text-[18px] text-zinc-300 font-bold">3</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-zinc-300 font-bold">Descripción</span>
                                            <span className="text-[12px] text-zinc-300 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="space-y-5">
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Nombre
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="gameTitleInput"
                                                type="text"
                                                placeholder="Nombre..."
                                                name="gameTitle"
                                                onChange={handleChange}
                                                value={realeses.gameTitle.value}
                                            />
                                        </div>
                                        <div className="md:grid md:grid-cols-2 md:gap-4">
                                            <div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                        Desarrollador
                                                    </label>
                                                    <input
                                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                        id="gameDeveloperInput"
                                                        type="text"
                                                        placeholder="Desarrollador..."
                                                        name="gameDeveloper"
                                                        onChange={handleChange}
                                                        value={realeses.gameDeveloper.value}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="locationLabel">
                                                        Plataforma
                                                    </label>
                                                    <Select className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black" options={arrPlataform} onResponse={onResponseSelect} name="gamePlatform" id="gamePlatformInput" placeholder="Plataforma..." />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="locationLabel">
                                                        Calificación
                                                    </label>
                                                    <Select className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black" options={arrStars} onResponse={onResponseSelect} name="gameScore" id="gameScoreInput" placeholder="Clasificación..." />
                                                </div>
                                            </div>
                                            <div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                        Creado
                                                    </label>
                                                    <input
                                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px]"
                                                        id="gameReleaseDateInput"
                                                        type="date"
                                                        style={{ color: 'black' }}
                                                        name="gameReleaseDate"
                                                        onChange={handleChange}
                                                        value={realeses.gameReleaseDate.value}
                                                    />
                                                </div>
                                                <div className="mb-4">
                                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="locationLabel">
                                                        ESRB
                                                    </label>
                                                    <Select className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black" options={arrESRB} onResponse={onResponseSelect} name="gameESRB" id="gameESRBInput" placeholder="ESRB..." />
                                                </div>
                                                <div className="mb-4">
                                                    {/* <div className="w-full flex justify-center items-center space-x-2 pt-[30px]">
                                                        <input type="checkbox" />
                                                        <label className="text-[14px]">Mostrar como destacado</label>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex justify-end pt-[5px]">
                                            <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" name="Step 2" type="button" onClick={nextStep}>
                                                Siguiente
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }

                        {/* Segunda parte del formulario */}
                        {
                            steps[1].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[290px] bg-orange-600 border border-orange-600"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">2</span>
                                            </div>
                                            <div className="w-[290px] bg-zinc-300 border border-zinc-300"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-zinc-300 rounded-full">
                                                <span className="text-[18px] text-zinc-300 font-bold">3</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-orange-600 font-bold">Descripción</span>
                                            <span className="text-[12px] text-zinc-300 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="space-y-5">
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Etiquetas <span className="text-zinc-300">{' (Para añadir el bloque de etiqueta, favor de separarlo con una "coma")'}</span>
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="gameGenreInput"
                                                type="text"
                                                placeholder="Etiquetas..."
                                                name="gameGenre"
                                                onChange={handleChange}
                                                value={genre}
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <div className="space-y-1 md:flex md:items-center md:space-x-[5px] md:space-y-0">
                                                {
                                                    realeses.gameGenre.value.map((genreElement:string, i:number) => {
                                                        return (
                                                            <div className="flex items-center bg-arena-black opacity-75 space-x-[5px] px-[5px] py-[3px]" key={i}>
                                                                <span className="txt-arena-white text-[12px]">
                                                                    {genreElement}
                                                                </span>
                                                                <button
                                                                    className="txt-arena-white text-[12px]"
                                                                    type="button"
                                                                    onClick={() => deleteGenreElement(genreElement)}
                                                                >
                                                                    X
                                                                </button>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="mb-4 space-y-2">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Descripción <span className="text-zinc-300">{' (Máximo 400 carácteres)'}</span>
                                            </label>
                                            <textarea
                                                className="appearance-none border border-zinc-500 bg-transparent w-full p-[10px] text-[14px] txt-arena-black rounded-sm"
                                                id="gameBodyInput"
                                                placeholder="Descripción..."
                                                name="gameBody"
                                                onChange={handleChange}
                                                value={realeses.gameBody.value}
                                                rows={6}
                                                maxLength={400}
                                            >
                                            </textarea>
                                        </div>
                                        <div className="flex justify-end pt-[5px]">
                                            <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" name="Step 3" type="button" onClick={nextStep}>
                                                Siguiente
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }

                        {/* Tercera parte del formulario */}
                        {
                            steps[2].status ?
                                <div className="space-y-7">
                                    <div className="hidden lg:block">
                                        <div className="w-[700px] flex justify-between items-center m-auto">
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">1</span>
                                            </div>
                                            <div className="w-[290px] bg-orange-600 border border-orange-600"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">2</span>
                                            </div>
                                            <div className="w-[290px] bg-orange-600 border border-orange-600"></div>
                                            <div className="w-[40px] h-[40px] border-2 flex justify-center items-center border-orange-600 rounded-full">
                                                <span className="text-[18px] text-orange-600 font-bold">3</span>
                                            </div>
                                        </div>
                                        <div className="flex items-stretch justify-between items-center">
                                            <span className="text-[12px] text-orange-600 font-bold">Información</span>
                                            <span className="text-[12px] text-orange-600 font-bold">Descripción</span>
                                            <span className="text-[12px] text-orange-600 font-bold">Imágenes</span>
                                        </div>
                                    </div>
                                    <div className="space-y-5">
                                        <div className="mb-4 space-y-2">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                Imagen <span className="text-zinc-400">{'Resolución recomendada 400x560'}</span>
                                            </label>
                                            <div className="flex items-center justify-center w-full">
                                                <label className="w-full h-12 border-4 border-dashed">
                                                    <div className="flex space-x-2 p-2">
                                                        <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                        <div>
                                                            { imageUpload.name ?
                                                                <div className="flex">
                                                                    <span
                                                                        className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                                    >
                                                                        {imageUpload.name}
                                                                    </span>
                                                                    <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteImage() }>
                                                                        <AiFillCloseCircle />
                                                                    </button>
                                                                </div>
                                                                :
                                                                <div className="flex">
                                                                    <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                        Cargar imagen...
                                                                    </span>
                                                                    <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <input type="file" className="opacity-0" name="noteImageURL" onChange={handleUpload} />
                                                </label>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                                URL juego destacado
                                            </label>
                                            <input
                                                className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                                id="dropVideoURLInput"
                                                type="text"
                                                placeholder="URL Video..."
                                                name="dropVideoURL"
                                                onChange={handleChange}
                                                value={realeses.dropVideoURL.value}
                                            />
                                        </div>
                                        <div className="flex justify-end pt-[5px]">
                                            <button className="bg-arena-orange px-[60px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" type="submit">
                                                {
                                                    realese ? "Guardar cambios" : "Añadir juego"
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            : null
                        }
                    </div>
                </form>
            </div>
        </>
    );
}

export default RealesesForm;