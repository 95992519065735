import { initializeApp } from "firebase/app";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    updatePassword,
    onAuthStateChanged,
    signOut,
    connectAuthEmulator
} from "firebase/auth";
import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { getStorage, ref, uploadBytes, connectStorageEmulator, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

const config = {
  apiKey: "AIzaSyBTNKCEdYOva0IrbzT8R_G15mdAPEEoacg",
  authDomain: "arenaweb-144819.firebaseapp.com",
  databaseURL: "https://arenaweb-144819.firebaseio.com",
  projectId: "arenaweb-144819",
  storageBucket: "arenaweb-144819.appspot.com",
  messagingSenderId: "401657878448",
  appId: "1:401657878448:web:32614bcbb8d69edc51e875"
    // apiKey: "AIzaSyArXQrVgfa3477upoX_98PwzX0jpZO9Bcc",
    // authDomain: "arena-fe78c.firebaseapp.com",
    // projectId: "arena-fe78c",
    // storageBucket: "arena-fe78c.appspot.com",
    // messagingSenderId: "422596210247",
    // appId: "1:422596210247:web:6d721df8f8dfe710694b62",
    // measurementId: "G-ENSPST282X"
};

const app = initializeApp(config);
const functions = getFunctions(app);
const auth = getAuth();
const storage = getStorage();

// connectFunctionsEmulator(functions, "localhost", 5001);
// connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
// connectStorageEmulator(storage, "localhost", 9199);

const uploadImages = async (file: any, folder: string) => {
    const storageRef = ref(storage, `images/${folder}/${v4()}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
}

const uploadVideos = async (file: any, folder: string) => {
    const storageRef = ref(storage, `videos/${folder}/${v4()}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
}

// Enviar a la carpeta Carrousel
const uploadVideosCarrousel = async (file: any, folder: string) => {
    const storageRef = ref(storage, `videosCarrousel/${folder}/${v4()}`);
    await uploadBytes(storageRef, file);
    return await getDownloadURL(storageRef);
}

export {
    functions,
    httpsCallable,
    storage,
    uploadImages,
    uploadVideos,
    uploadVideosCarrousel,
    signOut,
    auth,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    updatePassword
};