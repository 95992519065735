import { functions, httpsCallable } from "../firebase";
import { IDCompany } from "../global/global";
import NewsModel from "../models/News";

export const getNews = () => {
    const getAllNews = httpsCallable(functions, "readAllNotes");
    const res = getAllNews({ companyID: `${IDCompany}` });
    return res;
}

export const addNews = (data: NewsModel) => {
    const saveNews = httpsCallable(functions, "createNote");
    const res = saveNews(data);
    return res;
}

export const updateNews = (data: Object) => {
    const editNews = httpsCallable(functions, "updateNote");
    const res = editNews(data);
    return res;
}

export const deleteNews = (data: Object) => {
    const removeNews = httpsCallable(functions, "deleteNote");
    const res = removeNews(data);
    return res;
}