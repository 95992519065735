import { useEffect, useMemo, useState } from "react";
import Table from "../../../components/table/Table";
import styled from "styled-components";
import UserModel from "../../../models/User";
import UserButtonsTable from "./UserButtonsTable";

const Styled = styled.div`
    table {
        width: 800px;
        text-align: left;
    }

    table th {
        background-color: rgb(240, 240, 240);
        font-weight: bold;
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    table td {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    @media (max-width: 1200px) {
        table { width: 600px; }
    }

    @media (max-width: 600px) {
        table { width: 700px; }
    }
`;

const arrActiveBg: any = {
    true: "bg-green-500",
    false: "bg-zinc-400"
};

const UserTable = ({ users, arrTypes, onRefresh } : { users: any, arrTypes: Array<any>, onRefresh: any }) => {
    const [newUsers, setNewUsers] = useState<Array<any>>([]);
    const columns = useMemo(() => [
        {
            Header: 'Nombre',
            accessor: 'userName',
            width: '23%'
        },
        {
            Header: 'Apellido',
            accessor: 'userLastName',
            width: '23%'
        },
        {
            Header: 'Tipo de Usuario',
            accessor: 'userType',
            width: '23%'
        },
        {
            Header: 'Estatus',
            accessor: 'userIsActive',
            width: '23%'
        },
        {
            Header: '',
            accessor: 'options',
            width: '8%'
        }
    ], []);

    useEffect(() => {
        const newArr = users.map((userElement: UserModel, i: number) => {
            const userActive: string = userElement.userIsActive ? "ACTIVO" : "ARCHIVADO";
            return {
                userName: userElement.userName,
                userLastName: userElement.userLastName,
                userType: userElement.userType,
                userLoginEmail: userElement.userLoginEmail,
                userPassword: userElement.userPassword,
                userIsActive:
                    <div className={"w-[100px] p-[3px] text-center txt-arena-white text-[9px] font-bold rounded-md " + arrActiveBg[`${userElement.userIsActive}`]}>
                        {userActive}
                    </div>
                ,
                options: <UserButtonsTable onRefresh={onRefresh} arrTypes={arrTypes} user={userElement} />
            };
        });
        setNewUsers(newArr);
    }, [users]);

    return (
        <>
        <div className="block overflow-x-auto lg:overflow-x-visible">
            <Styled>
                <Table columns={columns} data={newUsers} />
            </Styled>
        </div>
        </>
    );
}

export default UserTable;