import Loading from "../../../components/loading/Loading";
import NavbarAdmin from "../../../components/navbar-admin/NavbarAdmin";
import SidebarAdmin from "../../../components/sidebar-admin/SidebarAdmin";
import { useState, useEffect } from "react";
import EventsCard from "./EventsCard";
import EventsModel from "../../../models/Events";
import { getEvents } from "../../../services/Events";
import { getVenues } from "../../../services/Venues";
import VenuesModel from "../../../models/Venues";

const AdminEvents = () => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [events, setEvents] = useState<Array<EventsModel>>([]);
    const [venuesOptions, setVenuesOptions] = useState<Array<VenuesModel>>([]);

    const monthsOptions = [
        { label: "Enero", value: 1 },
        { label: "Febrero", value: 2 },
        { label: "Marzo", value: 3 },
        { label: "Abril", value: 4 },
        { label: "Mayo", value: 5 },
        { label: "Junio", value: 6 },
        { label: "Julio", value: 7 },
        { label: "Agosto", value: 8 },
        { label: "Septiembre", value: 9 },
        { label: "Octubre", value: 10 },
        { label: "Noviembre", value: 11 },
        { label: "Diciembre", value: 12 },
    ];

    const getEventsData = () => {
        new Promise<void>((resolve, reject) => {
            // Get all events
            getEvents().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setEvents(newArr);
                }
            });

            // Get all venues
            getVenues().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push({ label: element.venueName, value: element.venueName });
                    }
                    setVenuesOptions(newArr);
                }
            });

            // Resolve promise
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    };

    useEffect(() => {
        getEventsData();
    }, []);

    return (
        <div className="flex flex-row w-full h-screen">
            { loadingStatus ? <Loading /> : null }
            <div className="w-[65px] md:w-[250px] lg:w-[370px]">
                <SidebarAdmin />
            </div>
            <div className="w-full bg-zinc-300 flex-grow flex-shrink flex-auto overflow-y-scroll">
                <div>
                    <NavbarAdmin />
                </div>
                <div>
                    <EventsCard onRefresh={getEventsData} events={events} venues={venuesOptions} months={monthsOptions} />
                </div>
            </div>
        </div>
    );
}

export default AdminEvents;