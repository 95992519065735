import { useState } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { IDCompany } from "../../../global/global";
import { deleteDropTournmanet } from "../../../services/TournamentDrop";
import { FaRegEdit } from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import Pop from "../../../components/Pop/Pop";
import TournamentDropModel from "../../../models/TournamentDrop";
import DropsPop from "./DropsPop";
import PopNotify from "../../../components/Pop/PopNotify";
import Loading from "../../../components/loading/Loading";

interface PopInfo {
    status: boolean,
    type: string
}

const DropsButtonsTable = ({
    drop,
    type,
    mode,
    plataform,
    venues,
    onRefresh
} : {
    drop: TournamentDropModel,
    type: Array<any>,
    mode: Array<any>,
    plataform: Array<any>,
    venues: Array<any>,
    onRefresh: any
}) => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [closeStatus, setCloseStatus] = useState<boolean>(false);
    const [updateStatus, setUpdateStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    
    const removeRealese = (res: boolean) => {
        setCloseStatus(false);
        if (res) {
            setLoadingStatus(true);
            new Promise<void>((resolve, reject) => {
                deleteDropTournmanet({ companyID: `${IDCompany}`, dropID: drop.dropID }).then(res => {
                    setLoadingStatus(false);
                    if (res && res.data) {
                        setPopStatus({...popStatus, status: true, type: "success"});
                        onRefresh(true);
                    }
                });
    
                // Resolve promise
                setTimeout(function() {
                    resolve();
                }, 1000);
            });
        }
    }

    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                closeStatus ?
                    <Pop title={`¿Está seguro que desea eliminar ${drop.dropName}?`} message="" onClose={() => { setCloseStatus(false) }} onResponse={removeRealese} />
                : null
            }

            {
                updateStatus ?
                    <DropsPop
                        onRefresh={onRefresh}
                        dtElement={drop}
                        type={type}
                        mode={mode}
                        plataform={plataform}
                        venues={venues}
                        onResponse={() => {setUpdateStatus(false)} }
                    />
                : null
            }

            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="relative">
                <div className="hidden lg:block">
                    <div className="dropdown">
                        <button className="dropbtn"><HiDotsVertical /></button>
                        <div className="dropdown-content">
                            <a onClick={() => setUpdateStatus(true) } href="#">Editar drop</a>
                            <a onClick={() => setCloseStatus(true) } href="#">Eliminar drop</a>
                        </div>
                    </div>
                </div>
                <div className="lg:hidden flex space-x-1">
                    <a className="p-2 bg-zinc-200 txt-arena-gray text-[16px] rounded-md" onClick={() => setUpdateStatus(true) } href="#"><FaRegEdit /></a>
                    <a className="p-2 bg-zinc-200 txt-arena-gray text-[16px] rounded-md" onClick={() => setCloseStatus(true) } href="#"><AiFillDelete /></a>
                </div>
            </div>
        </>
    );
}

export default DropsButtonsTable;