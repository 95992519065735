import { useMemo, useState, useEffect } from "react";
import Table from "../../../components/table/Table";
import styled from "styled-components";
import PopupModel from "../../../models/Popup";
import PopupButtonsTable from "./PopupButtonsTable";

const Styled = styled.div`
    table {
        width: 950px;
        text-align: left;
    }

    table th {
        background-color: rgb(240, 240, 240);
        font-weight: bold;
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    table td {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    @media (max-width: 1350px) {
        table { width: 850px; }
    }

    @media (max-width: 1270px) {
        table { width: 750px; }
    }

    @media (max-width: 1180px) {
        table { width: 600px; }
    }

    @media (max-width: 1024px) {
        table { width: 800px; }
    }
`;

const PopupTable = ({ onRefresh, popups } : { onRefresh: any, popups: Array<PopupModel> }) => {
    const [newPopup, setNewPopup] = useState<Array<any>>([]);

    const columns = useMemo(() => [
        {
            Header: 'Nombre',
            accessor: 'popupTitle',
            width: '30%'
        },
        {
            Header: 'Imagenes',
            accessor: 'popupImageURL',
            width: '30%'
        },
        {
            Header: 'Videos',
            accessor: 'popupVideoURL',
            width: '30%'
        },
        {
            Header: '',
            accessor: 'options',
            width: '10%'
        }
    ], []);

    useEffect(() => {
        const newArr = popups.map((popupElement: PopupModel, i: number) => {
            let existImages: boolean = false;
            for (let i = 0; i < popupElement.popupImageURL.length; i++) {
                if (popupElement.popupImageURL[i] !== "") existImages = true;
            }
            
            return {
                companyID: popupElement.companyID,
                popupID: popupElement.popupID,
                popupTitle: popupElement.popupTitle,
                popupCreatedBy: popupElement.popupCreatedBy,
                popupImageURL: existImages ? "SI" : "NO",
                popupVideoURL: popupElement.popupVideoURL ? "SI" : "NO",
                options: <PopupButtonsTable onRefresh={onRefresh} popupElement={popupElement} />
            };
        });
        setNewPopup(newArr);
    }, [popups]);
    
    return (
        <>
            <div className="block overflow-x-auto lg:overflow-x-visible">
                <Styled>
                    <Table columns={columns} data={newPopup} />
                </Styled>
            </div>
        </>
    );
}

export default PopupTable;