import { useContext, useEffect, useState } from "react";
import Loading from "../../../components/loading/Loading";
import NavbarAdmin from "../../../components/navbar-admin/NavbarAdmin";
import SidebarAdmin from "../../../components/sidebar-admin/SidebarAdmin";
import { IDCompany } from "../../../global/global";
import CompanyModel from "../../../models/Company";
import PopupModel from "../../../models/Popup";
import { getCompanyById } from "../../../services/Company";
import { getPopups } from "../../../services/Popup";
import { getUrlVideos } from "../../../services/Carrousel";
import PopupCard from "./PopupCard";
import { GlobalContext } from "../../../context/global_context";

const AdminPopup = () => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [popups, setPopups] = useState<Array<PopupModel>>([]);
    const [videosUrl, setVideosUrl] = useState([])
    const {globalState,setGlobalState} = useContext(GlobalContext)
        
    const [statePopups, setStatePopups] = useState<CompanyModel>({
        companyName: "",
        companyID: "",
        companyCreatedAt: "",
        companyAddress: {
          street: "",
          number: "",
          interiorNumber: "",
          neighborhood: "",
          city: "",
          state: "",
          country: "",
          zipCode: "",
        },
        companySocialMedia: {
          facebook: "",
          instagram: "",
          twitter: "",
        },
        companyActivePopUps: false,
        companyNewsCount : 0,
        companyPhone: "",
        companyNumberOfVenues: 0,
    });

    const getPopupData = () => {
        new Promise<void>((resolve, reject) => {
            // Get all pop up
            getPopups().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setPopups(newArr);
                }
            });
            getUrlVideos({ companyID: `${IDCompany}` }).then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    // console.log(datares[0],"urls")
                    setGlobalState({...globalState, videosURL:datares[0]})
                }
            });

            getCompanyById({ companyID: `${IDCompany}` }).then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    setStatePopups(datares);
                }
            });

            // Resolve promise
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    };

    useEffect(() => {
        getPopupData();
    }, []);

    return (
        <>
            <div className="flex flex-row w-full h-screen">
                { loadingStatus ? <Loading /> : null }
                <div className="w-[65px] md:w-[250px] lg:w-[370px]">
                    <SidebarAdmin />
                </div>
                <div className="w-full bg-zinc-300 flex-grow flex-shrink flex-auto overflow-y-scroll">
                    <div>
                        <NavbarAdmin />
                    </div>
                    <div>
                        <PopupCard popups={popups} statePops={statePopups} onRefresh={getPopupData} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminPopup;