import IconLogin from "../../assets/icons/04_icon_comunidad.png";
import ImageLogIn from "../../assets/images/08_img_comunidad.png";
import axios from "axios";
import PopWarning from "../../components/Pop/PopWarning";
import PopSuccess from "../../components/Pop/PopSuccess";
import { FaUserAlt } from "react-icons/fa";
import { IoIosKey } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { Fade } from "react-awesome-reveal";

interface PopInfo {
    status: boolean,
    type: string
}

const HomepageLogIn = () => {
    const navigate = useNavigate();
    const [popWarningStatus, setPopWarningStatus] = useState<PopInfo>({ status: false, type: "" });
    const [popSuccessStatus, setPopSuccessStatus] = useState<PopInfo>({ status: false, type: "" });
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [loginData, setLoginData] = useState({
        email: "",
        password: ""
    });

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setLoginData({...loginData, [evt.currentTarget.name]: evt.currentTarget.value});
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        try {
            if (loginData.email && loginData.password) {
                axios.post("https://offsite.gamersarena.com.mx/players/login", loginData).then(async (res: any) => {
                    if (res.status === 200) {
                        localStorage.setItem("user-data", JSON.stringify({ token: res.data.token, username: res.data.player.nick }));
                        navigate("/");
                    } else {
                        setPopWarningStatus({...popWarningStatus, status: true, type: "¡Ha ocurrido un problema, favor de volver a intentarlo!"});
                    }
                }).catch(async (error) => {
                    if (error.response.data.error.message) {
                        if (error.response.data.error.message === "No se encontró al jugador solicitado") {
                            await signInWithEmailAndPassword(auth, loginData.email, loginData.password);
                            navigate("/admin/popup");
                        }
                    } else {
                        setPopWarningStatus({...popWarningStatus, status: true, type: "¡Ha ocurrido un problema, favor de volver a intentarlo!"});
                    }
                });
            } else {
                setPopWarningStatus({...popWarningStatus, status: true, type: "Favor de llenar todos los campos del formulario"});
            }
        } catch (error) {
            setPopStatus({...popStatus, status: true, type: "warning"});
        }
    }

    return (
        <>
            {
                popWarningStatus.status ?
                    <PopWarning body={popWarningStatus.type} onClose={() => { setPopWarningStatus({...popWarningStatus, status: false, type: ""}) }} />
                : null
            }

            {
                popSuccessStatus.status ?
                    <PopSuccess body={popSuccessStatus.type} onClose={() => { setPopSuccessStatus({...popSuccessStatus, status: false, type: ""}) }} />
                : null
            }

            {/* Desktop */}
            <div className="hidden md:block m-auto">
                <form onSubmit={handleSubmit}>
                    <div className="pt-[150px] pb-[80px] lg:pt-[50px]">
                        <img className="absolute w-[420px] top-0 left-[100px] rounded-md img-boxs lg:left-[200px] lg:w-[500px]" src={ImageLogIn} />
                    </div>
                    <div className="space-y-[30px] lg:space-y-0 lg:space-x-[100px] lg:flex lg:items-center">
                        <div className="glassmorphism w-[550px] px-[25px] pt-[25px] pb-[50px] space-y-[10px] lg:w-[480px]">
                            <Fade cascade damping={0.1}>
                                <div>
                                    <img className="w-[70px]" src={IconLogin} />
                                </div>
                                <div className="text-left txt-arena-white font-bold">
                                    <h3 className="text-[18px]">Únete a la comunidad</h3>
                                </div>
                                <div className="text-left text-gray-300">
                                    <p className="text-[16px]">
                                        Vive lo mejor de ARENA The Place to Play Movistar: Torneos, Noticias, Reservas, Lanzamientos, Eventos, Fiestas de cumpleaños y mucho más. 
                                        ¡Juega a otro nivel!
                                    </p>
                                </div>
                            </Fade>
                        </div>
                        <div className="glassmorphism w-[550px] px-[25px] space-y-[35px] py-[35px] lg:w-[400px]">
                            <Fade cascade damping={0.1}>
                                <div className="space-y-[20px]">
                                    <div className="text-left txt-arena-white font-bold">
                                        <h3 className="text-[18px]">Correo electrónico</h3>
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="text"
                                            className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[16px] py-[15px]"
                                            placeholder="Ingresa tu correo electrónico"
                                            name="email"
                                            onChange={handleChange}
                                        />
                                        <div className="flex absolute inset-y-0 right-0 items-center pr-[10px] pointer-events-none txt-arena-white">
                                            <span className="text-[20px]"><FaUserAlt /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-[20px]">
                                    <div className="text-left txt-arena-white font-bold">
                                        <h3 className="text-[18px]">Contraseña</h3>
                                    </div>
                                    <div className="relative">
                                        <input
                                            type="password"
                                            className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[16px] py-[15px]"
                                            placeholder="Ingresa tu contraseña"
                                            name="password"
                                            onChange={handleChange}
                                        />
                                        <div className="flex absolute inset-y-0 right-0 items-center pr-[10px] pointer-events-none txt-arena-white">
                                            <span className="text-[20px]"><IoIosKey /></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="space-y-[25px] py-[20px]">
                                    <div className="w-[90%] m-auto scale-100 hover:scale-110 ease-in duration-150">
                                        <Link to="/register">
                                            <button className="btn-arena-primary" type="button">
                                                <span className="text-[12px] tracking-[2px]">SIGN UP</span>
                                            </button>
                                        </Link>
                                    </div>
                                    <div className="w-[90%] m-auto scale-100 hover:scale-110 ease-in duration-150">
                                        <button className="btn-arena-secondary" type="submit">
                                            <span className="text-[12px] tracking-[2px]">LOG IN</span>
                                        </button>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </form>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div className="relative mt-[7vw] pt-[15vw] pb-[8vw]">
                    <img className="absolute w-[75vw] top-0 left-[20vw] rounded-md z-30 img-boxs" src={ImageLogIn} />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="glassmorphism w-[88vw] space-y-[4vw] px-[5vw] py-[3vw] m-auto">
                        <Fade cascade damping={0.1}>
                            <div className="pt-[23vw]">
                                <img className="w-[20vw] z-40" src={IconLogin} />
                            </div>
                            <div className="text-left txt-arena-white font-bold">
                                <h3 className="text-[4.8vw]">Únete a la comunidad</h3>
                            </div>
                            <div className="text-left text-gray-300">
                                <p className="text-[4vw]">
                                    Vive lo mejor de ARENA The Place to Play Movistar: Torneos, Noticias, Reservas, Lanzamientos, Eventos, Fiestas de cumpleaños y mucho más. 
                                    ¡Juega a otro nivel!
                                </p>
                            </div>
                            <div className="space-y-[3vw]">
                                <div className="text-left txt-arena-white font-bold">
                                    <h3 className="text-[4vw]">Username</h3>
                                </div>
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[4vw] py-[2.5vw]"
                                        placeholder="Enter your username"
                                        name="username"
                                        onChange={handleChange}
                                    />
                                    <div className="flex absolute inset-y-0 right-0 items-center pr-[3vw] pointer-events-none txt-arena-white">
                                        <span className="text-[6vw]"><FaUserAlt /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-[3vw]">
                                <div className="text-left txt-arena-white font-bold">
                                    <h3 className="text-[4vw]">Password</h3>
                                </div>
                                <div className="relative">
                                    <input
                                        type="password"
                                        className="bg-transparent w-full border-b border-gray-300 text-gray-300 text-[4vw] py-[2.5vw]"
                                        placeholder="Enter your password"
                                        name="password"
                                        onChange={handleChange}
                                    />
                                    <div className="flex absolute inset-y-0 right-0 items-center pr-[3vw] pointer-events-none txt-arena-white">
                                        <span className="text-[6vw]"><IoIosKey /></span>
                                    </div>
                                </div>
                            </div>
                            <div className="space-y-[5vw] py-[3vw]">
                                <div>
                                    <Link to="/register">
                                        <button className="btn-arena-primary" type="button">
                                            <span className="text-[4vw]">REGISTRATE</span>
                                        </button>
                                    </Link>
                                </div>
                                <div>
                                    <button className="btn-arena-secondary" type="submit">
                                        <span className="text-[4vw]">INICIAR SESIÓN</span>
                                    </button>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </form>
            </div>
        </>
    );
}

export default HomepageLogIn;