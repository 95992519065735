import { AiOutlineArrowRight } from "react-icons/ai";
import { useState } from "react";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import IconThrophie from "../../assets/images/tournaments/03_icon_torneos.png";
import IconLocation from "../../assets/icons/tournaments/31_icon_sucursal.svg";
import ImageTop1 from "../../assets/images/tournaments/39_img_portada_torneos_2.png";
import ImageTop2 from "../../assets/images/tournaments/38_img_portada_torneos_1.png";
import TournamentDropModel from "../../models/TournamentDrop";

import { Fade } from "react-awesome-reveal";

const arrConsoleBg: any = {
    "Multiplataforma": "bg-arena-mp",
    "Nintendo Switch": "bg-arena-ndo",
    "Windows PC": "bg-arena-pc",
    "Steam": "bg-arena-pcstm",
    "Playstation 4/Pro": "bg-arena-ps4",
    "Playstation 5": "bg-arena-ps5",
    "Xbox One": "bg-arena-xo",
    "Xbox Series X/S": "bg-arena-xos",
    "Android": "bg-arena-aod",
    "iOS": "bg-arena-ios",
};

const arrModeBg: any = {
    "EQUIPO": "bg-arena-pc",
    "INDIVIDUAL": "bg-arena-ndo"
};

interface PageInfo {
    page: number,
    elements: number,
    firstIndex: number,
    lastIndex: number
}

const TournamentsCard = ({
    tournaments,
    onModalDrop,
    onModalJoin
} : {
    tournaments: Array<TournamentDropModel>,
    onModalDrop: any,
    onModalJoin: any
}) => {
    const [pagination, setPagination] = useState<PageInfo>({
        page: 1,
        elements: 3,
        firstIndex: 0,
        lastIndex: 2
    });

    const onChangePage = (place: string) => {
        if (place === "right" && (tournaments.length > (pagination.page * pagination.elements))) {
            setPagination({...pagination,
                page: pagination.page + 1,
                elements: pagination.elements,
                firstIndex: pagination.firstIndex + pagination.elements,
                lastIndex: pagination.lastIndex + pagination.elements
            });
        } else if (place === "left" && pagination.page > 1 ) {
            setPagination({...pagination,
                page: pagination.page - 1,
                elements: pagination.elements,
                firstIndex: pagination.firstIndex - pagination.elements,
                lastIndex: pagination.lastIndex - pagination.elements
            });
        }
    }

    return (
        <>
            {/* Desktop */}
            <div className="relative hidden md:flex w-full justify-center">
                <div className="absolute top-[-130px] lg:top-[-220px] right-[-3vw] z-30 flex space-x-3">
                    <Fade>
                        <img className="img-boxs w-[260px] lg:w-[400px]" src={ ImageTop1 } />
                        <img className="img-boxs w-[260px] lg:w-[400px]" src={ ImageTop2 } />
                    </Fade>
                </div>
                <div className="static">
                    <div className="glassmorphism pb-12">
                        <Fade cascade damping={0.2}>
                            <div className="px-10 py-5">
                                <span className="input-group-addon"><img className="w-[60px]" src={IconThrophie} /></span>
                            </div>
                            <div className="text-left text-white px-10">
                                <h3 className="text-[20px] font-bold pb-2">Inscríbete a uno de nuestros torneos </h3>
                                <div className="w-[80%] lg:w-[50%]">
                                    <p className="text-[16px]">
                                    Forma parte de la comunidad Arena Movistar y participa en nuestra gran variedad de torneos, 
                                    donde podrás ganar grandes premios y sacar el gamer que llevas dentro, no lo dudes e inscríbete ya.
                                    </p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <div className="mt-[20px]">
                        <Fade>
                            <h3 className="text-left txt-arena-white text-[20px] font-bold pb-2">Torneos</h3>
                        </Fade>
                        <div className="mt-[2px]">
                            <div>
                                <button
                                    className="scale-100 hover:scale-110 ease-in duration-150 absolute bg-transparent top-[500px] left-[-50px] txt-arena-white z-30"
                                    type="button"
                                    onClick={() => onChangePage("left")}
                                >
                                    <span className="text-[50px]"><MdOutlineArrowBackIosNew /></span>
                                </button>
                                <button
                                    className="scale-100 hover:scale-110 ease-in duration-150 absolute bg-transparent top-[500px] right-[-50px] txt-arena-white z-30"
                                    type="button"
                                    onClick={() => onChangePage("right")}
                                >
                                    <span className="text-[50px]"><MdOutlineArrowForwardIos /></span>
                                </button>
                            </div>
                            <div className="grid grid-cols-3 gap-3">
                                {
                                    tournaments.map((tournamentElement: TournamentDropModel, i: number) => {
                                        if (i >= pagination.firstIndex && i <= pagination.lastIndex) {
                                            return(
                                                <div key={i}>
                                                    <Fade>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                if (tournamentElement.dropExternalURL && tournamentElement.dropExternalURL !== "") {
                                                                    let win = window.open(tournamentElement.dropExternalURL, '_blank');
                                                                    if (win !== null) {
                                                                        win.focus();
                                                                    }
                                                                } else {
                                                                    onModalDrop(true, tournamentElement)
                                                                }
                                                            }}
                                                            className="relative scale-95 hover:scale-100 ease-in duration-150"
                                                        >
                                                            <div className="bg-arena-black flex items-center w-[215px] h-[215px] lg:w-[300px] lg:h-[300px]">
                                                                <img className="absolute w-full h-auto img-boxs rounded m-auto" src={tournamentElement.dropImageURL} />
                                                            </div>
                                                            <span className="absolute bg-arena-nj top-1 left-2 px-[12px] py-[5px] txt-arena-white text-[12px] rounded">
                                                                {tournamentElement.dropGameTitle}
                                                            </span>
                                                            <span className={
                                                                "absolute bottom-1 left-2 px-[12px] py-[5px] text-[12px] font-bold txt-arena-white rounded " +
                                                                arrConsoleBg[`${tournamentElement.dropConsole}`]
                                                            }>
                                                                {tournamentElement.dropConsole}
                                                            </span>
                                                            <div className="absolute flex items-center space-x-2 bottom-1 right-2 px-[12px] py-[5px]">
                                                                <span className={"w-2 h-2 rounded-full " + arrModeBg[`${tournamentElement.dropGroupMode}`]}></span>
                                                                <span className="txt-arena-white text-[12px] font-semibold">
                                                                    {tournamentElement.dropGroupMode}
                                                                </span>
                                                            </div>
                                                        </button>
                                                    </Fade>
                                                    <div className="mt-[15px] text-left">
                                                        <h3 className="text-left txt-arena-white text-[18px] font-bold pb-2">
                                                            {tournamentElement.dropName}
                                                        </h3>
                                                    </div>
                                                    <div className="flex space-x-2 text-[14px]">
                                                        <img className="w-[20px]" src={IconLocation} />
                                                        <span className="txt-arena-orange font-bold">
                                                            {tournamentElement.dropLocation}
                                                        </span>
                                                    </div>
                                                    <div className="mt-[15px] bg-arena-black py-1 text-[14px] rounded">
                                                        <span className="txt-arena-white">{tournamentElement.dropReward}</span>
                                                    </div>
                                                    <div className="mt-[12px] bg-arena-mp text-[14px] rounded">
                                                        <span className="txt-arena-white">{tournamentElement.dropDate}</span>
                                                    </div>
                                                    <div className="mt-[15px] text-left text-[16px] h-[80px]">
                                                        <span className="txt-arena-white">{tournamentElement.dropDescription}</span>
                                                    </div>
                                                    <div className="w-full mt-[50px] bg-[#4C4C4C] txt-arena-white text-left rounded scale-100 hover:scale-105 ease-in duration-150">
                                                        <button
                                                            className="w-full flex justify-between items-center"
                                                            type="button"
                                                            onClick={() => { onModalJoin(true, tournamentElement)}}
                                                        >
                                                            <span className="text-[18px] font-bold pl-5">Participar</span>
                                                            <span className="text-[25px] py-3 pr-3"><AiOutlineArrowRight /></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="relative md:hidden">
                <div className="absolute top-[-21vw] right-[-5vw] z-30">
                    <Fade>
                        <img className="img-boxs w-[65vw] rounded-lg" src={ ImageTop1 } />
                    </Fade>
                </div>
                <div className="static">
                    <div className="glassmorphism pb-[8vw]">
                        <Fade cascade damping={0.2}>
                            <div className="px-[5vw] pt-[21vw]">
                                <span className="input-group-addon"><img className="w-[15vw]" src={IconThrophie} /></span>
                            </div>
                            <div className="text-left text-white px-[5vw]">
                                <h3 className="text-[4.5vw] font-semibold pb-[2vw] pr-[10vw]">Inscríbete a uno de nuestros torneos </h3>
                                <p className="text-[4vw]">
                                    Forma parte de la comunidad Arena Movistar y participa en nuestra gran variedad de torneos, 
                                    donde podrás ganar grandes premios y sacar el gamer que llevas dentro, no lo dudes e inscríbete ya.
                                </p>
                            </div>
                        </Fade>
                    </div>
                    <div className="mt-[5vw]">
                        <h3 className="text-left txt-arena-white text-base font-bold pb-[2vw] pl-[5vw]">Torneos</h3>
                        <div className="mt-[1vw]">
                            <div className="body-carrousel-td">
                                <div className="slider-carrousel-td">
                                    <div className="slides-carrousel-td">
                                        {
                                            tournaments.map((tournamentElement: TournamentDropModel, i: number) => {
                                                return (
                                                    <div key={i}>
                                                        <div>
                                                            <Fade>
                                                                <button type="button" onClick={() => { onModalDrop(true, tournamentElement) }} className="relative">
                                                                    <img className="img-boxs w-full rounded-lg" src={tournamentElement.dropImageURL} />
                                                                    <span className="absolute bg-arena-nj top-[2vw] left-[2vw] px-[2vw] py-[1vw] txt-arena-white text-[3vw] rounded">
                                                                        {tournamentElement.dropGameTitle}
                                                                    </span>
                                                                    <span className={
                                                                        "absolute bottom-[2vw] left-[2vw] px-[2vw] py-[1vw] text-[3vw] font-bold txt-arena-white z-40 rounded " +
                                                                        arrConsoleBg[`${tournamentElement.dropConsole}`]
                                                                    }>
                                                                        {tournamentElement.dropConsole}
                                                                    </span>
                                                                    <div className="absolute flex items-center space-x-[2vw] bottom-[1vw] right-[2vw] px-[5vw] py-[1vw]">
                                                                        <span className={"w-[2vw] h-[2vw] rounded-full " + arrModeBg[`${tournamentElement.dropGroupMode}`]}></span>
                                                                        <span className="txt-arena-white text-[3vw] font-bold">
                                                                            {tournamentElement.dropGroupMode}
                                                                        </span>
                                                                    </div>
                                                                </button>
                                                            </Fade>
                                                            <div className="mt-[2vw] text-left">
                                                                <h3 className="text-left txt-arena-white text-[4.5vw] font-bold pb-[2vw]">
                                                                    {tournamentElement.dropName}
                                                                </h3>
                                                            </div>
                                                            <div className="flex space-x-[2vw] text-[3.5vw]">
                                                                <img className="w-[5vw]" src={IconLocation} />
                                                                <span className="txt-arena-orange font-bold">
                                                                    {tournamentElement.dropLocation}
                                                                </span>
                                                            </div>
                                                            <div className="mt-[5vw] bg-arena-black py-[2vw] text-[4.5vw] rounded">
                                                                <span className="txt-arena-white">{tournamentElement.dropReward}</span>
                                                            </div>
                                                            <div className="mt-[3.5vw] py-[1vw] px-[1vw] bg-arena-mp text-[3vw] rounded">
                                                                <span className="txt-arena-white">{tournamentElement.dropDate}</span>
                                                            </div>
                                                            <div className="w-full mt-[3vw] bg-[#4C4C4C] txt-arena-white text-left rounded">
                                                                <button
                                                                    className="w-full flex justify-between items-center"
                                                                    type="button"
                                                                    onClick={() => { onModalJoin(true, tournamentElement)}}
                                                                >
                                                                    <span className="text-[5vw] font-bold pl-[5.5vw]">Participar</span>
                                                                    <span className="text-[8vw] py-[3vw] pr-[3vw]"><AiOutlineArrowRight /></span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center space-x-1">
                                <button type="button" className="w-2 h-2 bg-arena-white rounded-full"></button>
                                <button type="button" className="w-2 h-2 bg-arena-white rounded-full"></button>
                                <button type="button" className="w-2 h-2 bg-arena-white rounded-full"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TournamentsCard;