import { usePagination, useTable } from "react-table";
import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from "react-icons/hi";

const Table = ({columns, data}: {columns: any, data: Array<Object>}) => {
    const {
        getTableProps, getTableBodyProps, headerGroups, prepareRow, page, canPreviousPage, canNextPage, pageOptions, pageCount, 
        gotoPage, nextPage, previousPage, setPageSize, state: { pageIndex, pageSize }
    } = useTable({ columns, data, initialState: { pageIndex: 0, pageSize: 10 } }, usePagination );

    return (
        <div>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th {...column.getHeaderProps(
                                    {
                                        className: column.className,
                                        style: {
                                            minWidth: column.minWidth,
                                            width: column.width
                                        }
                                    }
                                )}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row: any, i: Number) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    return <td {...cell.getCellProps([
                                        { className: cell.column.className, style: cell.column.style }
                                    ])}>{cell.render('Cell')}</td>;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className="pagination w-[100%] pt-[30px] flex justify-center items-center">
                <span className="p-[5px] text-[13px]">{' '}Página{' '}<strong>{pageIndex + 1} de {pageOptions.length}</strong>{'   '}</span>
                <button 
                    className="p-[5px] bg-transparent text-gray-600 text-[20px] text-bold"
                    type="button"
                    onClick={() => gotoPage(0)}
                    disabled={!canPreviousPage}>
                        <HiChevronDoubleLeft />
                </button>
                {' '}
                <button
                    className="p-[5px] bg-transparent text-gray-600 text-[20px] text-bold"
                    type="button"
                    onClick={() => previousPage()}
                    disabled={!canPreviousPage}>
                        <HiChevronLeft />
                </button>
                {' '}
                <button 
                    className="p-[5px] bg-transparent text-gray-600 text-[20px] text-bold"
                    type="button"
                    onClick={() => nextPage()}
                    disabled={!canNextPage}>
                        <HiChevronRight />
                </button>
                {' '}
                <button
                    className="p-[5px] bg-transparent text-gray-600 text-[20px] text-bold"
                    type="button"
                    onClick={() => gotoPage(pageCount - 1)}
                    disabled={!canNextPage}>
                        <HiChevronDoubleRight />
                </button>
                {/* <select
                    className="p-[0.5vw] bg-transparent text-[1.3vw]"
                    value={pageSize}
                    onChange={e => { setPageSize(Number(e.target.value)) }}>
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>Mostrar {pageSize}</option>
                        ))}
                </select> */}
            </div>
        </div>
        
    );
};

export default Table;