import { Route, Routes } from "react-router-dom";
import Birthday from "../views/birthday/Birthday";
import Booking from "../views/booking/Booking";
import Login from "../views/login/Login";
import Homepage from "../views/homepage/Homepage";
import News from "../views/news/News";
import Realeses from "../views/realeses/Realeses";
import Tournaments from "../views/tournaments/Tournaments";
import NewsDetail from "../views/news/detail/NewsDetail";
import Venues from "../views/venues/Venues";
import VenuesDetail from "../views/venues/detail/VenuesDetail";
import Register from "../views/register/Register";
import Admin from "../views/admin/Admin";
import AdminUser from "../views/admin/users/AdminUser";
import AdminRealeses from "../views/admin/realeses/AdminRealeses";
import AdminVenues from "../views/admin/venues/AdminVenues";
import AdminNews from "../views/admin/news/AdminNews";
import AdminDrops from "../views/admin/drops/AdminDrops";
import AdminEvents from "../views/admin/events/AdminEvents";
import AdminPopup from "../views/admin/popup/AdminPopup";

// import Examples from "../views/examples/Examples";
import Page404 from "../views/page404/Page404";
import MyAccount from "../components/MyAccount";
// import AdminVideo from "../views/admin/videos/AdminVideo";

const RoutesApp = () => {
    return (
        <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="*" element={<Page404 />} />

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/tournaments" element={<Tournaments />} />
            <Route path="/birthday" element={<Birthday />} />
            <Route path="/realeses" element={<Realeses />} />
            <Route path="/news" element={<News />} />
            <Route path="/news/detail" element={<NewsDetail />} />
            <Route path="/venues" element={<Venues />} />
            <Route path="/venues/detail" element={<VenuesDetail />} />

            <Route path="/myAccount" element={<MyAccount />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/admin/users" element={<AdminUser />} />
            <Route path="/admin/realeses" element={<AdminRealeses />} />
            <Route path="/admin/venues" element={<AdminVenues />} />
            <Route path="/admin/news" element={<AdminNews />} />
            <Route path="/admin/tournaments" element={<AdminDrops />} />
            <Route path="/admin/events" element={<AdminEvents />} />
            <Route path="/admin/popup" element={<AdminPopup />} />
            {/* <Route path="/admin/videos" element={<AdminVideo />} /> */}

            {/* <Route path="/examples" element={<Examples />} /> */}
        </Routes>
    );
}

export default RoutesApp;
