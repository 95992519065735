import { BsFillPersonFill, BsArrowLeft, BsArrowRight } from 'react-icons/bs'
// import {BsArrowLeft} from 'react-icons/'
import IconTwitter from "../../../assets/icons/13_twitter_arena.svg";
import IconInstagram from "../../../assets/icons/14_instagram_arena.svg";
import IconFacebook from "../../../assets/icons/15_facebook_arena.svg";
import "swiper/css";
import "swiper/css/navigation";
import { useState } from 'react';

const PopupBanner = ({ images, video, closeModal,videoSelected }: { images: Array<string>, video: string, closeModal: any,videoSelected:any }) => {
    const dataVideos = [
        {
            name: 'Versus Zone',
            description: 'Estación para dos personas, con pantallas de 55" para disfrutar consolas de Xbox, Playstation y Nintendo Switch.',
            url: 'https://www.youtube.com/embed/AvYVljnCsjA?autoplay=1&mute=1&loop=1&playlist=AvYVljnCsjA'
        },
        {
            name: 'Platinum',
            description: 'Estación privada para hasta 8 personas para disfrutar consolas de Xbox, PlayStation y Nintendo Switch.',
            url: 'https://www.youtube.com/embed/2cMTSBVLeFc?autoplay=1&mute=1&loop=1&playlist=2cMTSBVLeFc'
        },
        {
            name: 'Immersive VR',
            description: 'Estación privada para hasta 8 personas para disfrutar consolas de Xbox, PlayStation y Nintendo Switch. Además podrás disfrutar de PlayStation VR',
            url: 'https://www.youtube.com/embed/c8HY4msV0Xw?autoplay=1&mute=1&loop=1&playlist=c8HY4msV0Xw'
        },
        {
            name: 'Next Level',
            description: 'Estación para una persona donde podrás disfrutar lo último en tecnología de PC Gaming',
            url: 'https://www.youtube.com/embed/FE2mrMyuFzY?autoplay=1&mute=1&loop=1&playlist=FE2mrMyuFzY'
        },
        {
            name: 'Retro Room',
            description: 'Estación privada para 8 personas máximo con consolas clásicas.',
            url: 'https://www.youtube.com/embed/2cMTSBVLeFc?autoplay=1&mute=1&loop=1&playlist=2cMTSBVLeFc'
        },
    ]

    const roomSelected = dataVideos.filter((item:any)=>{
        // console.log(item.name)
        return item.name == videoSelected 
    })
    
    const NoRoom = dataVideos.indexOf(roomSelected[0])

    const [state, setState] = useState({
        noRoom: NoRoom
    })
    
    const handleNextRoom = () => {
        if (state.noRoom !== 4) {
            setState({ ...state, noRoom: state.noRoom + 1 })
        }
    }
    const handleBackRoom = () => {
        if (state.noRoom !== 0) {
            setState({ ...state, noRoom: state.noRoom - 1 })
        }
    }

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none w-[85vw] md:w-[730px] lg:w-[950px] m-auto">
                    <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-[5px] border-b border-solid border-gray-500 rounded-t">
                            <span className="pl-4 txt-arena-white font-bold"></span>
                            <button
                                className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                onClick={() => closeModal(false)}
                            >
                                X
                            </button>
                        </div>
                        <div className='w-full'>
                            <div className="flex mt-[60px]">
                                <div className="ml-[20px] w-[40%]">
                                    <div className="flex">
                                        <img className="w-[25px]" src={IconTwitter} />
                                        <img className="w-[25px] mx-[5px]" src={IconInstagram} />
                                        <img className="w-[25px]" src={IconFacebook} />
                                    </div>
                                    <div className='flex items-center txt-arena-orange mt-[15px]'><BsFillPersonFill /> 1-8 Personas</div>
                                    <div className='text-left txt-arena-white text-[18px] font-bold my-[15px]'>{dataVideos[state.noRoom]?.name}</div>
                                    <div className='text-left txt-arena-white text '>{dataVideos[state.noRoom]?.description}</div>
                                </div>
                                <div className='p-[20px]' style={{ height: '380px', width: '100%' }}>
                                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <iframe width="100%" height="360px" src={dataVideos[state.noRoom]?.url} title="YouTube video player" allow="autoplay" allowFullScreen></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex items-center ml-[40px] mb-[30px] '>
                            <div className='btn-prev mr-[10px] cursor-pointer' onClick={handleBackRoom}><BsArrowLeft /></div>
                            <div className='btn-next cursor-pointer' onClick={handleNextRoom}> <BsArrowRight /> </div>
                        </div>
                    </div>
                </div>

                <div onClick={() => closeModal(false)} className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </div>
            {/* Mobile */}
            <div className="md:hidden">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none w-[85vw] md:w-[730px] lg:w-[950px] m-auto">
                    <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-[5px] border-b border-solid border-gray-500 rounded-t">
                            <span className="pl-4 txt-arena-white font-bold"></span>
                            <button
                                className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                onClick={() => closeModal(false)}
                            >
                                X
                            </button>
                        </div>
                        <div className='w-full'>
                            <div className='p-[20px]' style={{ height: '380px', width: '100%' }}>
                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <iframe width="100%" height="360px" src={dataVideos[state.noRoom]?.url} title="YouTube video player" allow="autoplay" allowFullScreen></iframe>
                                </div>
                            </div>
                            <div className="flex mt-[10px]">
                                <div className="ml-[20px]">
                                    <div className="flex">
                                        <img className="w-[35px]" src={IconTwitter} />
                                        <img className="w-[35px] mx-[5px]" src={IconInstagram} />
                                        <img className="w-[35px]" src={IconFacebook} />
                                    </div>
                                    <div className='flex items-center txt-arena-orange mt-[15px]'><BsFillPersonFill /> 1-8 Personas</div>
                                    <div className='text-left txt-arena-white text-[18px] font-bold my-[15px]'>{dataVideos[state.noRoom]?.name}</div>
                                    <div className='text-left txt-arena-white text '>{dataVideos[state.noRoom]?.description}</div>
                                </div>

                            </div>
                        </div>
                        <div className='flex items-center mt-[30px] ml-[40px] mb-[30px] '>
                            <div className='btn-prev mr-[10px] cursor-pointer' onClick={handleBackRoom}><BsArrowLeft /></div>
                            <div className='btn-next cursor-pointer'onClick={handleNextRoom} > <BsArrowRight /> </div>
                        </div>
                    </div>
                </div>

                <div onClick={() => closeModal(false)} className="opacity-50 fixed inset-0 z-40 bg-black"></div>
            </div>
        </>
    );
}

export default PopupBanner;