import IconNews from "../../assets/icons/news/06_icon_news.png";
import NewsModel from "../../models/News";
import NewsCarrousel from "./NewsCarrousel";
import { Fade } from "react-awesome-reveal";

const NewsHeader = ({ news } : { news: Array<NewsModel> }) => {
    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div>
                    <Fade>
                        <NewsCarrousel news={news} />
                    </Fade>
                    <div className="relative flex justify-center items-center">
                        <div className="relative top-[-20px] lg:top-[-30px] flex justify-center items-center m-auto z-30">
                            <div className="absolute">
                                <img className="relative w-[100px] lg:w-[85px] top-[-120px] right-[180px] lg:right-[150px] z-50" src={IconNews} />
                            </div>
                            <div className="glassmorphism w-[550px] lg:w-[480px] py-[40px] pl-[30px] pr-[60px] space-y-[12px] text-left txt-arena-white">
                                <Fade cascade damping={0.1}>
                                    <div className="pt-[15px]">
                                        <h3 className="text-[20px] font-bold">#ArenaNews</h3>
                                        <h3 className="text-[20px] font-bold">Entérate de lo último</h3>
                                    </div>
                                    <p className="text-[16px]">
                                        El futuro del gaming está en Arena Movistar, entérate de las últimas noticias y mantente al día, 
                                        ¡Para que nunca estés fuera de juego!
                                    </p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div>
                    <div>
                        <Fade>
                            <NewsCarrousel news={news} />
                        </Fade>
                    </div>
                    <div className="relative">
                        <div className="absolute top-[-5vw] left-[3vw] flex justify-center items-center m-auto z-40">
                            <div className="absolute">
                                <img className="relative w-[20vw] top-[-30vw] right-[30vw] z-50" src={IconNews} />
                            </div>
                            <div className="glassmorphism w-[90vw] py-[10vw] pl-[6vw] pr-[6vw] space-y-[3vw] text-left txt-arena-white">
                                <Fade cascade damping={0.1}>
                                    <div className="pt-[1vw]">
                                        <h3 className="text-[5vw] font-bold">#ArenaNews</h3>
                                        <h3 className="text-[5vw] font-bold">Entérate de lo último</h3>
                                    </div>
                                    <p className="text-[4.5vw]">
                                        El futuro del gaming está en Arena Movistar, entérate de las últimas noticias y mantente al día, 
                                        ¡Para que nunca estés fuera de juego!
                                    </p>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewsHeader;