import { useState, useEffect } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import NewsModel from "../../../models/News";
import NewsPop from "./NewsPop";
import NewsTable from "./NewsTable";

interface FilterData {
    inputType: string;
    inputName: string;
    word: string;
}

const NewsCard = ({ news, onRefresh } : { news: Array<NewsModel>, onRefresh: any }) => {
    const [statusModal, setStatusModal] = useState<boolean>(false);
    const [data, setData] = useState<Array<NewsModel>>([]);
    const [newsUniv, setNewsUniv] = useState<Array<NewsModel>>([]);

    const filter = (type: FilterData) => {
        let palabra = new RegExp(`${type.word}.*`, "i");
        let newUsers = newsUniv.filter((newsElement) => palabra.test(newsElement.noteTitle));
        setData(newUsers);
    }

    useEffect(() => {
        setData(news);
        setNewsUniv(news);
    }, [news]);

    return (
        <>
            {
                statusModal ?
                    <NewsPop onRefresh={onRefresh} newElement={null} onResponse={() => {setStatusModal(false) }} />
                : null
            }

            <div className="block">
                <div className="bg-arena-white m-[20px] md:m-[25px] space-y-[25px] px-[15px] py-[15px] md:space-y-[35px] md:px-[35px] md:py-[30px] rounded">
                    <div className="text-left font-bold">
                        <h3 className="text-[20px]">Noticias</h3>
                    </div>
                    <div className="flex space-x-[10px]">
                        <button className="flex items-center bg-arena-orange py-[5px] px-[15px] space-x-[40px] rounded-sm" type="button" onClick={() => { setStatusModal(true) }}>
                            <div className="text-left txt-arena-white font-bold">
                                <span className="text-[14px]">Añadir noticia</span>
                            </div>
                            <div className="txt-arena-white font-bold">
                                <span className="text-[20px]"><AiOutlinePlus /></span>
                            </div>
                        </button>
                    </div>
                    <div>
                        <div className="space-y-[20px] lg:flex lg:space-x-[10px] lg:space-y-0">
                            <div className="relative">
                                <input
                                    className="bg-transparent border-b border-zinc-500 py-[8px] px-0 md:px-[10px] w-full lg:w-[250px] txt-arena-gray text-[14px]"
                                    type="text"
                                    id="filter-ntext"
                                    placeholder="Buscar por titulo"
                                    onChange={(wrd) => { filter({ inputType: "input", inputName: "noteTitle", word: `${wrd.target.value}` }) }}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-[8px] flex items-center">
                                    <span className="txt-arena-gray text-[20px]"><BiSearch /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <NewsTable onRefresh={onRefresh} news={data} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewsCard;