import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import { Fade } from "react-awesome-reveal";
import NewsModel from "../../../models/News";
import NewsCard from "./NewsCard";

const NewsMore2 = ({ news, onResponse } : { news: Array<NewsModel>, onResponse: any }) => {
    const onClickLeft = () => {
        let strContainer: string = "more-container";
        if (window.innerWidth >= 300 && window.innerWidth <= 400) {
            strContainer = "more-containerm";
        }

        const carrousel = document.getElementById(strContainer);
        if (carrousel !== null) {
            carrousel.scrollLeft -= 70;
        }
    }

    const onClickRight = () => {
        let strContainer: string = "more-container";
        if (window.innerWidth >= 300 && window.innerWidth <= 400) {
            strContainer = "more-containerm";
        }

        const carrousel = document.getElementById(strContainer);
        if (carrousel !== null) {
            carrousel.scrollLeft += 70;
        }
    }

    const onResponseCard = (props: NewsModel) => {
        onResponse(props);
    }

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="text-left txt-arena-black font-bold py-[12px]">
                    <h3 className="text-[25px]">Podrían interesarte</h3>
                </div>
                <div id="more-container" className="flex overflow-hidden space-x-[50px]">
                    <div className="flex items-center">
                        <button
                            className="scale-100 hover:scale-110 ease-in duration-150 absolute bg-arena-gray txt-arena-white left-[30px] lg:left-[70px] px-[15px] py-[15px] rounded-full z-40"
                            type="button"
                            onClick={onClickLeft}
                        >
                            <span className="text-[25px]"><AiOutlineArrowLeft /></span>
                        </button>
                        <button
                            className="scale-100 hover:scale-110 ease-in duration-150 absolute bg-arena-gray txt-arena-white right-[30px] lg:right-[70px] px-[15px] py-[15px] rounded-full z-40"
                            type="button"
                            onClick={onClickRight}
                        >
                            <span className="text-[25px]"><AiOutlineArrowRight /></span>
                        </button>
                    </div>
                    {
                        news.map((newElement: NewsModel, i: number) => {
                            if (i < 10 && `${newElement.noteCreatedAt}`!== "NaN") {
                                return(
                                    <div key={i} className="flex-shrink-0 scale-100 hover:scale-105 ease-in duration-150">
                                        <Fade>
                                            <NewsCard onResponse={onResponseCard} newElement={newElement} />
                                        </Fade>
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div className="text-left txt-arena-black font-bold py-[2vw]">
                    <h3 className="text-[5vw]">Podrían interesarte</h3>
                </div>
                <div className="body-carrousel-newsc">
                    <div className="slider-carrousel-newsc">
                        <div className="slides-carrousel-newsc">
                            {
                                news.map((newElement: NewsModel, i: number) => {
                                    return(
                                        <div key={i} className="flex-shrink-0">
                                            <Fade>
                                                <NewsCard onResponse={onResponseCard} newElement={newElement} />
                                            </Fade>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NewsMore2;