import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import RegisterForm from "./RegisterForm";

const Register = () => {
    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });
    
    return (
        <>
            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({...popupTP, status: false, documentStr: ""}) }}
                    />
                : null
            }

            <div className="bg-arena-gray">
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-40">
                        <Navbar />
                    </div>
                </div>
                <div className="w-[100%] pt-0 m-auto md:w-[75%] md:pt-[50px]">
                    <RegisterForm />
                </div>
                <div className="pt-10">
                    <Fade>
                        <Footer
                            statusPop={(opt: any) => {
                                setPopupTP({...popupTP, status: opt.status, documentStr: opt.documentStr })
                            }}
                        />
                    </Fade>
                </div>
            </div>
        </>
    );
}

export default Register;