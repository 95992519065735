import NewsModel from "../../models/News";
import { BiCalendar } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useState } from "react";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { Fade } from "react-awesome-reveal";

interface PageInfo {
  page: number,
  elements: number,
  firstIndex: number,
  lastIndex: number
}

const NewsMore = ({ news }: { news: Array<NewsModel> }) => {
  const [pagination, setPagination] = useState<PageInfo>({
    page: 1,
    elements: 6,
    firstIndex: 0,
    lastIndex: 5
  });

  const onChangePage = (place: string) => {
    if (place === "right" && (news.length > (pagination.page * pagination.elements))) {
      setPagination({...pagination,
        page: pagination.page + 1,
        elements: pagination.elements,
        firstIndex: pagination.firstIndex + pagination.elements,
        lastIndex: pagination.lastIndex + pagination.elements
      });
    } else if (place === "left" && pagination.page > 1 ) {
      setPagination({...pagination,
        page: pagination.page - 1,
        elements: pagination.elements,
        firstIndex: pagination.firstIndex - pagination.elements,
        lastIndex: pagination.lastIndex - pagination.elements
      });
    }
  }

  return (
    <>
      {/* Desktop */}
      <div className="hidden md:block">
        <div className="pt-[75px] md:w-[550px] lg:w-[850px] m-auto">
          <div className="py-[30px] text-left text-[25px] font-bold">
            <Fade>
              <h3>Las Noticias más recientes</h3>
            </Fade>
          </div>
          <div className="relative">
              <button
                  className="absolute bg-transparent md:top-[400px] lg:top-[200px] left-[-50px] txt-arena-gray z-40"
                  type="button"
                  onClick={() => onChangePage("left")}
              >
                  <span className="text-[50px]"><MdOutlineArrowBackIosNew /></span>
              </button>
              <button
                  className="absolute bg-transparent md:top-[400px] lg:top-[200px] right-[-50px] txt-arena-gray z-40"
                  type="button"
                  onClick={() => onChangePage("right")}
              >
                  <span className="text-[50px]"><MdOutlineArrowForwardIos /></span>
              </button>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:grid-rows-3">
            {news.map((newElement: NewsModel, i: number) => {
              if ((i >= pagination.firstIndex && i <= pagination.lastIndex) && `${newElement.noteCreatedAt}`!== "NaN") {
                return (
                  <Link
                    key={i}
                    to="/news/detail"
                    state={{ index: i, array: news }}
                  >
                    <Fade>
                      <div className="relative flex mb-[15px] scale-100 hover:scale-105 ease-in duration-150">
                        <div className="w-[50%] bg-arena-black m-auto">
                          <img className="w-auto h-[130px] lg:h-[150px] m-auto" src={newElement.noteImageURL ? newElement.noteImageURL[0] : ""} />
                        </div>
                        <div className="w-[50%] px-[15px]">
                          <p className="text-left lg:text-[15px] font-bold">
                            {newElement.noteTitle}
                          </p>
                          <div className="absolute flex justify-end items-center bottom-0 space-x-[10px]">
                            <span className="txt-arena-gray text-[16px]">
                              <BiCalendar />
                            </span>
                            <p className="text-[13px]">
                              Hace {newElement.noteCreatedAt} día(s)
                            </p>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </Link>
                );
              }
            })}
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="md:hidden">
        <div className="pt-[25vw]">
          <div className="py-[4vw] text-left text-[5vw] font-bold">
            <Fade><h3>Las Noticias más recientes</h3></Fade>
          </div>
          <div>
            {news.map((newElement: NewsModel, i: number) => {
              if (i < 6 && (`${newElement.noteCreatedAt}`!== "NaN")) {
                return (
                  <Link
                    key={i}
                    to="/news/detail"
                    state={{ index: i, array: news }}
                  >
                    <Fade>
                      <div className="relative flex mb-[3vw]">
                        <div className="w-[50%]">
                          <img
                            className="w-[40vw] h-auto"
                            src={newElement.noteImageURL ? newElement.noteImageURL[0] : ""}
                          />
                        </div>
                        <div className="w-[50%]">
                          <p className="text-left text-[3.5vw] font-bold">
                            {newElement.noteTitle}
                          </p>
                          <div className="absolute flex justify-end items-center bottom-0 space-x-[1vw]">
                            <span className="txt-arena-gray text-[4vw]">
                              <BiCalendar />
                            </span>
                            <p className="text-[3vw]">
                              Hace {newElement.noteCreatedAt} día(s)
                            </p>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </Link>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsMore;
