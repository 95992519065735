import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import IconTop from "../../assets/icons/logo_arena_mov.png";
import LogoX2 from "../../assets/icons/63_arena_logo_navbar.svg";
import IconVersus from "../../assets/icons/versus_icon.svg"
import IconPlatinum from "../../assets/icons/platinum_icon.svg"
import IconImmersive from "../../assets/icons/immersive_icon.svg"
import IconNextLevel from "../../assets/icons/next_level_icon.svg"
import IconRetro from "../../assets/icons/retro_icon.svg"

const Homepageheader = ({ type }: any) => {
   
    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block" style={{ marginTop: '-500px' }}>
                <div style={{ height: '490px' }}></div>
                <div className="mt-[70px]">
                    <div className="p-[30px] flex justify-center w-full">
                        <div className="w-100">
                            <img src={LogoX2} />
                        </div>
                    </div>

                    <div className="w-[400px] glassmorphism py-[15px] scale-100 hover:scale-105 ease-in duration-150 m-auto">
                        <Link to="/booking">
                            <Fade>
                                <div className="text-left txt-arena-white font-bold">
                                    <div className="flex items-center w-[90%] m-auto">
                                        <span className="w-[90%] text-[18px] font-bold">Reserva una sala</span>
                                        <span className="w-[10%] text-[24px]"><AiOutlineArrowRight /></span>
                                    </div>
                                </div>
                            </Fade>
                        </Link>
                    </div>
                    <div className="txt-arena-white mt-[20px]">
                        <span className="font-bold">Experiencias Diseñadas para ti </span>
                        | Ven y vive la experiencia en nuestras salas
                    </div>
                    <div className="flex my-[30px]">
                        <div className="w-[400px] glassmorphism py-[10px] scale-100 hover:scale-105 ease-in duration-150 m-auto cusor-pointer" onClick={() => {
                            type("Versus Zone")
                        }}>
                            <Fade>
                                <div className="text-left txt-arena-white font-bold">
                                    <div className="flex items-center w-[90%] m-auto">
                                        <span className="w-[90%] text-[18px] font-bold">Versus Zone</span>
                                        <div>
                                            <img width={'50px'} src={IconVersus} />
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                        <div className="w-[400px] glassmorphism py-[10px] scale-100 hover:scale-105 ease-in duration-150 m-auto mx-[15px] cusor-pointer" onClick={() => {
                            type("Platinum")
                        }}>
                            <Fade>
                                <div className="text-left txt-arena-white font-bold">
                                    <div className="flex items-center w-[90%] m-auto">
                                        <span className="w-[90%] text-[18px] font-bold">Platinum</span>
                                        <div>
                                            <img width={'50px'} src={IconPlatinum} />
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                        <div className="w-[400px] glassmorphism py-[10px] scale-100 hover:scale-105 ease-in duration-150 m-auto cusor-pointer" onClick={() => {
                            type("Immersive VR")
                        }}>
                            <Fade>
                                <div className="text-left txt-arena-white font-bold">
                                    <div className="flex items-center w-[90%] m-auto">
                                        <span className="w-[90%] text-[18px] font-bold">Immersive VR</span>
                                        <div>
                                            <img width={'50px'} src={IconImmersive} />
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                        <div className="w-[400px] glassmorphism py-[10px] scale-100 hover:scale-105 ease-in duration-150 m-auto mx-[15px] cusor-pointer" onClick={() => {
                            type("Next Level")
                        }}>
                            <Fade>
                                <div className="text-left txt-arena-white font-bold">
                                    <div className="flex items-center w-[90%] m-auto">
                                        <span className="w-[90%] text-[18px] font-bold">Next Level</span>
                                        <div>
                                            <img width={'50px'} src={IconNextLevel} />
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                        <div className="w-[400px] glassmorphism py-[10px] scale-100 hover:scale-105 ease-in duration-150 m-auto cusor-pointer" onClick={() => {
                            type("Retro Room")
                        }}>
                            <Fade>
                                <div className="text-left txt-arena-white font-bold">
                                    <div className="flex items-center w-[90%] m-auto">
                                        <span className="w-[90%] text-[18px] font-bold">Retro Room</span>
                                        <div>
                                            <img width={'50px'} src={IconRetro} />
                                        </div>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden w-full justify-center height500px ">
                <div className="absolute z-30 w-full">
                    <div className="">
                        <img className="w-[60vw] m-auto" src={LogoX2} />
                    </div>
                    <div className="px-[2vw] text-left text-white">
                        <div className="glassmorphism py-[4vw] my-[20px]">
                            <Link to="/booking">
                                <div className="text-left txt-arena-white font-bold">
                                    <Fade>
                                        <div className="flex items-center w-[90%] m-auto">

                                            <span className="w-[90%] text-[4.8vw] font-bold">Reserva una sala</span>
                                            <span className="w-[10%] text-[7vw]"><AiOutlineArrowRight /></span>
                                        </div>
                                    </Fade>
                                </div>
                            </Link>
                        </div>
                        <div className="txt-arena-white mt-[20px] text-center ">
                            <span className="font-bold">Experiencias Diseñadas para ti </span>
                            | Ven y vive la experiencia en nuestras salas
                        </div>

                        <div className="px-[20px] mt-[20px]">
                            <div className="w-full glassmorphism py-[15px] scale-100 hover:scale-105 ease-in duration-150 m-auto cusor-pointer" onClick={() => {
                                type("VersusZone")
                            }}>
                                <Fade>
                                    <div className="text-left txt-arena-white font-bold">
                                        <div className="flex items-center w-[90%] m-auto">
                                            <div>
                                                <img width={'50px'} src={IconVersus} />
                                            </div>
                                            <span className="w-[90%] text-[18px] font-bold">Versus Zone</span>
                                            <span className="w-[10%] text-[24px]"><AiOutlineArrowRight /></span>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="w-full glassmorphism py-[15px] scale-100 hover:scale-105 ease-in duration-150 m-auto cusor-pointer my-3" onClick={() => {
                                type("Platinum")
                            }}>
                                <Fade>
                                    <div className="text-left txt-arena-white font-bold">
                                        <div className="flex items-center w-[90%] m-auto">
                                            <div>
                                                <img width={'50px'} src={IconPlatinum} />
                                            </div>
                                            <span className="w-[90%] text-[18px] font-bold">Platinum</span>
                                            <span className="w-[10%] text-[24px]"><AiOutlineArrowRight /></span>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="w-full glassmorphism py-[15px] scale-100 hover:scale-105 ease-in duration-150 m-auto cusor-pointer" onClick={() => {
                                type("Immersive")
                            }}>
                                <Fade>
                                    <div className="text-left txt-arena-white font-bold">
                                        <div className="flex items-center w-[90%] m-auto">
                                            <div>
                                                <img width={'50px'} src={IconImmersive} />
                                            </div>
                                            <span className="w-[90%] text-[18px] font-bold">Immersive VR</span>
                                            <span className="w-[10%] text-[24px]"><AiOutlineArrowRight /></span>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="w-full glassmorphism py-[15px] scale-100 hover:scale-105 ease-in duration-150 m-auto cusor-pointer my-3" onClick={() => {
                                type("NextLevel")
                            }}>
                                <Fade>
                                    <div className="text-left txt-arena-white font-bold">
                                        <div className="flex items-center w-[90%] m-auto">
                                            <div>
                                                <img width={'50px'} src={IconNextLevel} />
                                            </div>
                                            <span className="w-[90%] text-[18px] font-bold">Next Level</span>
                                            <span className="w-[10%] text-[24px]"><AiOutlineArrowRight /></span>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                            <div className="w-full glassmorphism py-[15px] scale-100 hover:scale-105 ease-in duration-150 m-auto cusor-pointer" onClick={() => {
                                type("RetroRoom")
                            }}>
                                <Fade>
                                    <div className="text-left txt-arena-white font-bold">
                                        <div className="flex items-center w-[90%] m-auto">
                                            <div>
                                                <img width={'50px'} src={IconRetro} />
                                            </div>
                                            <span className="w-[90%] text-[18px] font-bold">Retro Room</span>
                                            <span className="w-[10%] text-[24px]"><AiOutlineArrowRight /></span>
                                        </div>
                                    </div>
                                </Fade>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default Homepageheader;