import { useEffect, useState, useContext } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { BsCheck, BsCheckLg } from "react-icons/bs";
import { IoMdClose } from "react-icons/io";
import Loading from "../../../components/loading/Loading";
import PopNotify from "../../../components/Pop/PopNotify";
import CompanyModel from "../../../models/Company";
import PopupModel from "../../../models/Popup";
import { updateCompany } from "../../../services/Company";
import PopupPop from "./PopupPop";
import PopupTable from "./PopupTable";
import { MdDelete } from 'react-icons/md'
import PopupVideos from "./popUpVideos";
import { GlobalContext } from "../../../context/global_context";
import { updateUrlVideos } from "../../../services/Carrousel";

interface FilterData {
    inputType: string;
    inputName: string;
    word: string;
}

interface PopInfo {
    status: boolean,
    type: string
}

const PopupCard = ({ popups, statePops, onRefresh }: { popups: Array<PopupModel>, statePops: CompanyModel, onRefresh: any }) => {
    const [statusModal, setStatusModal] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [data, setData] = useState<Array<PopupModel>>([]);
    const [popupUniv, setPopupUniv] = useState<Array<PopupModel>>([]);

    const { globalState, setGlobalState } = useContext(GlobalContext)
  


    const filter = (type: FilterData) => {
        if (type.inputType === "input") {
            let palabra = new RegExp(`${type.word}.*`, "i");
            let newPopups = popupUniv.filter((popEle) => palabra.test(popEle.popupTitle));
            setData(newPopups);
        } else {
            setData(popupUniv);
        }
    }

    const handleDeleteVideos = (element: any) => {
        if (globalState.videosURL.carouselLinksURL.length === 1) {
            // console.log("no puedes elimiar")
            setPopStatus({ ...popStatus, status: true, type: "warningVideo" });
        } else {
            setLoadingStatus(true);
            const newsVideos = globalState.videosURL.carouselLinksURL.filter((item: any) => {
                return element !== item
            })
            // console.log(globalState)

            const newArray = globalState.videosURL
            newArray.carouselLinksURL = newsVideos
            setGlobalState({ ...globalState, videosURL: newArray })

            // HACER PROMESA DE UPDATE

            const body = {
                companyID: "n6x4YlbQ2uQ06ZuQYv0h",
                carouselID: globalState.videosURL.carouselID,
                carouselTitle: globalState.videosURL.carouselTitle,
                carouselLinksURL: newsVideos
            }

            // console.log(body, "payload")


            new Promise<void>((resolve, reject) => {
                updateUrlVideos(body).then((res) => {
                    setLoadingStatus(false);
                    if (res && res.data) {
                        // console.log(res, "respuesta")
                        setPopStatus({ ...popStatus, status: true, type: "success" });
                    } else {
                        setPopStatus({ ...popStatus, status: true, type: "error" });
                    }

                })
                setTimeout(function () {
                    resolve();
                }, 1000);
            });
        }
    }

    const updatePopState = () => {
        setLoadingStatus(true);
        const companyModel: CompanyModel = {
            companyName: statePops.companyName,
            companyID: statePops.companyID,
            companyCreatedAt: statePops.companyCreatedAt,
            companyAddress: {
                street: statePops.companyAddress.street,
                number: statePops.companyAddress.number,
                interiorNumber: statePops.companyAddress.interiorNumber,
                neighborhood: statePops.companyAddress.neighborhood,
                city: statePops.companyAddress.city,
                state: statePops.companyAddress.state,
                country: statePops.companyAddress.country,
                zipCode: statePops.companyAddress.zipCode,
            },
            companySocialMedia: {
                facebook: statePops.companySocialMedia.facebook,
                instagram: statePops.companySocialMedia.instagram,
                twitter: statePops.companySocialMedia.twitter,
            },
            companyActivePopUps: !statePops.companyActivePopUps,
            companyNewsCount: statePops.companyNewsCount,
            companyPhone: statePops.companyPhone,
            companyNumberOfVenues: statePops.companyNumberOfVenues,
        }
        new Promise<void>((resolve, reject) => {
            updateCompany(companyModel).then(res => {
                if (res && res.data) {
                    setPopStatus({ ...popStatus, status: true, type: "success" });
                    onRefresh(true);
                } else {
                    setPopStatus({ ...popStatus, status: true, type: "error" });
                }
            });

            // Resolve promise
            setTimeout(function () {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    }

    const [popUpAddUrl, setPopUpAddUrl] = useState(false)

    useEffect(() => {
        setData(popups);
        setPopupUniv(popups);
    }, [popups]);

    return (
        <>
            {
                statusModal ?
                    <PopupPop onRefresh={onRefresh} popupElement={null} onResponse={() => { setStatusModal(false) }} />
                    : null
            }

            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({ ...popStatus, status: false, type: "" }) }} />
                    : null
            }

            {loadingStatus ? <Loading /> : null}

            {popUpAddUrl ?
                <PopupVideos onResponse={() => { setPopUpAddUrl(false) }} />

                :
                null
            }

            <div className="block">
                <div className="bg-arena-white m-[20px] md:m-[25px] space-y-[25px] px-[15px] py-[15px] md:space-y-[35px] md:px-[35px] md:py-[30px] rounded">
                    <div className="text-left font-bold">
                        <h3 className="text-[20px]">Avisos</h3>
                    </div>
                    <div className="flex space-x-[10px]">
                        <button className="flex items-center bg-arena-orange py-[5px] px-[15px] space-x-[40px] rounded-sm" type="button" onClick={() => { setStatusModal(true) }}>
                            <div className="text-left txt-arena-white font-bold">
                                <span className="text-[14px]">Añadir aviso</span>
                            </div>
                            <div className="txt-arena-white font-bold">
                                <span className="text-[20px]"><AiOutlinePlus /></span>
                            </div>
                        </button>
                        <button
                            className={"flex items-center py-[5px] px-[15px] space-x-[40px] rounded-sm " + `${statePops.companyActivePopUps ? "bg-red-600" : "bg-green-600"}`}
                            type="button"
                            title="Click para activar o desactivar Pop-up"
                            onClick={updatePopState}
                        >
                            <div className="text-left txt-arena-white font-bold">
                                <span className="text-[14px]">{statePops.companyActivePopUps ? "Desactivar avisos" : "Activar avisos"}</span>
                            </div>
                            <div className="txt-arena-white font-bold">
                                <span className="text-[20px] txt-arena-white">
                                    {statePops.companyActivePopUps ? <IoMdClose /> : <BsCheck />}
                                </span>
                            </div>
                        </button>
                    </div>
                    <div>
                        <div className="space-y-[20px] lg:flex lg:space-x-[10px] lg:space-y-0">
                            <div className="relative">
                                <input
                                    className="bg-transparent border-b border-zinc-500 py-[8px] px-0 md:px-[10px] w-full lg:w-[250px] txt-arena-gray text-[14px]"
                                    type="text"
                                    id="filter-aputext"
                                    placeholder="Buscar por nombre"
                                    onChange={(wrd) => { filter({ inputType: "input", inputName: "popupTitle", word: `${wrd.target.value}` }) }}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-[8px] flex items-center">
                                    <span className="txt-arena-gray text-[20px]"><BiSearch /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <PopupTable onRefresh={onRefresh} popups={data} />
                    </div>
                </div>
                <div className="bg-arena-white m-[20px] md:m-[25px] space-y-[25px] px-[15px] py-[15px] md:space-y-[35px] md:px-[35px] md:py-[30px] rounded">
                    <div className="text-left font-bold">
                        <h3 className="text-[20px]">Videos</h3>
                    </div>
                    <div className="flex space-x-[10px]">
                        <button className="flex items-center bg-arena-orange py-[5px] px-[15px] space-x-[40px] rounded-sm" type="button" onClick={() => { setPopUpAddUrl(true) }}>
                            <div className="text-left txt-arena-white font-bold">
                                <span className="text-[14px]">Añadir Video</span>
                            </div>
                            <div className="txt-arena-white font-bold">
                                <span className="text-[20px]"><AiOutlinePlus /></span>
                            </div>
                        </button>

                    </div>
                    <div className="text-left">
                        {globalState.videosURL.carouselLinksURL?.map((item: any, i: any) => {
                            return (
                                <div key={i} className="flex items-center">
                                    <div className="mr-[10px] w-[90%] mt-[10px] "><a target={'_blank'} href={item}>{item}</a></div>
                                    <div className="btn-icon-delete cursor-pointer" onClick={() => { handleDeleteVideos(item) }} ><MdDelete /></div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>

        </>
    );
}

export default PopupCard;