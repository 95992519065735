import { useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { auth, signOut } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import LogoX from "../../assets/icons/63_arena_logo_navbar.svg";
import Pop from "../Pop/Pop";
import "./NavbarAdmin.css";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";

const NavbarAdmin = () => {
    const [closeStatus, setCloseStatus] = useState<boolean>(false)
    const [userLogger, setUserLogger] = useState<any>("");

    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });
    const navigate = useNavigate();
    
    // Cerrar sesión...
    const signOutAccount = async () => {
        await signOut(auth);
        navigate("/");
    }

    // Verifica si existe una sesión activa, en caso de que no, reedirige al Home...
    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            if (user && user !== null) {
                setUserLogger(user.displayName);
            } else {
                navigate("/");
            }
        });
    }, []);

    return (
        <>
            {
                closeStatus ?
                    <Pop title="¿Esta seguro que desea cerrar sesión?" message="" onClose={() => {setCloseStatus(false) }} onResponse={signOutAccount} />
                : null
            }
            {
                popupTP.status ? 
                    // <Pop title="Documentación" message="" onClose={() => {setstate({...state, showPdf:false}) }} onResponse={()=>{}} />
                    <PDFViewer
                        documentStr={"documento"}
                        closeModal={() => { 
                            setPopupTP({...popupTP, status: false, documentStr: ""})
                            // setstate({...state, showPdf:false})
                         }}
                    />
                :
                null
            }

            <div className="block">
                <div className="bg-arena-gray">
                    <div className="mx-auto px-[20px] py-[15px] md:px-[50px] md:py-[5px]">
                        <div className="flex justify-between items-center">
                            <div>
                                <img className="w-[200px] md:w-[250px]" src={LogoX} />
                            </div>
                            <div className="flex items-center justify-end flex-1 lg:w-0">
                                <div className="hidden md:block">
                                    <div className="dropdown py-[8px]">
                                        <button id="dropdown-navbarAdmin" className="dropbtn">
                                            <div className="flex items-center space-x-[15px] font-bold">
                                                <span className="text-[16px]">{userLogger}</span>
                                                <span className="text-[20px]"><BsChevronDown /></span>
                                            </div>
                                        </button>
                                   
                                        <div id="dropdown-content-navbarAdmin" className="dropdown-content">
                                            <a onClick={() => setPopupTP({...popupTP, status: true, documentStr: "Documento"}) } href="#">Documentación</a>
                                            <a onClick={() => setCloseStatus(true) } href="#">Salir</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="md:hidden">
                                    <button className="txt-arena-white" type="button" onClick={() => setCloseStatus(true) }>
                                        <span className="text-[20px]"><FaSignOutAlt /></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NavbarAdmin;