import { useEffect, useState, useContext } from "react";
import { uploadVideosCarrousel } from "../../../firebase";
import { IDCompany } from "../../../global/global";
// import { updateCarousel } from "../../../services/Popup";

import Loading from "../../../components/loading/Loading";
import PopNotify from "../../../components/Pop/PopNotify";
import PopupModel from "../../../models/Popup";
import { HiPhotograph } from "react-icons/hi";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillCameraVideoFill, BsPlusCircleFill } from "react-icons/bs";
import { GlobalContext } from "../../../context/global_context";
import { updateUrlVideos } from "../../../services/Carrousel";

interface ImageData {
    name: string;
    file: any;
}

interface PopInfo {
    status: boolean,
    type: string
}

const PopupFormVideos = ({onClose}: {onClose:any} ) => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });

    // CONTEXTO GLOBAL
    const { globalState } = useContext(GlobalContext)
    // ERROR SUBIDA DE OTRA EXTENSION
    const [state, setState] = useState({
        error: false,
        errorTitle: ""
    })

    const [videoUpload, setVideoUpload] = useState<ImageData>({
        name: "",
        file: null
    });



    // ESTADO PARA UN BODY OJO NO ES NECESARIO 

    // const [popupData, setPopupData] = useState({
    //     companyID: { value: `${IDCompany}`, isCorrect: true },
    //     popupID: { value: "", isCorrect: false },
    //     popupTitle: { value: "", isCorrect: false },
    //     popupImageURL: { value: [], isCorrect: false },
    //     popupVideoURL: { value: "", isCorrect: false },
    //     popupCreatedBy: { value: "", isCorrect: false }
    // });

    const deleteVideo = () => {
        setVideoUpload({ ...videoUpload, name: "", file: null });
    }

    const handleVideoUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const file: any = evt.currentTarget.files;
        setState({ ...state, error: false })
        const extensionFile = file[0].name.split('.').pop();
        if (extensionFile === "mp4" || extensionFile === "mov" || extensionFile === "wmv" || extensionFile === "avi") {
            if (file !== null) {
                setVideoUpload({ ...videoUpload, name: file[0].name, file: file[0] });
            }
        } else {
            setState({ ...state, error: true, errorTitle: "Sólo puedes subir archivos de video" })
        }
    }

    const handleSubmit = async () => {
        setLoadingStatus(true);
        // console.log(videoUpload)
        if (videoUpload.file) {
           
            let videoUrlStr: string = "";
            if (videoUpload.file) {
                videoUrlStr = await uploadVideosCarrousel(videoUpload.file, "popups");
            }


            const urls = globalState.videosURL.carouselLinksURL
            urls.push(videoUrlStr)

            const body = {
                companyID: "n6x4YlbQ2uQ06ZuQYv0h",
                carouselID: globalState.videosURL.carouselID,
                carouselTitle: globalState.videosURL.carouselTitle,
                carouselLinksURL: urls
            }
  
            // console.log(body , "payload")

            new Promise<void>((resolve, reject) => {
                updateUrlVideos(body).then((res) => {
                    setLoadingStatus(false);
                    if (res && res.data) {
                        // console.log(res,"respuesta")
                        setPopStatus({ ...popStatus, status: true, type: "success" });
                        deleteVideo()
                        onClose()
                    } else {
                        setPopStatus({ ...popStatus, status: true, type: "error" });
                    }

                })
                setTimeout(function () {
                    resolve();
                }, 1000);
            });
        } else {
            setLoadingStatus(false);
            setState({ ...state, error: true, errorTitle: "Carga un archivo de video" })
        }

    }

    return (
        <>
            {loadingStatus ? <Loading /> : null}

            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({ ...popStatus, status: false, type: "" }) }} />
                    : null
            }

            <div className="block">
                <div className="mb-4 space-y-2">
                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                        Video
                    </label>
                    <div className="flex items-center justify-center w-full">
                        <label className="w-full h-12 border-4 border-dashed">
                            <div className="flex space-x-2 p-2">
                                <span className="text-[25px] text-zinc-400"><BsFillCameraVideoFill /></span>
                                <div>
                                    {videoUpload.name ?
                                        <div className="flex">
                                            <span
                                                className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                            >
                                                {videoUpload.name}
                                            </span>
                                            <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteVideo()}>
                                                <AiFillCloseCircle />
                                            </button>
                                        </div>
                                        :
                                        <div className="flex">
                                            <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                Cargar video...
                                            </span>
                                            <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                        </div>
                                    }
                                </div>
                            </div>
                            <input type="file" className="opacity-0" name="noteVideoURL" accept="video/mp4,video/x-m4v,video/*" onChange={handleVideoUpload} />
                        </label>
                    </div>
                </div>
                <div style={{ height: '30px', color: 'red' }}>{state.error ? state.errorTitle : null}</div>
                <div className="flex justify-end pt-[5px]">
                    <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" onClick={handleSubmit}>
                        Agregar Video
                    </button>
                </div>
                {/* <form onSubmit={handleSubmit}>
                    <div className="px-2 text-left">
                        <div className="space-y-7">
                            <div className="space-y-5">
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">

                                    </label>
                                    <input
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                        id="popupTitleInput"
                                        type="text"
                                        placeholder="Nombre del aviso..."
                                        name="popupTitle"
                                        onChange={handleChange}
                                        value={popupData.popupTitle.value}
                                    />
                                </div>

                                <div className="mr-[10px] w-[60%] mt-[10px] ">https://www.youtube.com/embed/AvYVljnCsjA</div>
                                <div className="mr-[10px] w-[60%] mt-[10px] ">https://www.youtube.com/embed/c8HY4msV0Xw</div>
                                <div className="mr-[10px] w-[60%] mt-[10px] ">https://www.youtube.com/embed/2cMTSBVLeFc</div>
                                <div className="mr-[10px] w-[60%] mt-[10px] ">https://www.youtube.com/embed/AvYVljnCsjA</div>
                                <div className="flex justify-end pt-[5px]">
                                    <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" type="submit">
                                        Agregar Video
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form> */}
            </div>
        </>
    );
}

export default PopupFormVideos;