import { functions, httpsCallable } from "../firebase";
// import CarrouselModel from "../models/Carrousel";

export const getUrlVideos = (data: any) => {
    const getUrlVideos = httpsCallable(functions, "readAllCarousel");
    const res = getUrlVideos(data);
    return res;
}

export const deletedUrlVideos = (data: any) => {
    const deletedUrlVideos = httpsCallable(functions, "deleteCarousel");
    const res = deletedUrlVideos(data);
    return res;
}
export const updateUrlVideos = (data: any) => {
    const updateUrlVideos = httpsCallable(functions, "updateCarousel");
    const res = updateUrlVideos(data);
    return res;
}