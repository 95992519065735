import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import Select from "../../../components/select/Select";
import TournamentDropModel from "../../../models/TournamentDrop";
import DropsPop from "./DropsPop";
import DropsTable from "./DropsTable";

interface FilterData {
    inputType: string;
    inputName: string;
    word: string;
}

const DropsCard = ({
    drops,
    tournaments,
    type,
    mode,
    plataform,
    venues,
    onRefresh
} : {
    drops: Array<TournamentDropModel>,
    tournaments: Array<TournamentDropModel>,
    type: Array<any>,
    mode: Array<any>,
    plataform: Array<any>,
    venues: Array<any>,
    onRefresh: any
}) => {
    const [statusModal, setStatusModal] = useState<boolean>(false);
    const [data, setData] = useState<Array<TournamentDropModel>>([]);
    const [dropsUniv, setDropsUniv] = useState<Array<TournamentDropModel>>([]);

    const filter = (type: FilterData) => {
        if (type.inputType === "input") {
            const filter1 = document.getElementById("filter-adrop") as HTMLSelectElement | null;
            if (filter1 !== null) filter1.value = "default";

            let palabra = new RegExp(`${type.word}.*`, "i");
            let newUsers = dropsUniv.filter((dropElement) => palabra.test(dropElement.dropName));
            setData(newUsers);
        } else {
            if (type.inputName === "dropType") {
                const filter1 = document.getElementById("filter-dtext") as HTMLSelectElement | null;
                if (filter1 !== null) filter1.value = "";

                const usersFound = dropsUniv.filter((dropElement) => (dropElement.dropType === type.word));
                setData(usersFound);
            }
        }
    }
    
    useEffect(() => {
        let newArr: Array<TournamentDropModel> = [];
        newArr = newArr.concat(tournaments, drops);
        setData(newArr);
        setDropsUniv(newArr);
    }, [tournaments, drops]);

    return (
        <>
            {
                statusModal ?
                    <DropsPop
                        dtElement={null}
                        type={type}
                        mode={mode}
                        plataform={plataform}
                        venues={venues}
                        onResponse={() => {setStatusModal(false) }}
                        onRefresh={onRefresh}
                    />
                : null
            }
            
            <div className="block">
                <div className="bg-arena-white m-[20px] md:m-[25px] space-y-[25px] px-[15px] py-[15px] md:space-y-[35px] md:px-[35px] md:py-[30px] rounded">
                    <div className="text-left font-bold">
                        <h3 className="text-[20px]">Drops, Dinámicas y Torneos</h3>
                    </div>
                    <div className="flex space-x-[10px]">
                        <button className="flex items-center bg-arena-orange py-[5px] px-[15px] space-x-[40px] rounded-sm" type="button" onClick={() => { setStatusModal(true) }}>
                            <div className="text-left txt-arena-white font-bold">
                                <span className="text-[14px]">Añadir drop, dinámica o torneo</span>
                            </div>
                            <div className="txt-arena-white font-bold">
                                <span className="text-[20px]"><AiOutlinePlus /></span>
                            </div>
                        </button>
                    </div>
                    <div>
                        <div className="space-y-[20px] lg:flex lg:space-x-[22px] lg:space-y-0">
                            <div className="relative">
                                <input
                                    className="bg-transparent border-b border-zinc-500 py-[8px] px-0 md:px-[12px] w-full lg:w-[270px] txt-arena-gray text-[14px]"
                                    type="text"
                                    id="filter-dtext"
                                    placeholder="Buscar drop, dinámica / torneo"
                                    onChange={(wrd) => { filter({ inputType: "input", inputName: "dropName", word: `${wrd.target.value}` }) }}
                                />
                                <div className="pointer-events-none absolute inset-y-0 right-[8px] flex items-center">
                                    <span className="txt-arena-gray text-[20px]"><BiSearch /></span>
                                </div>
                            </div>
                            <div>
                                <Select
                                    className="bg-transparent border-b border-zinc-500 py-[11px] px-0 md:px-[10px] w-full lg:w-[250px] txt-arena-gray text-[14px]"
                                    options={type}
                                    onResponse={(wrd: any) => { filter({ inputType: "select", inputName: "dropType", word: `${type[wrd.target.value].value}` }) }}
                                    name="filter-adrop"
                                    id="filter-adrop"
                                    placeholder="Filtrar por tipo"
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <DropsTable type={type} mode={mode} plataform={plataform} venues={venues} data={data} onRefresh={onRefresh} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default DropsCard;