import RealeseModel from "../../models/Realese";
import RealesesCard from "./RealesesCard";
import RealesesFilter from "./RealesesFilter";
import IconControl from "../../assets/icons/realeses/02_icon_lanzamientos.png";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";

interface PageInfo {
    page: number,
    elements: number,
    firstIndex: number,
    lastIndex: number
}

const RealesesBody = ({
    realeses,
    plataform,
    stars,
    onModalDetail
} : {
    realeses: Array<RealeseModel>,
    plataform: Array<any>,
    stars: Array<any>,
    onModalDetail: any
}) => {
    const [realesesData, setRealesesData] = useState<Array<RealeseModel>>([]);
    const [realesesUniv, setRealesesUniv] = useState<Array<RealeseModel>>([]);
    const [pagination, setPagination] = useState<PageInfo>({
        page: 1,
        elements: 8,
        firstIndex: 0,
        lastIndex: 7
    });

    const onChangePage = (place: string) => {
        if (place === "right" && (realesesData.length > (pagination.page * pagination.elements))) {
            setPagination({...pagination,
                page: pagination.page + 1,
                elements: pagination.elements,
                firstIndex: pagination.firstIndex + pagination.elements,
                lastIndex: pagination.lastIndex + pagination.elements
            });
        } else if (place === "left" && pagination.page > 1 ) {
            setPagination({...pagination,
                page: pagination.page - 1,
                elements: pagination.elements,
                firstIndex: pagination.firstIndex - pagination.elements,
                lastIndex: pagination.lastIndex - pagination.elements
            });
        }
    }

    const onFilter = (opt: any, type: string) => {
        let newRealeses: Array<RealeseModel> = [];
        if (type === "input") {
            let palabra = new RegExp(`${opt}.*`, "i");
            newRealeses = realesesUniv.filter((realesesElement) => palabra.test(realesesElement.gameTitle));
            setRealesesData(newRealeses);
        } else if (type === "select") {
            newRealeses = realesesUniv.filter((relElement: RealeseModel) =>
                (relElement.gamePlatform === opt.value || Number(relElement.gameScore) === opt.value)
            );
            setRealesesData(newRealeses);
        } else {
            setRealesesData(realesesUniv);
        }
    }

    useEffect(() => {
        setRealesesData(realeses);
        setRealesesUniv(realeses);
    }, [realeses]);

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block w-full justify-center mt-[-45px]">
                <div className="flex justify-center items-center m-auto z-50">
                    <div className="absolute">
                        <img className="relative w-[90px] top-[-110px] right-[150px] z-30" src={IconControl} />
                    </div>
                    <div className="glassmorphism w-[450px] py-[40px] pl-[35px] pr-[35px] space-y-[12px] text-left txt-arena-white">
                        <Fade cascade damping={0.1}>
                            <div className="pt-[25px]">
                                <h3 className="text-[20px] font-bold">Encuentra los mejores juegos</h3>
                            </div>
                            <p className="text-[16px]">
                                Cientos de títulos en videojuegos y opciones para todos los gustos, sumérgete en el mundo gamer y 
                                forma parte de la experiencia Arena Movistar.
                            </p>
                        </Fade>
                    </div>
                </div>
                <div>
                    <div className="text-left txt-arena-white font-bold lg:px-[150px] py-[50px]">
                        <Fade><h3 className="text-[20px]">Nuevos lanzamientos</h3></Fade>
                    </div>
                    <div className="w-[650px] lg:w-[850px] pb-[20px] m-auto">
                        <Fade>
                            <RealesesFilter plataform={plataform} stars={stars} onResponse={onFilter} />
                        </Fade>
                    </div>
                    <div className="relative">
                        <button
                            className="absolute bg-transparent md:top-[400px] lg:top-[200px] left-[-50px] txt-arena-white z-40"
                            type="button"
                            onClick={() => onChangePage("left")}
                        >
                            <span className="text-[50px]"><MdOutlineArrowBackIosNew /></span>
                        </button>
                        <button
                            className="absolute bg-transparent md:top-[400px] lg:top-[200px] right-[-50px] txt-arena-white z-40"
                            type="button"
                            onClick={() => onChangePage("right")}
                        >
                            <span className="text-[50px]"><MdOutlineArrowForwardIos /></span>
                        </button>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-4 md:w-[450px] lg:w-[850px] m-auto">
                        {
                            realesesData.map((realeseElement: RealeseModel, i: number) => {
                                if (i >= pagination.firstIndex && i <= pagination.lastIndex) {
                                    return(
                                        <div key={i} className="">
                                            <Fade>
                                                <RealesesCard
                                                    realeses={realeseElement}
                                                    onResponseModal={(relReponse: RealeseModel) => {
                                                        onModalDetail(relReponse, true)
                                                    }}
                                                />
                                            </Fade>
                                        </div>
                                    );
                                }
                            })
                        }
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden mt-[18vw]">
                <div className="flex justify-center items-center m-auto z-50">
                    <div className="absolute">
                        <img className="relative w-[22vw] top-[-25vw] right-[27vw] z-30" src={IconControl} />
                    </div>
                    <div className="glassmorphism w-[90vw] py-[7vw] px-[5vw] space-y-[2vw] text-left txt-arena-white">
                        <Fade cascade damping={0.1}>
                            <div className="pt-[2vw]">
                                <h3 className="text-[5vw] font-bold">Encuentra los mejores juegos</h3>
                            </div>
                            <p className="text-[4.3vw]">
                                Cientos de títulos en videojuegos y opciones para todos los gustos, sumérgete en el mundo gamer y 
                                forma parte de la experiencia Arena Movistar.
                            </p>
                        </Fade>
                    </div>
                </div>
                <div className="mt-[8vw]">
                    <div className="text-left txt-arena-white font-bold px-[5vw] py-[7vw]">
                        <Fade><h3 className="text-[5vw]">Nuevos lanzamientos</h3></Fade>
                    </div>
                    <div className="body-carrousel-rel">
                        <div className="slider-carrousel-rel">
                            <div className="slides-carrousel-rel">
                                {
                                    realeses.map((realeseElement: RealeseModel, i: number) => {
                                        return(
                                            <div key={i} className="flex-shrink-0">
                                                <Fade>
                                                    <RealesesCard
                                                        realeses={realeseElement}
                                                        onResponseModal={(relReponse: RealeseModel) => {
                                                            onModalDetail(relReponse, true)
                                                        }}
                                                    />
                                                </Fade>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RealesesBody;