import { useEffect, useState } from "react";
import { auth, signOut } from "../../firebase";
import { Link } from "react-router-dom";
import { FaSignOutAlt } from "react-icons/fa";
import { BsChevronDown } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import LogoY from "../../assets/icons/logo_arena_mov.png";
import LogoX2 from "../../assets/icons/63_arena_logo_navbar.svg";
import Sidebar from "../sidebar/Sidebar";
import Pop from "../Pop/Pop";
import "./Navbar.css";


interface LoginData {
    status: boolean;
    user: any;
}

interface JSONData {
    token: string;
    username: string;
}

const Navbar = () => {
    const [closeStatus, setCloseStatus] = useState<boolean>(false);
    const navigate = useNavigate();
    const [userLogger, setUserLogger] = useState<LoginData>({
        status: false,
        user: null
    });

    const signOutAccount = async () => {
        setUserLogger({ ...userLogger, status: false, user: "" });
        setCloseStatus(false);
        localStorage.clear();
        navigate("/");
    }

    useEffect(() => {
        const userInfo: JSONData = JSON.parse(localStorage.getItem('user-data') || '{}');
        if (userInfo && Object.keys(userInfo).length > 0) {
            setUserLogger({ ...userLogger, status: true, user: String(userInfo.username).toUpperCase() });
        } else {
            setUserLogger({ ...userLogger, status: false, user: "" });
        }
    }, []);

    return (
        <>
            {
                closeStatus ?
                    <Pop title="¿Esta seguro que desea cerrar sesión?" message="" onClose={() => { setCloseStatus(false) }} onResponse={signOutAccount} />
                    : null
            }

            {/* Desktop max-w-7xl */}
            <div className="bg-arena-cgrayo hidden md:block">
                <div className="md:w-[95%] lg:w-[85%] flex justify-between items-center mx-auto">
                    <div className="flex justify-start">
                        <Sidebar />
                    </div>
                    <div className="ease-in-out hover:scale-110 duration-300">
                        <Link to="/">
                            <div className="flex justify-center items-center space-x-[7px]">
                                <img className="md:hidden lg:block w-[280px]" src={LogoX2} />
                                <img className="md:block lg:hidden w-[95px]" src={LogoY} />
                            </div>
                        </Link>
                    </div>
                    <div className="flex items-center justify-end">
                        <Link to="/venues">
                            <button
                                className="md:w-[120px] lg:w-[140px] btn-navbar bg-transparent duration-200 hover:text-orange-600 hover:text-[11px]" type="button">
                                <span className="md:text-[10px] lg:text-[12px] md:tracking-[1.5px] lg:tracking-[3px]">
                                    SUCURSALES
                                </span>
                            </button>
                        </Link>
                        <Link to="/booking">
                            <button className="md:w-[120px] lg:w-[140px] btn-navbar bg-transparent duration-200 hover:text-orange-600 hover:text-[11px]" type="button">
                                <span className="md:text-[10px] lg:text-[12px] md:tracking-[1.5px] lg:tracking-[3px]">
                                    RESERVA
                                </span>
                            </button>
                        </Link>
                        <Link to="/register">
                            <button className="md:w-[120px] lg:w-[140px] btn-navbar bg-transparent duration-200 hover:text-orange-600 hover:text-[11px]" type="button">
                                <span className="md:text-[10px] lg:text-[12px] md:tracking-[1.5px] lg:tracking-[3px]">
                                    REGISTRO
                                </span>
                            </button>
                        </Link>
                        {
                            userLogger.status ?
                                <div className="bg-arena-black flex btn-navbar items-center justify-end">
                                    <div className="hidden md:block">
                                        <div className="dropdown">
                                            <button className="w-auto duration-200 hover:text-orange-600">
                                                <div className="flex items-center space-x-[10px]">
                                                    <span className="md:text-[10px] lg:text-[12px] md:tracking-[1.5px] lg:tracking-[3px]">{userLogger.user}</span>
                                                    <span className="md:text-[10px] lg:text-[12px] md:tracking-[1.5px] lg:tracking-[3px]"><BsChevronDown /></span>
                                                </div>
                                            </button>
                                            <div id="dropdown-content-navbarAdmin" className="dropdown-content">
                                                <Link to="/myAccount">
                                                    Mi cuenta
                                                </Link>
                                                <a className="cursor-pointer" onClick={() => setCloseStatus(true)}>Cerrar sesión</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="md:hidden">
                                        <button className="txt-arena-white" type="button" onClick={() => setCloseStatus(true)}>
                                            <span className="text-[20px]"><FaSignOutAlt /></span>
                                        </button>
                                    </div>
                                </div>
                                :
                                <Link to="/login">
                                    <button className="md:w-[150px] lg:w-[180px] btn-navbar bg-arena-black duration-200 hover:text-orange-600 hover:text-[11px]" type="button">
                                        <span className="md:text-[10px] lg:text-[12px] md:tracking-[1.5px] lg:tracking-[3px]">
                                            INICIAR SESIÓN
                                        </span>
                                    </button>
                                </Link>
                        }
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="bg-arena-grayo md:hidden " style={{width:'100%',position:'fixed', top:'0',marginTop:'0'}}>
                <div className="max-w-7xl mx-auto px-7 sm:px-6">
                    <div className="flex justify-between items-center md:justify-start md:space-x-10">
                        <div className="flex justify-start">
                            <Sidebar />
                        </div>
                        <nav className="m-auto">
                            <Link to="/">
                                <div className="flex justify-center items-center space-x-[15px]">
                                    <img className="w-[45vw]" src={LogoX2} />
                                </div>
                            </Link>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Navbar;