import { useEffect, useState } from "react";
import { getUsers } from "../../../services/Users";
import NavbarAdmin from "../../../components/navbar-admin/NavbarAdmin";
import SidebarAdmin from "../../../components/sidebar-admin/SidebarAdmin";
import UserModel from "../../../models/User";
import UserCard from "./UserCard";
import Loading from "../../../components/loading/Loading";

const AdminUser = () => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [users, setUsers] = useState<Array<UserModel>>([]);
    const typeOptions = [
        { label: "ADMIN", value: "ADMIN" }
    ];

    const getUsersData = () => {
        new Promise<void>((resolve, reject) => {
            // Get all users
            getUsers().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setUsers(newArr);
                }
            });

            // Resolve promise
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    }

    useEffect(() => {
        getUsersData();
    }, []);

    return (
        <div className="flex flex-row w-full h-screen">
            { loadingStatus ? <Loading /> : null }
            <div className="w-[65px] md:w-[250px] lg:w-[370px]">
                <SidebarAdmin />
            </div>
            <div className="w-full bg-zinc-300 flex-grow flex-shrink flex-auto overflow-y-scroll">
                <div>
                    <NavbarAdmin />
                </div>
                <div>
                    <UserCard onRefresh={getUsersData} users={users} typeOptions={typeOptions} />
                </div>
            </div>
        </div>
    );
}

export default AdminUser;