const Pop = ({ message, title, onClose, onResponse } : { message: string, title: string, onClose: any, onResponse: any }) => {
    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[500px]">
                        <div className="p-[20px] border-0 rounded-lg shadow-lg flex-col w-full bg-arena-white outline-none focus:outline-none">
                            <div className="relative px-[8px] py-[20px] flex-auto">
                                <div className="space-y-[15px]">
                                    <div className="text-center font-bold">
                                        <h3 className="text-[20px]">{ title }</h3>
                                    </div>
                                    <div className="text-center">
                                        <p className="text-[16px]">{ message }</p>
                                    </div>
                                    <div className="flex justify-center items-center space-x-[10px]">
                                        <button
                                        type="button"
                                            className="px-[10px] py-[8px] bg-arena-black txt-arena-white text-[14px] rounded-sm"
                                            onClick={() => onResponse(true)}
                                        >
                                            Confirmar
                                        </button>
                                        <button
                                        type="button"
                                            className="px-[10px] py-[8px] bg-arena-gray txt-arena-white text-[14px] rounded-sm"
                                            onClick={() => onClose(false)}
                                        >
                                            Cancelar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default Pop;