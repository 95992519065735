const Select = (
    { options, id, className, name, placeholder, onResponse } : {
        options: Array<Object>,
        id: string,
        className: string,
        name: string,
        placeholder: string,
        onResponse: any
    }) => {
    
    const onChangeSelect = (evt: React.ChangeEvent<HTMLSelectElement | HTMLOptionElement>) => {
        evt.preventDefault();
        onResponse(evt);
    }

    return (
        <select defaultValue="default" className={className} name={name} id={id} onChange={onChangeSelect}>
            <option value="default" disabled>{placeholder}</option>
            {options.map((element: any, index) => {
                return <option key={index} value={index}>{element.label}</option>;
            })}
        </select>
    );
}

export default Select;