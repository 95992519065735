import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesApp from './routes/RoutesApp';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { GlobalContextProvider } from './context/global_context';

// Reincia el scroll al cambiar de ruta...
function ScrollToTop(props: any) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children;
}

function App() {
  return (
    <GlobalContextProvider>
      <div className="App">
        <Router>
          <ScrollToTop />
          <RoutesApp />
        </Router>
      </div>
    </GlobalContextProvider>
  );
}

export default App;
