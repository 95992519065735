import { useState } from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import LoginForm from "./LoginForm";

const Login = () => {
    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    return (
        <>
            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({...popupTP, status: false, documentStr: ""}) }}
                    />
                : null
            }

            <div className="bg-arena-gray">
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-40">
                        <Navbar />
                    </div>
                </div>
                <div className="pt-[5vw] m-auto md:pt-[50px]">
                    <LoginForm />
                </div>
                <div className="pt-10">
                    <Footer
                        statusPop={(opt: any) => {
                            setPopupTP({...popupTP, status: opt.status, documentStr: opt.documentStr })
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default Login;