import TournamentDropModel from "../../models/TournamentDrop";

const TournamentsPopJoin = ({ tournament, onCloseModal } : { tournament: TournamentDropModel, onCloseModal: any }) => {
    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[85vw] md:w-[600px] lg:w-[700px]">
                        <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-[5px] lg:p-[10px] border-b border-solid border-gray-500 rounded-t">
                                <button
                                    className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                    onClick={() => onCloseModal(false)}
                                >
                                    X
                                </button>
                            </div>
                            <div className="relative p-[15px] lg:p-[30px] flex-auto">
                                <div className="space-y-5">
                                    <div className="text-left txt-arena-white font-bold">
                                        <h3 className="text-[20px]">¡PARTICIPA YA!</h3>
                                    </div>
                                    <div>
                                        <img className="w-[400px] m-auto rounded-md" src={tournament.dropImageURL} />
                                    </div>
                                    <div className="txt-arena-white">
                                        <p>
                                            ¡Para participar en el <b>{ tournament.dropName }</b>{' ('}{ tournament.dropGroupMode }{') '} 
                                            acude a nuestras instalaciones <b>{ tournament.dropLocation }</b> e inscribete en el torneo 
                                            para ganar <b>{ tournament.dropReward }</b>!
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default TournamentsPopJoin;