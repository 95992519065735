import Logo from "../../assets/icons/63_arena_logo_navbar.svg";
import IconTwitter from "../../assets/icons/13_twitter_arena.svg";
import IconInstagram from "../../assets/icons/14_instagram_arena.svg";
import IconFacebook from "../../assets/icons/15_facebook_arena.svg";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = ({ statusPop } : { statusPop: any }) => {
    return (
        <div className="footer-arena-style bg-arena-black">
            {/* Desktop */}
            <div className="relative hidden md:flex w-full justify-center">
                <footer className="px-[10px] lg:px-[20px] pb-[12px] pt-[150px] border border-transparent w-[90%] lg:w-[80%]">
                    <div className="flex text-left space-x-[15px] lg:space-x-[40px] py-[10px] m-auto">
                        <div className="w-[25%] text-gray-500 text-[16px] lg:text-[18px]">
                            <h3 className="text-white pb-[10px] lineMenubottom">Menú</h3>
                            <ul className="list-none space-y-[10px] mt-3">
                                <li className="ease-in duration-150 scale-100 hover:scale-105 hover:text-orange-500">
                                    <Link to="/">Home</Link>
                                </li>
                                <li className="ease-in duration-150 scale-100 hover:scale-105 hover:text-orange-500">
                                    <Link to="/booking">Reserva</Link>
                                </li>
                                <li className="ease-in duration-150 scale-100 hover:scale-105 hover:text-orange-500">
                                    <Link to="/tournaments">Campeonatos {'&'} Drops</Link>
                                </li>
                                <li className="ease-in duration-150 scale-100 hover:scale-105 hover:text-orange-500">
                                    <Link to="/birthday">Cumpleaños</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="w-[25%] text-gray-500 text-[16px] lg:text-[18px]">
                            <h3 className="text-white pb-[10px]"><br/></h3>
                            <ul className="list-none space-y-[10px] mt-3">
                                <li className="ease-in duration-150 scale-100 hover:scale-105 hover:text-orange-500">
                                    <Link to="/realeses">Lanzamientos</Link>
                                </li>
                                <li className="ease-in duration-150 scale-100 hover:scale-105 hover:text-orange-500">
                                    <Link to="/news">Noticias</Link>
                                </li>
                                <li className="ease-in duration-150 scale-100 hover:scale-105 hover:text-orange-500">
                                    <Link to="/venues">Sucursales</Link>
                                </li>
                                <li className="ease-in duration-150 scale-100 hover:scale-105 hover:text-orange-500">
                                    <a href="https://www.sixkarma.mx/">SixKarma</a>
                                </li>
                            </ul>
                        </div>
                        <div className="w-[50%] space-y-[10px]">
                            <div className="flex">
                                <div className="w-[50%] text-gray-500 text-[16px] lg:text-[18px]">
                                    <h3 className="text-white pb-[10px] lineMenubottom">Administrador</h3>
                                    <ul className="list-none space-y-[10px] mt-3">
                                        <li className="ease-in duration-150 scale-100 hover:scale-105 hover:text-orange-500">
                                            <Link to="/login">Iniciar sesión</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="w-[50%] text-[16px] lg:text-[18px] pl-[20px]">
                                    <h3 className="text-white pb-[10px] lineMenubottom">Nuestras redes</h3>
                                    <div className="flex justify-start space-x-[10px] mt-3">
                                        <a className="ease-in duration-150 scale-100 hover:scale-110" href="https://facebook.com/ArenaThePlaceToPlay" target="_blank"><img className="w-[50px]" src={IconFacebook} /></a>
                                        <a className="ease-in duration-150 scale-100 hover:scale-110" href="https://instagram.com/arenatheplacetoplay" target="_blank"><img className="w-[50px]" src={IconInstagram} /></a>
                                        <a className="ease-in duration-150 scale-100 hover:scale-110" href="https://twitter.com/arenaplacetplay" target="_blank"><img className="w-[50px]" src={IconTwitter} /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="w-[100%] text-gray-500 text-[16px] lg:text-[18px]">
                                <div className="py-[10px] space-y-[10px]">
                                    <h3 className="text-white lineMenubottom mb-3">Contacto</h3>
                                    <a href="mailto:atencionaclientes@arenatheplacetoplay.com">
                                        <p>atencionaclientes@arenatheplacetoplay.com</p>
                                    </a>
                                    <a href="tel:5552015800">
                                        <p>55 5201-5800</p>
                                    </a>
                                </div>
                                {/* <div className="pt-[10px]">
                                    <h3 className="text-white pb-[10px]">Otros</h3>
                                    <p>Facturación</p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center items-center text-center space-x-[30px] py-[20px]">
                        <div className="ease-in duration-150 scale-100 hover:scale-110">
                            <Link className="flex justify-center items-center" to="/">
                                <img className="w-[250px]" src={Logo} />
                            </Link>
                        </div>
                        <div className="text-gray-500 text-[12px]">
                            <span>Todos los derechos reservados. Arena. The place to Play Movistar. © 2018</span>
                        </div>
                        <div className="text-gray-400 text-[12px]">
                            <span>
                                <button
                                    className="hover:text-orange-500 duration-150"
                                    onClick={() => { statusPop({ status: true, documentStr: "terms_conditions" }) }}
                                >
                                    Términos y condiciones
                                </button>
                                {' | '}
                                <button
                                    className="hover:text-orange-500 duration-150"
                                    onClick={() => { statusPop({ status: true, documentStr: "privacy" }) }}
                                >
                                    Aviso de privacidad
                                </button>
                                {' | '}
                                <button
                                    className="hover:text-orange-500 duration-150"
                                    onClick={() => { statusPop({ status: true, documentStr: "privacy" }) }}
                                >
                                    Aviso de privacidad
                                </button>
                                {' | '}
                                <button
                                    className="hover:text-orange-500 duration-150"
                                    onClick={() => { window.open("https://webportal.edicomgroup.com/customers/cinemex/search.htm"); }}
                                >
                                    Facturación
                                </button>
                                {' | '}
                                <button
                                    className="hover:text-orange-500 duration-150"
                                    onClick={() => { window.open("https://atracciondetalento.grupocinemex.com/login"); }}
                                >
                                    Atracción de Talento
                                </button>
                            </span>
                        </div>
                    </div>
                </footer>
            </div>

            {/* Mobile */}
            <div className="relative flex md:hidden w-full justify-center">
                <footer className="px-[4vw] pb-[4vw] pt-[28vw] border border-transparent">
                    <div className="pl-[2vw] pb-[2vw] text-left text-[4vw]">
                        <div className="row">
                            <div className="grid grid-cols-2">
                                <div className="text-gray-500">
                                    <h3 className="text-white text-[5vw] pb-[2vw]">Menú</h3>
                                    <ul className="list-none space-y-[1vw]">
                                        <li><Link to="/">Home</Link></li>
                                        <li><Link to="/booking">Booking</Link></li>
                                        <li><Link to="/tournaments">Torneos</Link></li>
                                        <li><Link to="/birthday">Cumpleaños</Link></li>
                                        <li><Link to="/realeses">Lanzamientos</Link></li>
                                        <li><Link to="/news">Noticias</Link></li>
                                        <li><Link to="/venues">Sucursales</Link></li>
                                        <li><a href="https://www.sixkarma.mx/">SixKarma</a></li>
                                    </ul>
                                </div>
                                <div className="text-gray-500">
                                    <div className="pb-[2vw]">
                                        <h3 className="text-white text-[5vw] pb-[2vw]">Contacto</h3>
                                        <a href="mailto:atencionaclientes@arenatheplacetoplay.com">
                                            <p>atencionaclientes<br />@arenatheplaceto<br />play.com</p>
                                        </a>
                                        <a href="tel:5552015800">
                                        <p>55 5201-5800</p>
                                    </a>
                                    </div>
                                    {/* <div className="pb-[2vw]">
                                        <h3 className="text-white text-[5vw] pb-[2vw]">Otros</h3>
                                        <p>Facturación</p>
                                    </div> */}
                                    <div>
                                        <h3 className="text-white text-[5vw] pb-[2vw]">Nuestras redes</h3>
                                        <div className="flex justify-start space-x-[2vw]">
                                            <a href="https://facebook.com/ArenaThePlaceToPlay" target="_blank"><img className="w-[10vw]" src={IconFacebook} /></a>
                                            <a href="https://instagram.com/arenatheplacetoplay" target="_blank"><img className="w-[10vw]" src={IconInstagram} /></a>
                                            <a href="https://twitter.com/arenaplacetplay" target="_blank"><img className="w-[10vw]" src={IconTwitter} /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="text-center space-y-[1vw]">
                        <div className="row p-[2vw]">
                            <Link className="flex justify-center items-center" to="/">
                                <img className="w-[300px]" src={Logo} />
                            </Link>
                        </div>
                        <div className="row text-gray-500 text-[3.5vw]">
                            <span>Todos los derechos reservados.</span>
                        </div>
                        <div className="row text-gray-500 text-[3.5vw]">
                            <span>Arena. The place to Play Movistar. © 2018</span>
                        </div>
                        <div className="row p-2 text-gray-400 text-[3.5vw]">
                            <span>
                                <button onClick={() => { statusPop({ status: true, documentStr: "terms_conditions" }) }}>
                                    Términos y condiciones
                                </button>
                                {' | '}
                                <button onClick={() => { statusPop({ status: true, documentStr: "privacy" }) }}>
                                    Aviso de privacidad
                                </button>
                            </span>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}

export default Footer;