import { BsCalendarWeek, BsFillCalendarDateFill } from "react-icons/bs";
import { FaGamepad, FaNewspaper, FaTrophy, FaUserAlt, FaVideo } from "react-icons/fa";
import { MdAddAlert, MdPlace } from "react-icons/md";
import { Link } from "react-router-dom";

const SidebarAdmin = () => {
    return (
        <>
            {/* Desktop */}
            <div className="block">
                <div className="bg-arena-grayo w-full h-screen">
                    <div className="py-[55px]">
                        <Link to="/admin/popup">
                            <h3 className="hidden md:block py-[18px] pl-[30px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                Avisos
                            </h3>
                            <h3 className="md:hidden py-[18px] pl-[20px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                <MdAddAlert />
                            </h3>
                        </Link>
                        <Link to="/admin/events">
                            <h3 className="hidden md:block py-[18px] pl-[30px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                Eventos
                            </h3>
                            <h3 className="md:hidden py-[18px] pl-[20px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                <BsFillCalendarDateFill />
                            </h3>
                        </Link>
                        <Link to="/admin/realeses">
                            <h3 className="hidden md:block py-[18px] pl-[30px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                Lanzamientos
                            </h3>
                            <h3 className="md:hidden py-[18px] pl-[20px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                <FaGamepad />
                            </h3>
                        </Link>
                        <Link to="/admin/news">
                            <h3 className="hidden md:block py-[18px] pl-[30px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                Noticias
                            </h3>
                            <h3 className="md:hidden py-[18px] pl-[20px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                <FaNewspaper />
                            </h3>
                        </Link>
                        <Link to="/admin/venues">
                            <h3 className="hidden md:block py-[18px] pl-[30px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                Sucursales
                            </h3>
                            <h3 className="md:hidden py-[18px] pl-[20px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                <MdPlace />
                            </h3>
                        </Link>
                        <Link to="/admin/tournaments">
                            <h3 className="hidden md:block py-[18px] pl-[30px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                Torneos y Promociones
                            </h3>
                            <h3 className="md:hidden py-[18px] pl-[20px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                <FaTrophy />
                            </h3>
                        </Link>
                        <Link to="/admin/users">
                            <h3 className="hidden md:block py-[18px] pl-[30px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                Usuarios
                            </h3>
                            <h3 className="md:hidden py-[18px] pl-[20px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                <FaUserAlt />
                            </h3>
                        </Link>
                        {/* <Link to="/admin/videos">
                            <h3 className="hidden md:block py-[18px] pl-[30px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                Videos
                            </h3>
                            <h3 className="md:hidden py-[18px] pl-[20px] text-zinc-400 text-left text-[18px] font-bold border-y border-zinc-600">
                                <FaVideo />
                            </h3>
                        </Link> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SidebarAdmin;