import { useEffect, useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from "react-icons/md";
import { Link } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import VenuesModel from "../../models/Venues";
import VenuesCard from "./VenuesCard";
import VenuesFilter from "./VenuesFilter";

interface PageInfo {
    page: number,
    elements: number,
    firstIndex: number,
    lastIndex: number
}

const VenuesBody = ({ stateOptions, venues }: { stateOptions: Array<any>, venues: Array<VenuesModel> }) => {
    const [arrVenues, setArrVenues] = useState<Array<VenuesModel>>([]);
    const [arrVenuesUniv, setArrVenuesUniv] = useState<Array<VenuesModel>>([]);
    const [newVenue, setNewVenue] = useState<VenuesModel>({
        companyID: "",
        venueID: "",
        venueName: "",
        venueAddress: {
            street: "",
            number: "",
            interiorNumber: "",
            neighborhood: "",
            city: "",
            state: "",
            country: "",
            zipCode: ""
        },
        venueContactMail: "",
        venueWorkingHours: "",
        venueContactPhone: "",
        venueMapURL: "",
        venueImagesURL: [],
        venueVideosURL: []
    });

    const [pagination, setPagination] = useState<PageInfo>({
        page: 1,
        elements: 6,
        firstIndex: 0,
        lastIndex: 5
    });

    const onChangePage = (place: string) => {
        if (place === "right" && (arrVenues.length > (pagination.page * pagination.elements))) {
            
            setPagination({
                ...pagination,
                page: pagination.page + 1,
                elements: pagination.elements,
                firstIndex: pagination.firstIndex + pagination.elements,
                lastIndex: pagination.lastIndex + pagination.elements
            });
        } else if (place === "left" && pagination.page > 1) {
            setPagination({
                ...pagination,
                page: pagination.page - 1,
                elements: pagination.elements,
                firstIndex: pagination.firstIndex - pagination.elements,
                lastIndex: pagination.lastIndex - pagination.elements
            });
        }
    }

    const onFilter = (opt: any, type: string) => {
        let newVenues: Array<VenuesModel> = [];
        if (type === "select" && opt.value !== "clean") {
            newVenues = arrVenuesUniv.filter((relElement: VenuesModel) => (relElement.venueAddress.state === opt.value));
            setArrVenues(newVenues);
            setNewVenue(newVenues[0]);
            setPagination({ ...pagination, elements: 6, firstIndex: 0, lastIndex: 5 });
        } else {
            setArrVenues(arrVenuesUniv);
            setNewVenue(arrVenuesUniv[0]);
            setPagination({ ...pagination, elements: 6, firstIndex: 0, lastIndex: 5 });
        }
    }

    useEffect(() => {
        if (venues && venues.length > 0) {
            // if()
            const venuesIsActive = venues.filter((item: any) => {
                // return item.venueIsActive ? item : null
                if (item.venueIsActive) {
                    return item
                }
            })
            // LOGICA ANTERIOR
            // setNewVenue(venues[0]);
            // setArrVenues(venues);
            // setArrVenuesUniv(venues);

            setNewVenue(venuesIsActive[0]);
            setArrVenues(venuesIsActive);
            setArrVenuesUniv(venuesIsActive);
        }
    }, [venues]);

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block w-[500px] lg:w-[760px] m-auto">
                <div className="mt-[50px]">
                    <div className="">
                        <VenuesFilter arrStates={stateOptions} onResponse={onFilter} />
                    </div>
                    <div className="text-left txt-arena-white font-bold">
                        <Fade>
                            <h3 className="text-[25px]">Descubre el nuevo {newVenue.venueName}</h3>
                        </Fade>
                    </div>
                    <div className="relative py-[25px]">
                        <Fade>
                            <img className="relative w-[500px] h-[280px] lg:w-[760px] lg:h-[385px] img-boxs" src={newVenue.venueImagesURL ? newVenue.venueImagesURL[0] : ""} />
                        </Fade>
                        <Link to="/venues/detail" state={newVenue}>
                            <div className="absolute flex justify-between glassmorphism w-[455px] lg:w-[320px] bottom-[40px] left-[20px] py-[14px] px-[25px] z-30 scale-100 hover:scale-105 ease-in duration-150">
                                <div className="text-left space-y-[12px] lg:space-y-[18px]">
                                    <div className="text-[20px] txt-arena-white font-bold">
                                        <h3>{newVenue.venueName}</h3>
                                    </div>
                                    <div className="flex items-center space-x-[10px] txt-arena-white font-bold">
                                        <span className="text-[16px]"><GoLocation /></span>
                                        <p className="text-[14px]">{newVenue.venueAddress.city}{', '}{newVenue.venueAddress.country}</p>
                                    </div>
                                    <div className="text-[16px] text-gray-400">
                                        <span>
                                            {newVenue.venueAddress.street}{' '}
                                            {newVenue.venueAddress.number}{', '}
                                            {newVenue.venueAddress.neighborhood}
                                        </span>
                                    </div>
                                </div>
                                <div className="txt-arena-white">
                                    <span className="text-[30px]"><AiOutlineArrowRight /></span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="relative">
                    <button
                        className="absolute bg-transparent md:top-[920px] lg:top-[300px] left-[-50px] txt-arena-white z-40"
                        type="button"
                        onClick={() => onChangePage("left")}
                    >
                        <span className="text-[50px]"><MdOutlineArrowBackIosNew /></span>
                    </button>
                    <button
                        className="absolute bg-transparent md:top-[920px] lg:top-[300px] right-[-50px] txt-arena-white z-40"
                        type="button"
                        onClick={() => onChangePage("right")}
                    >
                        <span className="text-[50px]"><MdOutlineArrowForwardIos /></span>
                    </button>
                </div>
                <div className="space-y-[25px] py-[50px] lg:grid lg:grid-cols-2 lg:grid-rows-3 lg:gap-6 lg:space-y-0 lg:py-0 m-auto">
                    {
                        arrVenues.map((venueElement: any, i: number) => {
                            if (venueElement.venueIsActive === true) {
                                if ((i >= pagination.firstIndex && i <= pagination.lastIndex)) {
                                    if (i !== 0) {
                                        return (
                                            <div key={i}>
                                                <Fade>
                                                    <VenuesCard venue={venueElement} />
                                                </Fade>
                                            </div>
                                        );
                                    }
                                }
                            } else {
                                return
                            }
                        })
                    }
                </div>
                {/* <div className="w-full lg:grid lg:grid-cols-2 lg:gap-6 space-y-[25px] lg:space-y-0 py-[50px] lg:py-0 m-auto">
                    {
                        arrVenues.map((venueElement: VenuesModel, i:number) => {
                            return (
                                <div key={i}>
                                    <VenuesCard venue={venueElement} />
                                </div>
                            )
                        })
                    }
                </div> */}
            </div>

            {/* Mobile */}
            <div className="relative md:hidden">
                <div className="mt-[50vw]">
                    <div className="pt-10">
                        <VenuesFilter arrStates={stateOptions} onResponse={onFilter} />
                    </div>
                    <div className="p-[7vw] text-left txt-arena-white font-bold">
                        <Fade>
                            <h3 className="text-[5vw]">Descubre el nuevo {newVenue.venueName}</h3>
                        </Fade>
                    </div>
                    <div className="relative py-[2vw]">
                        <Fade>
                            <img className="relative w-[95vw] h-[65vw] img-boxs" src={newVenue.venueImagesURL ? newVenue.venueImagesURL[0] : ""} />
                        </Fade>
                        <Link to="/venues/detail" state={newVenue}>
                            <div className="absolute flex glassmorphism w-[90vw] bottom-[5vw] left-[2vw] py-[3vw] px-[4vw] z-30">
                                <div className="text-left space-y-[3vw]">
                                    <div className="text-[5vw] txt-arena-white font-bold">
                                        <h3>{newVenue.venueName}</h3>
                                    </div>
                                    <div className="flex items-center space-x-[2vw] txt-arena-white font-bold">
                                        <span className="text-[4.5vw]"><GoLocation /></span>
                                        <p className="text-[3.5vw]">{newVenue.venueAddress.city}{', '}{newVenue.venueAddress.country}</p>
                                    </div>
                                    <div className="text-[4.5vw] text-gray-400">
                                        <span>
                                            {newVenue.venueAddress.street}{' '}
                                            {newVenue.venueAddress.number}{', '}
                                            {newVenue.venueAddress.neighborhood}
                                        </span>
                                    </div>
                                </div>
                                <div className="txt-arena-white">
                                    <span className="text-[7vw]"><AiOutlineArrowRight /></span>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="p-[5vw]">
                    <div className="pb-[5vw] text-left txt-arena-white font-bold">
                        <h3 className="text-[5vw]">Conoce nuestras Sucursales</h3>
                    </div>
                    <div className="w-[90vw] m-auto">
                        {
                            arrVenues.map((venueElement: VenuesModel, i: number) => {
                                if ((i >= pagination.firstIndex && i <= pagination.lastIndex)) {
                                    return (
                                        <div className="mb-[4vw] md:mb-0" key={i}>
                                            <Fade>
                                                <VenuesCard venue={venueElement} />
                                            </Fade>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default VenuesBody;