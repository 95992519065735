import NewsModel from "../../../models/News";

const NewsCard = ({ newElement, onResponse } : { newElement: NewsModel, onResponse: any }) => {
    const onResponseNewCard = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        onResponse(newElement);
    }

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <button type="button" onClick={onResponseNewCard} className="w-[280px] space-y-[10px]">
                    <div className="py-[15px]">
                        <img className="w-[280px] h-[180px] img-boxs" src={newElement && newElement.noteImageURL ? newElement.noteImageURL[0] : ""} />
                    </div>
                    <div className="space-y-[12px] text-left">
                        <div className="txt-arena-orange font-bold">
                            <p className="text-[20px]">{newElement.noteEpisode}</p>
                        </div>
                        <div className="font-bold">
                            <h3 className="text-[20px]">{newElement.noteTitle}</h3>
                        </div>
                    </div>
                </button>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <button type="button" onClick={onResponseNewCard} className="w-[50vw]">
                    <div className="py-[5vw]">
                        <img className="w-[50vw] h-[35vw] img-boxs rounded-md" src={newElement && newElement.noteImageURL ? newElement.noteImageURL[0] : ""} />
                    </div>
                    <div className="space-y-[2vw] text-left">
                        <div className="txt-arena-orange font-bold">
                            <p className="text-[3.5vw]">{newElement.noteEpisode}</p>
                        </div>
                        <div className="font-bold">
                            <h3 className="text-[4vw]">{newElement.noteTitle}</h3>
                        </div>
                    </div>
                </button>
            </div>
        </>
    );
}

export default NewsCard;