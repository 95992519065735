import { functions, httpsCallable } from "../firebase";
import { IDCompany } from "../global/global";
import PopupModel from "../models/Popup";

export const getPopups = () => {
    const getAllPopups = httpsCallable(functions, "readAllPopup");
    const res = getAllPopups({ companyID: `${IDCompany}` });
    return res;
}

export const getPopupById = (data: Object) => {
    const getAPopupById = httpsCallable(functions, "readPopupById");
    const res = getAPopupById(data);
    return res;
}

export const addPopup = (data: PopupModel) => {
    const savePopup = httpsCallable(functions, "createPopup");
    const res = savePopup(data);
    return res;
}

export const updatePopup = (data: Object) => {
    const editPopup = httpsCallable(functions, "updatePopup");
    const res = editPopup(data);
    return res;
}

export const deletePopup = (data: Object) => {
    const removePopup = httpsCallable(functions, "deletePopup");
    const res = removePopup(data);
    return res;
}