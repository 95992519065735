import { useEffect, useState } from "react";
import { getVenues } from "../../services/Venues";
import { getEvents } from "../../services/Events";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { Fade } from "react-awesome-reveal";
import Navbar from "../../components/navbar/Navbar";
import BookingForm from "./BookingForm";
import Footer from "../../components/footer/Footer";
import IconBg from "../../assets/icons/06_hexagon.svg";
import EventsModel from "../../models/Events";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import Loading from "../../components/loading/Loading";
import PopNotify from "../../components/Pop/PopNotify";
import PopWarning from "../../components/Pop/PopWarning";
import PopSuccess from "../../components/Pop/PopSuccess";
import Rellax from "rellax";
import "./Booking.css";

interface PopInfo {
    status: boolean,
    type: string
}


const Booking = () => {
    const [venuesOpt, setVenuesOpt] = useState<Array<Object>>([]);
    const [events, setEvents] = useState<Array<EventsModel>>([]);

    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [popWarningStatus, setPopWarningStatus] = useState<PopInfo>({ status: false, type: "" });
    const [popSuccessStatus, setPopSuccessStatus] = useState<PopInfo>({ status: false, type: "" });
    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    const friendsOptions = [
        { label: "Solo", value: 0 },
        { label: "01", value: 1 },
        { label: "02", value: 2 },
        { label: "03", value: 3 },
        { label: "04", value: 4 },
        { label: "05", value: 5 },
        { label: "06", value: 6 },
        { label: "07", value: 7 },
    ];

    const durationOptions = [
        { label: "1 hora", value: "1 hora" },
        { label: "1 hora y media", value: "1 hora y media" },
        { label: "3 horas", value: "3 horas" },
        { label: "Todo el día", value: "Todo el día" }
    ];

    useEffect(() => {
        new Rellax(".animatev0", { speed: 3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev1", { speed: -1, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev2", { speed: -2, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev3", { speed: -3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev4", { speed: -4, center: false, round: true, vertical: true, horizontal: false });

        new Promise<void>((resolve, reject) => {
            getVenues().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        if (element.venueIsActive) {
                            newArr.push({
                                label: element['venueName'],
                                value: element.venueID,
                                data: element
                            });
                        }
                    }
                    setVenuesOpt(newArr);
                }
            });

            // Get all events
            getEvents().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setEvents(newArr);
                }
            });

            setTimeout(function () {
                resolve();
            }, 1000);
        });
    }, []);

    return (
        <>
            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({ ...popupTP, status: false, documentStr: "" }) }}
                    />
                    : null
            }

            {loadingStatus ? <Loading /> : null}

            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({ ...popStatus, status: false, type: "" }) }} />
                    : null
            }

            {
                popWarningStatus.status ?
                    <PopWarning body={popWarningStatus.type} onClose={() => { setPopWarningStatus({ ...popWarningStatus, status: false, type: "" }) }} />
                    : null
            }

            {
                popSuccessStatus.status ?
                    <PopSuccess body={popSuccessStatus.type} onClose={() => { setPopSuccessStatus({ ...popSuccessStatus, status: false, type: "" }) }} />
                    : null
            }

            <div className="bg-arena-gray">
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-40">
                        <Navbar />
                    </div>
                </div>
                <div className="relative md:w-[90%] md:w-[650px] lg:w-[900px] pt-[100px] px-5 m-auto">
                    <img className="animatev1 absolute w-[250px] top-[0vw] left-[-30vw] md:top-[120px] md:left-[-150px] lg:top-[150px] lg:left-[-200px] z-0" src={IconBg} />
                    <img className="animatev2 absolute w-[250px] top-[-45vw] right-[-30vw] md:top-[320px] md:right-[-100px] lg:top-[350px] lg:right-[-120px] z-0" src={IconBg} />
                    <img className="animatev3 absolute w-[250px] top-[400vw] left-[-30vw] md:top-[900px] md:left-[-150px] lg:top-[900px] lg:left-[-200px] z-0" src={IconBg} />
                    <img className="animatev2 absolute w-[250px] top-[-45vw] right-[-30vw] md:top-[1200px] md:right-[-100px] lg:top-[1200px] lg:right-[-120px] z-0" src={IconBg} />
                    <img className="animatev1 md:hidden absolute w-[250px] top-[220vw] right-[-30vw] z-0" src={IconBg} />
                    <img className="animatev1 md:hidden absolute w-[250px] top-[500vw] right-[-40vw] z-0" src={IconBg} />

                    <BookingForm
                        duration={durationOptions}
                        friends={friendsOptions}
                        venues={venuesOpt}
                        events={events}
                        statusPop={(opt: any) => {
                            setPopupTP({ ...popupTP, status: opt.status, documentStr: opt.documentStr })
                        }}
                        onSuccess={(sts: any, msg: any) => {
                            setPopSuccessStatus({ ...popSuccessStatus, status: sts, type: msg });
                        }}
                        onWarning={(sts: any, msg: any) => {
                            setPopWarningStatus({ ...popWarningStatus, status: sts, type: msg });
                        }}
                        onStatus={(opt: any) => { 
                            console.log(opt)
                            setLoadingStatus(opt)
                         }}
                    />
                </div>
                <div className="relative pt-10 md:pt-20">
                    <img className="hidden md:block absolute w-[250px] top-[-50px] left-[50px] lg:top-[-50px] lg:left-[170px] z-0 md:z-10" src={IconBg} />
                    <Fade>
                        <Footer
                            statusPop={(opt: any) => {
                                setPopupTP({ ...popupTP, status: opt.status, documentStr: opt.documentStr })
                            }}
                        />
                    </Fade>
                </div>
            </div>
        </>
    );
}

export default Booking;