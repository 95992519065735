import { useEffect, useState } from "react";
import { AiFillStar } from "react-icons/ai";
import RealeseModel from "../../models/Realese";
import IconMenu4 from "../../assets/icons/04_arena_icon_lanzamientos.svg";

const arrConsoleBg: any = {
    "Multiplataforma": "bg-arena-mp",
    "Nintendo Switch": "bg-arena-ndo",
    "Windows PC": "bg-arena-pc",
    "Steam": "bg-arena-pcstm",
    "Playstation 4/Pro": "bg-arena-ps4",
    "Playstation 5": "bg-arena-ps5",
    "Xbox One": "bg-arena-xo",
    "Xbox Series X/S": "bg-arena-xos",
    "Android": "bg-arena-aod",
    "iOS": "bg-arena-ios",
};

const RealesesPop = ({ realese, closeModal } : { realese: RealeseModel, closeModal: any }) => {
    const [realeseData, setRealeseData] = useState<RealeseModel>({
        companyID: "",
        gameID: "",
        gameTitle: "",
        gameDeveloper: "",
        gameBody: "",
        gameGenre: [],
        gameESRB: "",
        gamePlatform: "",
        gameReleaseDate: "",
        gameScore: 0,
        gameCreatedBy: "",
        gameImageURL: "",
        dropVideoURL: ""
    });

    const [rating, setRating] = useState<Array<string>>([
        "text-gray-200",
        "text-gray-200",
        "text-gray-200",
        "text-gray-200",
        "text-gray-200"
    ]);

    useEffect(() => {
        const newRating = rating.map((ratingElement: string, i: number) => {
            if ((i+1) <= realese.gameScore) {
                return "text-yellow-500";
            } else {
                return ratingElement;
            }
        });

        setRating(newRating);
        setRealeseData(realese);
    }, [realese]);

    return(
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="w-[650px] lg:w-[1000px] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none m-auto">
                    <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-[5px] border-b border-solid border-gray-500 rounded-t">
                            <div className="flex items-center space-x-2 pl-4 txt-arena-white font-bold">
                                <img src={IconMenu4} />
                                <span>Lanzamientos - {realeseData.gameTitle}</span>
                            </div>
                            <button
                                className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                onClick={() => closeModal(false)}
                            >
                                X
                            </button>
                        </div>
                        <div className="relative p-[20px] lg:p-[50px] flex-auto">
                            <div className="md:flex md:items-center md:space-x-[40px]">
                                <div>
                                    <div className="w-full md:w-[165px] lg:w-[300px] m-auto">
                                        <img className="w-full md:w-[165px] lg:w-[300px] rounded-md img-boxs" src={realeseData.gameImageURL} />
                                        <div className="relative txt-arena-white text-[14px]">
                                            <span className={"absolute bottom-[12px] left-[12px] px-[15px] py-[6px] rounded-sm " + arrConsoleBg[`${realeseData.gamePlatform}`]} >{realeseData.gamePlatform}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-left txt-arena-white font-bold py-[12px]">
                                    <div>
                                        <h3 className="text-[23px]">{realeseData.gameTitle}</h3>
                                    </div>
                                    <div className="flex text-[18px] space-x-[5px]">
                                        <span className={rating[0]}><AiFillStar /></span>
                                        <span className={rating[1]}><AiFillStar /></span>
                                        <span className={rating[2]}><AiFillStar /></span>
                                        <span className={rating[3]}><AiFillStar /></span>
                                        <span className={rating[4]}><AiFillStar /></span>
                                    </div>
                                    <div className="space-y-[10px] mt-[25px]">
                                        <div className="flex space-x-[25px] txt-arena-orange text-[14px]">
                                            <span>Desarrollador: </span>
                                            <span>{realeseData.gameDeveloper}</span>
                                        </div>
                                        <div className="flex space-x-[25px] txt-arena-orange text-[14px]">
                                            <span>ESRB: </span>
                                            <span>{realeseData.gameESRB}</span>
                                        </div>
                                        <div className="txt-arena-orange text-[14px]">
                                            {
                                                realeseData.gameGenre.map((elem: string, i: number) => {
                                                    return (
                                                        <span key={i}>{elem + ' | '}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="text-[14px]">
                                            <p>{realeseData.gameBody}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none w-[85vw] m-auto">
                    <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-[5px] border-b border-solid border-gray-500 rounded-t">
                            <div className="flex items-center space-x-2 pl-4 txt-arena-white font-bold">
                                <img src={IconMenu4} />
                                <span>Lanzamientos</span>
                            </div>
                            <button
                                className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                onClick={() => closeModal(false)}
                            >
                                X
                            </button>
                        </div>
                        <div className="relative p-[10px] flex-auto">
                            <div className="py-[2vw]">
                                <div className="m-auto">
                                    <img className="w-[55vw] h-[80vw] rounded-md m-auto img-boxs" src={realeseData.gameImageURL} />
                                    <div className="relative txt-arena-white text-[3vw]">
                                        <span className={"absolute bottom-[3vw] left-[15vw] px-[3vw] py-[1vw] rounded-md " + arrConsoleBg[`${realeseData.gamePlatform}`]} >{realeseData.gamePlatform}</span>
                                    </div>
                                </div>
                                <div className="text-left txt-arena-white font-bold px-[7vw] py-[7vw]">
                                    <div>
                                        <h3 className="text-[4vw]">{realeseData.gameTitle}</h3>
                                    </div>
                                    <div className="flex text-[3.5vw] space-x-[0.3vw]">
                                        <span className={rating[0]}><AiFillStar /></span>
                                        <span className={rating[1]}><AiFillStar /></span>
                                        <span className={rating[2]}><AiFillStar /></span>
                                        <span className={rating[3]}><AiFillStar /></span>
                                        <span className={rating[4]}><AiFillStar /></span>
                                    </div>
                                    <div className="space-y-[2vw] mt-[2vw]">
                                        <div className="flex space-x-[2vw] txt-arena-orange text-[3.2vw] font-bold">
                                            <span>Desarrollador: </span>
                                            <span>{realeseData.gameDeveloper}</span>
                                        </div>
                                        <div className="txt-arena-orange text-[3.2vw]">
                                            {
                                                realeseData.gameGenre.map((elem: string, i: number) => {
                                                    return (
                                                        <span key={i}>{elem + ' | '}</span>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="text-[3.2vw]">
                                            <p>{realeseData.gameBody}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black" onClick={() => closeModal(false)}></div>
        </>
    );
}

export default RealesesPop;