import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import ImageDesktop from "../../assets/images/page404/error_404_img_des.png";
import ImageMobile from "../../assets/images/page404/error_404_img_mob.png";
import IconBg from "../../assets/icons/06_hexagon.svg";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import { Link } from "react-router-dom";
import { useState } from "react";

const Page404 = () => {
    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    return (
        <>
            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({...popupTP, status: false, documentStr: ""}) }}
                    />
                : null
            }

            <div className="bg-arena-gray">
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-40">
                        <Navbar />
                    </div>
                </div>
                <div className="relative flex justify-center items-center h-auto md:h-[100vh] my-[100px] w-[100%] m-auto">
                    <div className="hidden md:block">
                        <div className="absolute mt-[120px] left-0 right-0 space-y-10 z-10">
                            <div className="txt-arena-white">
                                <p className="text-[35px] font-bold">OOOPS!</p>
                                <p className="text-[20px] font-bold">Parece que algo ha salido mal!</p>
                            </div>
                            <div className="w-[300px] m-auto">
                                <Link to="/">
                                    <button className="btn-arena-primary opacity-100" type="button">
                                        <span className="font-bold tracking-[2px]">REGRESAR AL INICIO</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <img className="relative m-auto" src={ImageDesktop} />
                    </div>
                    <div className="md:hidden">
                        <img className="m-auto" src={ImageMobile} />
                        <div className="mt-2 m-auto space-y-10 z-10">
                            <div className="txt-arena-white">
                                <p className="text-[35px] font-bold">OOOPS!</p>
                                <p className="text-[20px] font-bold">Parece que algo ha salido mal!</p>
                            </div>
                            <div className="w-[300px] m-auto">
                                <Link to="/">
                                    <button className="btn-arena-primary opacity-100" type="button">
                                        <span className="font-bold tracking-[2px]">REGRESAR AL INICIO</span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative pt-10">
                    {/* Image background */}
                    <img className="hidden md:block absolute w-[250px] top-[-100px] left-[-40px] lg:top-[-100px] lg:left-[100px] z-10" src={IconBg} />
                    <Footer
                        statusPop={(opt: any) => {
                            setPopupTP({...popupTP, status: opt.status, documentStr: opt.documentStr })
                        }}
                    />
                </div>
            </div>
        </>
    );
}

export default Page404;