import { useEffect, useState } from "react";

interface TabData {
    status: boolean;
    index: number;
    urlImage: string;
}

const PopupCarrousel = ({ images }: { images: Array<string> }) => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [tab, setTab] = useState<Array<TabData>>([]);
    const [stateArrow, setStateArrow] = useState(true)

    const onClickTab = (evt: React.MouseEvent<HTMLButtonElement>) => {
        evt.preventDefault();
        const newTab = tab.map((element: TabData, i: number) => {
            let tabModel: TabData = { status: element.status, index: element.index, urlImage: element.urlImage };
            if (evt.currentTarget.name === "next") {
                if ((currentIndex + 1 === tab.length) && (i === 0)) {
                    tabModel.status = true;
                    setCurrentIndex(i);
                } else if ((currentIndex + 1 < tab.length) && (currentIndex + 1 === i)) {
                    tabModel.status = true;
                    setCurrentIndex(i);
                } else {
                    tabModel.status = false;
                }
            } else if (evt.currentTarget.name === "back") {
                if ((currentIndex === 0) && (i === tab.length - 1)) {
                    tabModel.status = true;
                    setCurrentIndex(i);
                } else if ((currentIndex > 0) && (currentIndex - 1 === i)) {
                    tabModel.status = true;
                    setCurrentIndex(i);
                } else {
                    tabModel.status = false;
                }
            }
            return tabModel;
        });
        setTab(newTab);
    }

    useEffect(() => {
        let newImages: Array<TabData> = [];
        for (let i = 0; i < images.length; i++) {
            const newElement = images[i];
            if (newElement !== "") {
                let statusTab: boolean = false;
                if (i === 0) statusTab = true;

                newImages.push({ status: statusTab, index: i, urlImage: newElement });
            }
        }
        if (newImages.length === 1) {
            setStateArrow(false)
        }
        // console.log(newImages,"for")
        setTab(newImages);
    }, [images])

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div>
                    {
                        tab.map((newElement: TabData, i: number) => {
                            if (newElement.status) {
                                return (
                                    <div key={i}>
                                        <div className="w-auto h-[20vw] md:h-[550px] lg:h-[600px] overflow-hidden rounded-md m-auto">
                                            <div className="absolute w-full pr-[20px] h-[20vw] md:h-[550px] lg:h-[600px] flex justify-between items-center z-50 popUpContainer">
                                                {stateArrow ?
                                                    <>
                                                        <button
                                                            className="w-[100px] h-full bg-arena-black txt-arena-white opacity-50 hover:opacity-75"
                                                            type="button"
                                                            name="back"
                                                            onClick={onClickTab}
                                                        >
                                                            {'<'}
                                                        </button>
                                                        <button
                                                            className="w-[100px] h-full bg-arena-black txt-arena-white opacity-50 hover:opacity-75"
                                                            type="button"
                                                            name="next"
                                                            onClick={onClickTab}
                                                        >
                                                            {'>'}
                                                        </button>
                                                    </>
                                                    :
                                                    <>
                                                        <button
                                                            className="w-[100px] h-full bg-arena-black txt-arena-white opacity-50"
                                                            type="button"
                                                            name="back"
                                                            // onClick={onClickTab}
                                                        >
                                                            {' '}
                                                        </button>
                                                        <button
                                                            className="w-[100px] h-full bg-arena-black txt-arena-white opacity-50"
                                                            type="button"
                                                            name="next"
                                                            // onClick={onClickTab}
                                                        >
                                                            {' '}
                                                        </button>
                                                    </>
                                                }
                                            </div>
                                            <img
                                                className="relative w-auto h-[20vw] md:h-[550px] lg:h-[600px] m-auto popUpContainer"
                                                src={newElement.urlImage}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                        })
                    }
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <div className="body-carrousel-popup">
                    <div className="slider-carrousel-popup">
                        <div className="slides-carrousel-popup">
                            {
                                images.map((newElement: string, i: number) => {
                                    return (
                                        <div key={i}>
                                            <img
                                                className="w-[60vw] h-auto z-30 m-auto"
                                                src={newElement && newElement !== "" ? newElement : ""}
                                            />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PopupCarrousel;