import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import BirthdayForm from "./BirthdayForm";
import IconBirthday from "../../assets/images/birthday/04_icon_eventos.png";
import ImageOption1 from "../../assets/images/birthday/46_img_cumple_1.png";
import ImageOption2 from "../../assets/images/birthday/47_img_cumple_2.png";
import ImageOption3 from "../../assets/images/birthday/48_img_cumple_3.png";
import ImageOption4 from "../../assets/images/birthday/49_img_cumple_4.png";
import IconOption1 from "../../assets/icons/birthday/40_icon_salas_platino.svg";
import IconOption2 from "../../assets/icons/birthday/41_icon_estaciones_convencionales.svg";
import IconOption3 from "../../assets/icons/birthday/42_icon_equipos_alta_gama.svg";
import IconOption4 from "../../assets/icons/birthday/43_icon_pc_ultima.svg";
import IconOption5 from "../../assets/icons/birthday/44_icon_cientos_titulos.svg";
import IconOption6 from "../../assets/icons/birthday/45_icon_comida.svg";

const BirthdayCard = ({
    duration,
    venues,
    events,
    statusPop,
    onModalCarrousel,
    onWarning,
    onSuccess,
    onStatus,
    onClosePop,
    onLoading
}: {
    duration: Array<Object>,
    venues: Array<Object>,
    events: Array<Object>,
    statusPop: any,
    onModalCarrousel: any,
    onWarning: any,
    onSuccess: any,
    onStatus: any,
    onClosePop: any,
    onLoading: any
}) => {
    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block w-full">
                <div className="flex justify-center mb-[100px] md:space-x-[50px] lg:space-x-[100px]">
                    <div className="w-[550px] lg:w-[500px] space-y-[35px]">
                        <div className="glassmorphism pt-[90px] pb-[30px] px-[20px] lg:pb-[60px] lg:px-[40px] text-left txt-arena-white rounded">
                            <Fade cascade damping={0.1}>
                                <div className="absolute top-[-40px]">
                                    <span className="input-group-addon">
                                        <img className="w-[90px]" src={IconBirthday} />
                                    </span>
                                </div>
                                <h3 className="text-[20px] font-bold md:pr-0 lg:pr-[130px]">
                                    Celebra tu Cumpleaños en Arena The Place to Play Movistar
                                </h3>
                                <p className="py-[18px] text-[16px]">
                                    Sé parte de esta gran experiencia, organiza tu evento en cualquiera
                                    de nuestras sucursales y forma parte del Gaming Center #1 en México.

                                </p>
                                <p className="text-[16px]">
                                    Para tu evento en Arena The Place to Play Movistar te ofrecemos la experiencia de vivir el mundo gamer como
                                    nunca antes lo has vivido con paquetes de acuerdo a tus necesidades.
                                </p>
                            </Fade>
                        </div>
                        <div className="glassmorphism py-[40px]">
                            <h3 className="text-left txt-arena-white text-[20px] font-bold px-[40px] pb-[40px]">Contáctanos</h3>
                            <BirthdayForm
                                duration={duration}
                                venues={venues}
                                events={events}
                                onWarning={onWarning}
                                onSuccess={onSuccess}
                                onStatus={onStatus}
                                onClosePop={onClosePop}
                                statusPop={statusPop}
                                onLoading={onLoading}
                            />
                        </div>
                    </div>
                    <div className="w-[300px] lg:w-[450px] space-y-[15px] txt-arena-white text-left text-[16px] lg:text-[20px] font-bold">
                        <div
                            // type="button"
                            className="glassmorphism flex w-full items-center space-x-[15px] py-[15px] px-[10px] lg:space-x-[30px] lg:px-[20px] text-left scale-100 hover:scale-105 ease-in duration-150"
                            onClick={() => onModalCarrousel(true, 0)}
                        >
                            <Fade>
                                <span><img className="w-[55px]" src={IconOption1} /></span>
                                <p>Salas Platino, VR y Retro</p>
                            </Fade>
                        </div>
                        <div
                            // type="button"
                            className="glassmorphism flex w-full items-center space-x-[15px] py-[15px] px-[10px] lg:space-x-[30px] lg:px-[20px] text-left scale-100 hover:scale-105 ease-in duration-150"
                            onClick={() => onModalCarrousel(true, 0)}
                        >
                            <Fade>
                                <span><img className="w-[55px]" src={IconOption2} /></span>
                                <p>Estaciones de Juego</p>
                            </Fade>
                        </div>
                        {/* <button
                            type="button"
                            className="glassmorphism flex w-full items-center space-x-[15px] py-[15px] px-[10px] lg:space-x-[30px] lg:px-[20px] text-left scale-100 hover:scale-105 ease-in duration-150"
                            onClick={() => onModalCarrousel(true, 2)}
                        >
                            <Fade>
                                <span><img className="w-[55px]" src={IconOption3} /></span>
                                <p>Equipos de alta gama</p>
                            </Fade>
                        </button> */}
                        <div
                            // type="button"
                            className="glassmorphism flex w-full items-center space-x-[15px] py-[15px] px-[10px] lg:space-x-[30px] lg:px-[20px] text-left scale-100 hover:scale-105 ease-in duration-150"
                            onClick={() => onModalCarrousel(true, 2)}
                        >
                            <Fade>
                                <span><img className="w-[55px]" src={IconOption4} /></span>
                                <p>PCs de última generación</p>
                            </Fade>
                        </div>
                        <div
                            // type="button"
                            className="glassmorphism flex w-full items-center space-x-[15px] py-[15px] px-[10px] lg:space-x-[30px] lg:px-[20px] text-left scale-100 hover:scale-105 ease-in duration-150"
                            onClick={() => onModalCarrousel(true, 3)}
                        >
                            <Fade>
                                <span><img className="w-[55px]" src={IconOption5} /></span>
                                <p>Más de 200 títulos</p>
                            </Fade>
                        </div>
                        <button
                            type="button"
                            className="glassmorphism flex w-full items-center space-x-[15px] py-[15px] px-[10px] lg:space-x-[30px] lg:px-[20px] text-left scale-100 hover:scale-105 ease-in duration-150"
                            onClick={() => {
                                window.open('https://menuplatino.mx/bar/', '_blank');
                            }}
                        >
                            <Fade>
                                <span><img className="w-[55px]" src={IconOption6} /></span>
                                <p>Bebidas y alimentos gourmet</p>
                            </Fade>
                        </button>
                        <div className="space-y-[10px] pt-[20px]">
                            <Fade cascade damping={0.1}>
                                <div>
                                    <button className="scale-100 hover:scale-105 ease-in duration-150" type="button" onClick={() => onModalCarrousel(true, 0)}>
                                        <img className="w-[300px] h-[120px] lg:w-[450px] lg:h-[200px] rounded-lg" src={ImageOption1} />
                                    </button>
                                </div>
                                <div className="flex space-x-[1vw]">
                                    <div>
                                        <button className="scale-100 hover:scale-105 ease-in duration-150" type="button" onClick={() => onModalCarrousel(true, 1)}>
                                            <img className="w-[100px] h-[50px] lg:w-[140px] lg:h-[80px] rounded-lg" src={ImageOption2} />
                                        </button>
                                    </div>
                                    <div>
                                        <button className="scale-100 hover:scale-105 ease-in duration-150" type="button" onClick={() => onModalCarrousel(true, 2)}>
                                            <img className="w-[100px] h-[50px] lg:w-[140px] lg:h-[80px] rounded-lg" src={ImageOption3} />
                                        </button>
                                    </div>
                                    <div>
                                        <button className="scale-100 hover:scale-105 ease-in duration-150" type="button" onClick={() => onModalCarrousel(true, 3)}>
                                            <img className="w-[100px] h-[50px] lg:w-[140px] lg:h-[80px] rounded-lg" src={ImageOption4} />
                                        </button>
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="relative md:hidden top-[52vw]">
                <div className="space-y-[5vw]">
                    <div className="space-y-[2vw]">
                        <div className="glassmorphism pt-[11vw] pb-[5vw] px-[5vw] text-left txt-arena-white rounded">
                            <Fade cascade damping={0.1}>
                                <div className="absolute top-[-15vw]">
                                    <span><img className="w-[22vw]" src={IconBirthday} /></span>
                                </div>
                                <h3 className="text-[4.5vw] font-bold pr-[10vw]">
                                    Celebra tu Cumpleaños en Arena The Place to Play Movistar
                                </h3>
                                <p className="py-[3vw] text-[4vw]">
                                    Sé parte de esta gran experiencia, organiza tu evento en cualquiera
                                    de nuestras sucursales y forma parte del Gaming Center #1 en México.
                                </p>
                                <p className="text-[4vw]">
                                    Para tu evento en Arena The Place to Play Movistar te ofrecemos la experiencia de vivir el mundo gamer como
                                    nunca antes lo has vivido con paquetes de acuerdo a tus necesidades.
                                </p>
                            </Fade>
                        </div>
                    </div>
                    <div className="space-y-[2.5vw] txt-arena-white text-left text-[4vw] font-bold">
                        <div className="glassmorphism flex items-center space-x-[4vw] py-[2vw] px-[3vw]">
                            <Fade>
                                <span><img className="w-[10vw]" src={IconOption1} /></span>
                                <p>Salas Platino, VR y Retro</p>
                            </Fade>
                        </div>
                        <div className="glassmorphism flex items-center space-x-4 py-2 px-3">
                            <Fade>
                                <span><img className="w-[10vw]" src={IconOption2} /></span>
                                <p>Estaciones de Juego</p>
                            </Fade>
                        </div>
                        {/* <div className="glassmorphism flex items-center space-x-4 py-2 px-3">
                            <Fade>
                                <span><img className="w-[10vw]" src={IconOption3} /></span>
                                <p>Equipos de alta gama</p>
                            </Fade>
                        </div> */}
                        <div className="glassmorphism flex items-center space-x-4 py-2 px-3">
                            <Fade>
                                <span><img className="w-[10vw]" src={IconOption4} /></span>
                                <p>PCs de última generación</p>
                            </Fade>
                        </div>
                        <div className="glassmorphism flex items-center space-x-4 py-2 px-3">
                            <Fade>
                                <span><img className="w-[10vw]" src={IconOption5} /></span>
                                <p>Más de 200 títulos</p>
                            </Fade>
                        </div>
                        <div className="glassmorphism flex items-center space-x-4 py-2 px-3" onClick={() => {
                            window.open('https://menuplatino.mx/bar/', '_blank');
                        }}>
                            <Fade>
                                <span><img className="w-[10vw]" src={IconOption6} /></span>
                                <p>Bebidas y alimentos gourmet</p>
                            </Fade>
                        </div>
                        <div className="space-y-[3vw] pt-[3.5vw]" >
                            <Fade>
                                <div>
                                    <img className="w-[90vw] rounded-lg" src={ImageOption1} />
                                </div>
                                <div className="flex space-x-[2vw]">
                                    <div>
                                        <img className="w-[28vw] h-[15vw] rounded-lg" src={ImageOption2} />
                                    </div>
                                    <div>
                                        <img className="w-[28vw] h-[15vw] rounded-lg" src={ImageOption3} />
                                    </div>
                                    <div>
                                        <img className="w-[28vw] h-[15vw] rounded-lg" src={ImageOption4} />
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                    <div className="pt-[6vw] pb-[50vw]">
                        <h3 className="text-left txt-arena-white text-[4.5vw] font-bold px-[3vw] pb-[5vw]">Contáctanos</h3>
                        <BirthdayForm
                            duration={duration}
                            venues={venues}
                            events={events}
                            onWarning={onWarning}
                            onSuccess={onSuccess}
                            onStatus={onStatus}
                            onClosePop={onClosePop}
                            statusPop={statusPop}
                            onLoading={onLoading}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default BirthdayCard;