function getCurrentStringDate(): string {
    let currentMonth: string = "";
    let currentDay: any;

    if (new Date().getMonth()+1 <= 9) {
        currentMonth = `0${new Date().getMonth()+1}`;
    } else {
        currentMonth = `${new Date().getMonth()+1}`;
    }

    if (new Date().getDate() <= 9) {
        // currentDay = `0${new Date().getDate()}`;
        currentDay = parseInt(`0${new Date().getDate()}`) + 1
        currentDay = `0${currentDay.toString()}`

    } else {
        // currentDay = ;
        currentDay = parseInt(`${new Date().getDate()}`) + 1
    }
    console.log( currentDay)
    return `${new Date().getFullYear()}-${currentMonth}-${currentDay}`;
}

export {
    getCurrentStringDate
}