import RealesesForm from "./RealesesForm";

const RealesesPop = ({
    onResponse,
    realese,
    stars,
    plataforma,
    esrb,
    onRefresh
} : {
    onResponse: any,
    realese: any,
    stars: Array<any>,
    plataforma: Array<any>,
    esrb: Array<any>,
    onRefresh: any
}) => {
    const closeModal = (close: boolean) => {
        onResponse(close);
    }

    return (
        <>
            {/* Desktop */}
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[700px] lg:w-[800px]">
                        <div className="p-[20px] border-0 rounded-lg shadow-lg flex-col w-full bg-arena-white outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-[10px] rounded-t">
                                <span className="text-[20px] font-bold">
                                    { realese ? "Actualizar Lanzamiento" : "Nuevo Lanzamiento" }
                                </span>
                                <button
                                    className="p-[5px] ml-auto border-0 txt-arena-black float-right text-[20px] leading-none outline-none focus:outline-none"
                                    onClick={() => closeModal(false)}
                                >
                                    X
                                </button>
                            </div>
                            <div className="relative px-[10px] pb-[20px] pt-[10px] flex-auto">
                                <RealesesForm
                                    onRefresh={() => {
                                        onRefresh(true);
                                        setTimeout(() => {
                                            onResponse(false);
                                        }, 2000);
                                    }}
                                    realese={realese}
                                    arrPlataform={plataforma}
                                    arrESRB={esrb}
                                    arrStars={stars}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default RealesesPop;