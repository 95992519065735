import { useState, useEffect } from "react";
import { AiFillWarning, AiOutlineClose } from "react-icons/ai";

interface PopData {
    icon: any;
    color: string,
    title: string;
    body: string;
}

const PopWarning = ({ body, onClose } : { body: string, onClose: any }) => {
    const [popData, setPopData] = useState<PopData>({
        icon: null,
        color: "",
        title: "",
        body: ""
    });

    useEffect(() => { 
        setPopData({...popData,
            icon: <AiFillWarning />,
            color: "text-yellow-600",
            title: "¡ATENCIÓN!",
            body: body
        });

        setInterval(() => {
            onClose(false);
        }, 8000);
    }, [body]);

    return (
        <>
            <div className="block">
                <div className="mt-[65px] mr-[10px] md:mr-[30px] justify-end flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[400px]">
                        <div className="p-[5px] md:p-[10px] border-0 rounded-lg shadow-lg flex-col w-full bg-arena-black opacity-75 outline-none focus:outline-none">
                            <div className="relative px-[8px] py-[10px] flex-auto">
                                <div className="flex justify-between items-center space-x-[15px]">
                                    <div className={popData.color}>
                                        <span className="text-[30px] md:text-[30px]">
                                            { popData.icon }
                                        </span>
                                    </div>
                                    <div className="txt-arena-white text-left">
                                        <div className="font-bold">
                                            <h3 className="text-[14px]">{ popData.title }</h3>
                                        </div>
                                        <div className="">
                                            <p className="text-[14px]">{ popData.body }</p>
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="button"
                                            className="txt-arena-white text-[20px] rounded-sm"
                                            onClick={() => onClose(false)}
                                        >
                                            <AiOutlineClose />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PopWarning;