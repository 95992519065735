import { useEffect, useState } from "react";
import { getRealeses } from "../../services/Realese";
import { AiFillStar } from "react-icons/ai";
import { Parallax, ParallaxLayer } from "@react-spring/parallax";
import { Fade } from "react-awesome-reveal";
import IconBg from "../../assets/icons/06_hexagon.svg";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import RealeseModel from "../../models/Realese";
import RealesesBody from "./RealesesBody";
import ReactPlayer from 'react-player';
import Loading from "../../components/loading/Loading";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import RealesesPop from "./RealesesPop";
import Rellax from "rellax";
import "./Realeses.css";

const arrConsoleBg: any = {
    "Multiplataforma": "bg-arena-mp",
    "Nintendo Switch": "bg-arena-ndo",
    "Windows PC": "bg-arena-pc",
    "Steam": "bg-arena-pcstm",
    "Playstation 4/Pro": "bg-arena-ps4",
    "Playstation 5": "bg-arena-ps5",
    "Xbox One": "bg-arena-xo",
    "Xbox Series X/S": "bg-arena-xos",
    "Android": "bg-arena-aod",
    "iOS": "bg-arena-ios",
};

interface PopInfo {
    status: boolean,
    info: RealeseModel
}

const Realeses = () => {
    const [pages, setPages] = useState<Array<number>>([]);
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [realeses, setRealeses] = useState<Array<RealeseModel>>([]);
    const [urlVideo, setUrlVIdeo] = useState<string>("");
    const [consoleVideo, setConsoleVIdeo] = useState<string>("");
    const [nameVideo, setnameVIdeo] = useState<string>("");

    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    const [statusModal, setStatusModal] = useState<PopInfo>({
        status: false,
        info: {
            companyID: "",
            gameID: "",
            gameTitle: "",
            gameDeveloper: "",
            gameBody: "",
            gameGenre: [],
            gameESRB: "",
            gamePlatform: "",
            gameReleaseDate: "",
            gameScore: 0,
            gameCreatedBy: "",
            gameImageURL: "",
            dropVideoURL: ""
        }
    });

    const [rating, setRating] = useState<Array<string>>([
        "text-gray-200",
        "text-gray-200",
        "text-gray-200",
        "text-gray-200",
        "text-gray-200"
    ]);

    const plataformsOptions = [
        { label: "Multiplataforma", value: "Multiplataforma" },
        { label: "Nintendo Switch", value: "Nintendo Switch" },
        { label: "Windows PC", value: "Windows PC" },
        { label: "Steam", value: "Steam" },
        { label: "Playstation 4/Pro", value: "Playstation 4/Pro" },
        { label: "Playstation 5", value: "Playstation 5" },
        { label: "Xbox One", value: "Xbox One" },
        { label: "Xbox Series X/S", value: "Xbox Series X/S" },
        { label: "Android", value: "Android" },
        { label: "iOS", value: "iOS" }
    ];

    const starsOptions = [
        { label: "1 estrella", value: 1 },
        { label: "2 estrellas", value: 2 },
        { label: "3 estrellas", value: 3 },
        { label: "4 estrellas", value: 4 },
        { label: "5 estrellas", value: 5 },
    ];

    useEffect(() => {
        new Rellax(".animatev0", { speed: 3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev1", { speed: -1, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev2", { speed: -2, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev3", { speed: -3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev4", { speed: -4, center: false, round: true, vertical: true, horizontal: false });

        new Promise<void>((resolve, reject) => {
            // Get all realeses
            getRealeses().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr = new Array();
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        newArr.push(element);
                    }
                    setRealeses(newArr);
                    setUrlVIdeo(newArr[0]['dropVideoURL']);
                    setConsoleVIdeo(newArr[0]['gamePlatform']);
                    setnameVIdeo(newArr[0]['gameTitle']);

                    // Se determina el rating del video de la cabecera
                    const newRating = newArr.map((ratingElement: string, i: number) => {
                        if ((i+1) <= newArr[0]['gameScore']) {
                            return "text-yellow-500";
                        } else {
                            return ratingElement;
                        }
                    });
                    setRating(newRating);
                }
            });

            // Resolve promise
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    }, []);

    return (
        <>
            { loadingStatus ? <Loading /> : null }

            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({...popupTP, status: false, documentStr: ""}) }}
                    />
                : null
            }

            {
                statusModal.status ?
                    <RealesesPop realese={statusModal.info} closeModal={() => {setStatusModal({...statusModal, status: false})}} />
                : null
            }

            <div id="animatev0" style={{ scrollBehavior: 'auto' }} className="bg-arena-gray">
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-40">
                        <Navbar />
                    </div>
                </div>
                <div className="relative w-[100%] md:w-[650px] lg:w-[900px] m-auto">
                    <div className="flex justify-center pb-[32vw] md:pb-[300px] lg:pb-[510px]">
                        <div className="absolute w-[100vw] h-[55vw] top-[50px] m-auto z-0 md:w-[500px] md:h-[280px] md:top-[70px] lg:w-[760px] lg:h-[428px] lg:top-[70px]">
                            <div className="md:hidden absolute top-[3vw] md:top-[17vw] left-[5vw] z-0">
                                <div className="text-left txt-arena-white text-[3vw]">
                                    <span className={"px-[2vw] py-[0.5vw] rounded-md " + arrConsoleBg[`${consoleVideo}`]} >{consoleVideo}</span>
                                </div>
                                <div className="text-left txt-arena-white text-[4vw] font-bold pt-[2vw] pb-[0.5vw]">
                                    <h3>{nameVideo}</h3>
                                </div>
                                <div className="flex text-[4vw] space-x-[0.2vw]">
                                    <span className={rating[0]}><AiFillStar /></span>
                                    <span className={rating[1]}><AiFillStar /></span>
                                    <span className={rating[2]}><AiFillStar /></span>
                                    <span className={rating[3]}><AiFillStar /></span>
                                    <span className={rating[4]}><AiFillStar /></span>
                                </div>
                            </div>
                            <ReactPlayer
                                url={urlVideo}
                                className='react-player'
                                playing
                                width='100%'
                                height='100%'
                                controls={false}
                                loop={true}
                                muted={true}
                            />
                        </div>
                    </div>
                    <div className="pb-[100px] w-[90%] md:w-[650px] lg:w-[900px] m-auto">
                        <img className="animatev2 absolute w-[50vw] top-[45vw] left-[-35vw] md:w-[250px] md:top-[300px] md:left-[-100px] lg:top-[450px] lg:left-[50px] z-0" src={IconBg} />
                        <img className="animatev1 absolute w-[50vw] top-[60vw] left-[40vw] md:w-[250px] md:top-[390px] md:left-[440px] lg:top-[540px] lg:left-[630px] z-0" src={IconBg} />
                        <RealesesBody
                            plataform={plataformsOptions}
                            stars={starsOptions}
                            realeses={realeses}
                            onModalDetail={(relModel: RealeseModel, sts: boolean) => {
                                setStatusModal({...statusModal, status: sts, info: relModel});
                            }}
                        />
                    </div>
                </div>
                <div className="relative pt-10">
                    <img className="hidden md:block absolute w-[250px] top-[-80px] left-[-140px] lg:top-[-100px] lg:left-[-20px] z-0 md:z-10" src={IconBg} />
                    <Fade>
                        <Footer
                            statusPop={(opt: any) => {
                                setPopupTP({...popupTP, status: opt.status, documentStr: opt.documentStr })
                            }}
                        />
                    </Fade>
                </div>
            </div>
        </>
    );
}

export default Realeses;