import { functions, httpsCallable } from "../firebase";

export const getCompanyById = (data: Object) => {
    const getRCompanyById = httpsCallable(functions, "readCompanyById");
    const res = getRCompanyById(data);
    return res;
}

export const updateCompany = (data: Object) => {
    const editCompany = httpsCallable(functions, "updateCompanyById");
    const res = editCompany(data);
    return res;
}