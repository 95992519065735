import { useEffect, useState } from "react";
import { getNews } from "../../services/News";
import { Fade } from "react-awesome-reveal";
import IconBg from "../../assets/icons/06_hexagon.svg";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import NewsModel from "../../models/News";
import NewsHeader from "./NewsHeader";
import NewsMore from "./NewsMore";
import Loading from "../../components/loading/Loading";
import PDFViewer from "../../components/pdf-viewer/PDFViewer";
import Rellax from "rellax";
import "./News.css";

const News = () => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(true);
    const [news, setNews] = useState<Array<NewsModel>>([]);
    const [popupTP, setPopupTP] = useState({
        status: false,
        documentStr: ""
    });

    useEffect(() => {
        new Rellax(".animatev0", { speed: 3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev1", { speed: -1, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev2", { speed: -2, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev3", { speed: -3, center: false, round: true, vertical: true, horizontal: false });
        new Rellax(".animatev4", { speed: -4, center: false, round: true, vertical: true, horizontal: false });

        new Promise<void>((resolve, reject) => {
            getNews().then(res => {
                if (res && res.data) {
                    const datares: any = res.data;
                    let newArr: Array<NewsModel> = [];
                    for (let i = 0; i < datares.length; i++) {
                        const element = Object(datares[i]);
                        let diasDif = new Date().getTime() - new Date(element.noteCreatedAt).getTime();
                        element.noteCreatedAt = Math.round(diasDif/(1000 * 60 * 60 * 24));
                        newArr.push(element);
                    }

                    // Se ordena las noticias por episodio
                    newArr.sort((a: NewsModel, b:NewsModel) => {
                        return Number(String(b.noteEpisode).replace("Episodio ", "")).valueOf() - Number(String(a.noteEpisode).replace("Episodio ", "")).valueOf();
                    });

                    setNews(newArr);
                }
            });
            setTimeout(function() {
                setLoadingStatus(false);
                resolve();
            }, 1000);
        });
    }, []);

    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                popupTP.status ?
                    <PDFViewer
                        documentStr={popupTP.documentStr}
                        closeModal={() => { setPopupTP({...popupTP, status: false, documentStr: ""}) }}
                    />
                : null
            }
            
            <div id="animatev0" style={{ scrollBehavior: 'auto' }} className="bg-arena-gray">
                <div className="flex flex-row">
                    <div className="w-full bg-no-repeat bg-cover fixed z-40">
                        <Navbar />
                    </div>
                </div>
                <div className="relative w-[100%] md:w-[650px] lg:w-[900px] pt-[50px] m-auto md:pt-[70px] lg:pt-[50px] z-0">
                    <img className="animatev3 absolute w-[50vw] top-[120vw] left-[-35vw] md:w-[250px] md:top-[300px] md:left-[-100px] lg:top-[500px] lg:left-[0px] z-10" src={IconBg} />
                    <img className="animatev1 absolute w-[50vw] top-[90vw] left-[60vw] md:w-[250px] md:top-[390px] md:left-[440px] lg:top-[600px] lg:left-[630px] z-0" src={IconBg} />

                    <NewsHeader news={news} />
                </div>
                <div className="news-arena-style bg-arena-white relative mt-[70vw] md:mt-[50px]">
                    <div className="w-[90%] m-auto pt-10 md:w-[70%] md:h-[1050px] lg:w-[75%] lg:h-[650px]">
                        <NewsMore news={news} />
                    </div>
                    <div className="relative pt-10 md:pt-[200px]">
                        <img className="hidden md:block absolute w-[60vw] top-[310vw] left-[-45vw] md:w-[250px] md:top-[50px] md:left-[-40px] lg:top-[70px] lg:left-[150px] z-0 md:z-10" src={IconBg} />
                        <Fade>
                            <Footer
                                statusPop={(opt: any) => {
                                    setPopupTP({...popupTP, status: opt.status, documentStr: opt.documentStr })
                                }}
                            />
                        </Fade>
                    </div>
                </div>
            </div>
        </>
    );
}

export default News;