import { useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IDCompany } from "../../../global/global";
import { deletePopup } from "../../../services/Popup";
import Loading from "../../../components/loading/Loading";
import Pop from "../../../components/Pop/Pop";
import PopNotify from "../../../components/Pop/PopNotify";
import PopupModel from "../../../models/Popup";
import PopupPop from "./PopupPop";

interface PopInfo {
    status: boolean,
    type: string
}

const PopupButtonsTable = ({ onRefresh, popupElement } : { onRefresh: any, popupElement: PopupModel }) => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [closeStatus, setCloseStatus] = useState<boolean>(false);
    const [updateStatus, setUpdateStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    
    const removePopup = (res: boolean) => {
        setCloseStatus(false);
        if (res) {
            setLoadingStatus(true);
            new Promise<void>((resolve, reject) => {
                deletePopup({ companyID: `${IDCompany}`, popupID: popupElement.popupID }).then(res => {
                    setLoadingStatus(false);
                    if (res && res.data) {
                        setPopStatus({...popStatus, status: true, type: "success"});
                        onRefresh(true);
                    }
                });
    
                // Resolve promise
                setTimeout(function() {
                    resolve();
                }, 1000);
            });
        }
    }

    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                closeStatus ?
                    <Pop title={`¿Está seguro que desea eliminar ${popupElement.popupTitle}?`} message="" onClose={() => {setCloseStatus(false) }} onResponse={removePopup} />
                : null
            }

            {
                updateStatus ?
                    <PopupPop onRefresh={onRefresh} popupElement={popupElement} onResponse={() => {setUpdateStatus(false)} } />
                : null
            }

            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="relative">
                <div className="hidden lg:block">
                    <div className="dropdown">
                        <button className="dropbtn"><HiDotsVertical /></button>
                        <div className="dropdown-content">
                            <a onClick={() => setUpdateStatus(true) } href="#">Editar aviso</a>
                            <a onClick={() => setCloseStatus(true) } href="#">Eliminar aviso</a>
                        </div>
                    </div>
                </div>
                <div className="lg:hidden flex space-x-1">
                    <a className="p-2 bg-zinc-200 txt-arena-gray text-[16px] rounded-md" onClick={() => setUpdateStatus(true) } href="#"><FaRegEdit /></a>
                    <a className="p-2 bg-zinc-200 txt-arena-gray text-[16px] rounded-md" onClick={() => setCloseStatus(true) } href="#"><AiFillDelete /></a>
                </div>
            </div>
        </>
    );
}

export default PopupButtonsTable;