import { Link } from "react-router-dom";
import NewsModel from "../../models/News";
import NewsMore from "../news/NewsMore";

const HomepageNews = ({ news } : { news: Array<NewsModel> }) => {
    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <NewsMore news={news} />
                <div className="w-[350px] py-[25px] m-auto scale-100 hover:scale-105 ease-in duration-150">
                    <Link to="/news">
                        <button className="btn-arena-primary" type="button">
                            <span className="text-[14px] tracking-[2px]">VER TODAS LAS NOTICIAS</span>
                        </button>
                    </Link>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden">
                <NewsMore news={news} />
                <div className="w-[75vw] py-[5vw] m-auto">
                    <Link to="/news">
                        <button className="btn-arena-primary" type="button">
                            <span className="text-[4vw]">VER TODAS LAS NOTICIAS</span>
                        </button>
                    </Link>
                </div>
            </div>
        </>
    );
}

export default HomepageNews;