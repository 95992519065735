import { useEffect, useState } from "react";
import { uploadImages, uploadVideos } from "../../../firebase";
import { IDCompany } from "../../../global/global";
import { addPopup, updatePopup } from "../../../services/Popup";
import Loading from "../../../components/loading/Loading";
import PopNotify from "../../../components/Pop/PopNotify";
import PopupModel from "../../../models/Popup";
import { HiPhotograph } from "react-icons/hi";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsFillCameraVideoFill, BsPlusCircleFill } from "react-icons/bs";

interface ImageData {
    name: string;
    file: any;
}

interface PopInfo {
    status: boolean,
    type: string
}

const PopupForm = ({ popupElement, onRefresh }: { popupElement: any, onRefresh: any }) => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [imageUpload, setImageUpload] = useState<Array<ImageData>>([
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null },
        { name: "", file: null }
    ]);

    const [videoUpload, setVideoUpload] = useState<ImageData>({
        name: "",
        file: null
    });

    const [popupData, setPopupData] = useState({
        companyID: { value: `${IDCompany}`, isCorrect: true },
        popupID: { value: "", isCorrect: false },
        popupTitle: { value: "", isCorrect: false },
        popupImageURL: { value: [], isCorrect: false },
        popupVideoURL: { value: "", isCorrect: false },
        popupCreatedBy: { value: "", isCorrect: false },
    });

    const [urlExterna, setUrlExterna] = useState("")

    const deleteArrImage = (index: number) => {
        const newImageUpload = imageUpload.map((imgEle: ImageData, i: number) => {
            if (index === i) {
                return { name: "", file: null }
            } else {
                return { name: imgEle.name, file: imgEle.file }
            }
        });
        setImageUpload(newImageUpload);
    }

    const deleteVideo = () => {
        setVideoUpload({ ...videoUpload, name: "", file: null });
    }

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setPopupData({ ...popupData, [evt.target.name]: { value: evt.target.value, isCorrect: true } });
    }
    const handleChangeUrlExterna  = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setUrlExterna(evt.target.value)
    }

    const handleUpload = (evt: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = evt.currentTarget.files;
        if (file !== null) {
            const newImageUpload = imageUpload.map((imgEle: ImageData, i: number) => {
                if (index === i) {
                    return { name: file[0].name, file: file[0] }
                } else {
                    return { name: imgEle.name, file: imgEle.file }
                }
            });
            setImageUpload(newImageUpload);
        }
    }

    const handleVideoUpload = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const file = evt.currentTarget.files;
        if (file !== null) {
            setVideoUpload({ ...videoUpload, name: file[0].name, file: file[0] });
        }
    }

    const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (popupData.popupTitle.isCorrect) {
            setLoadingStatus(true);
            let newImgUplAux: Array<string> = [];
            for (let i = 0; i < imageUpload.length; i++) {
                let imageUrlStr: any = "";
                if (imageUpload[i].file !== null) {
                    imageUrlStr = await uploadImages(imageUpload[i].file, "popup");
                } else if (imageUpload[i].name !== "") {
                    imageUrlStr = imageUpload[i].name;
                }
                newImgUplAux.push(imageUrlStr);
            }

            let videoUrlStr: string = "";
            if (videoUpload.file) {
                videoUrlStr = await uploadVideos(videoUpload.file, "popups");
            }
            //FALTA VALIDAR URL

            const popupModelData: PopupModel = {
                companyID: `${IDCompany}`,
                popupID: popupElement ? popupElement.popupID : "",
                popupTitle: popupData.popupTitle.value,
                popupCreatedBy: popupData.popupCreatedBy.value,
                popupImageURL: newImgUplAux,
                popupVideoURL: videoUrlStr,
                popupExternalURL: urlExterna // get input 
            }
            // console.log(popupModelData, "payload")

            new Promise<void>((resolve, reject) => {
                if (popupElement !== null) {
                    updatePopup(popupModelData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data) {
                            setPopStatus({ ...popStatus, status: true, type: "success" });
                            onRefresh(true);
                        } else {
                            setPopStatus({ ...popStatus, status: true, type: "error" });
                        }
                    });
                    setTimeout(function () {
                        resolve();
                    }, 1000);
                } else {
                    addPopup(popupModelData).then(res => {
                        setLoadingStatus(false);
                        if (res && res.data && res.data === "Popup -undefined- was created successfully") {
                            onRefresh(true);
                            setPopStatus({ ...popStatus, status: true, type: "success" });
                            setImageUpload([
                                { name: "", file: null },
                                { name: "", file: null },
                                { name: "", file: null },
                                { name: "", file: null }
                            ]);
                            setPopupData({
                                ...popupData,
                                companyID: { value: `${IDCompany}`, isCorrect: true },
                                popupID: { value: "", isCorrect: false },
                                popupTitle: { value: "", isCorrect: false },
                                popupImageURL: { value: [], isCorrect: false },
                                popupVideoURL: { value: "", isCorrect: false },
                            });
                        } else {
                            setPopStatus({ ...popStatus, status: true, type: "error" });
                        }
                    });
                    setTimeout(function () {
                        resolve();
                    }, 1000);
                }
            });
        } else {
            setPopStatus({ ...popStatus, status: true, type: "warning" });
        }
    }

    useEffect(() => {
        if (popupElement !== null) {
            const newPopup = {
                companyID: { value: `${IDCompany}`, isCorrect: true },
                popupID: { value: popupElement.popupID, isCorrect: true },
                popupTitle: { value: popupElement.popupTitle, isCorrect: true },
                popupCreatedBy: { value: popupElement.popupCreatedBy, isCorrect: true },
                popupImageURL: { value: popupElement.popupImageURL, isCorrect: true },
                popupVideoURL: { value: popupElement.popupVideoURL, isCorrect: true }
            }
            setPopupData(newPopup);

            // Se almacenan las imagenes en un estado para manipular en caso que se desee editar
            let arrImgInput: Array<ImageData> = [];
            for (let i = 0; i < 4; i++) {
                if (popupElement.popupImageURL[i]) {
                    arrImgInput.push({ name: popupElement.popupImageURL[i], file: null });
                } else {
                    arrImgInput.push({ name: "", file: null });
                }
            }
            setImageUpload(arrImgInput);
        }
    }, [popupElement]);

    return (
        <>
            {loadingStatus ? <Loading /> : null}
            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({ ...popStatus, status: false, type: "" }) }} />
                    : null
            }

            <div className="block">
                <form onSubmit={handleSubmit}>
                    <div className="px-2 text-left">
                        <div className="space-y-7">
                            <div className="space-y-5">
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                        Nombre del aviso
                                    </label>
                                    <input
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                        id="popupTitleInput"
                                        type="text"
                                        placeholder="Nombre del aviso..."
                                        name="popupTitle"
                                        onChange={handleChange}
                                        value={popupData.popupTitle.value}
                                    />
                                </div>
                                <div className="mb-4 space-y-2">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                        Imagen <span className="text-zinc-400">{'Resolución recomendada 600x650 (máximo 4 imagenes)'}</span>
                                    </label>
                                    <div className="flex items-center justify-center w-full">
                                        <label className="w-full h-12 border-4 border-dashed">
                                            <div className="flex space-x-2 p-2">
                                                <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                <div>
                                                    {imageUpload[0].name ?
                                                        <div className="flex">
                                                            <span
                                                                className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                            >
                                                                {imageUpload[0].name}
                                                            </span>
                                                            <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteArrImage(0)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="flex">
                                                            <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                Cargar imagen...
                                                            </span>
                                                            <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input type="file" className="opacity-0" name="venueImagesURL" onChange={(evt: any) => handleUpload(evt, 0)} />
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-center w-full">
                                        <label className="w-full h-12 border-4 border-dashed">
                                            <div className="flex space-x-2 p-2">
                                                <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                <div>
                                                    {imageUpload[1].name ?
                                                        <div className="flex">
                                                            <span
                                                                className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                            >
                                                                {imageUpload[1].name}
                                                            </span>
                                                            <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteArrImage(1)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="flex">
                                                            <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                Cargar imagen...
                                                            </span>
                                                            <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input type="file" className="opacity-0" name="venueImagesURL" onChange={(evt: any) => handleUpload(evt, 1)} />
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-center w-full">
                                        <label className="w-full h-12 border-4 border-dashed">
                                            <div className="flex space-x-2 p-2">
                                                <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                <div>
                                                    {imageUpload[2].name ?
                                                        <div className="flex">
                                                            <span
                                                                className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                            >
                                                                {imageUpload[2].name}
                                                            </span>
                                                            <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteArrImage(2)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="flex">
                                                            <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                Cargar imagen...
                                                            </span>
                                                            <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input type="file" className="opacity-0" name="venueImagesURL" onChange={(evt: any) => handleUpload(evt, 2)} />
                                        </label>
                                    </div>
                                    <div className="flex items-center justify-center w-full">
                                        <label className="w-full h-12 border-4 border-dashed">
                                            <div className="flex space-x-2 p-2">
                                                <span className="text-[25px] text-zinc-400"><HiPhotograph /></span>
                                                <div>
                                                    {imageUpload[3].name ?
                                                        <div className="flex">
                                                            <span
                                                                className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                            >
                                                                {imageUpload[3].name}
                                                            </span>
                                                            <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteArrImage(3)}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="flex">
                                                            <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                Cargar imagen...
                                                            </span>
                                                            <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input type="file" className="opacity-0" name="venueImagesURL" onChange={(evt: any) => handleUpload(evt, 3)} />
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-4 space-y-2">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                        Video
                                    </label>
                                    <div className="flex items-center justify-center w-full">
                                        <label className="w-full h-12 border-4 border-dashed">
                                            <div className="flex space-x-2 p-2">
                                                <span className="text-[25px] text-zinc-400"><BsFillCameraVideoFill /></span>
                                                <div>
                                                    {videoUpload.name ?
                                                        <div className="flex">
                                                            <span
                                                                className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate"
                                                            >
                                                                {videoUpload.name}
                                                            </span>
                                                            <button className="text-red-500 text-[25px]" type="button" onClick={() => deleteVideo()}>
                                                                <AiFillCloseCircle />
                                                            </button>
                                                        </div>
                                                        :
                                                        <div className="flex">
                                                            <span className="w-[160px] md:w-[540px] lg:w-[640px] text-[14px] text-zinc-400 font-bold truncate">
                                                                Cargar video...
                                                            </span>
                                                            <span className="text-green-500 text-[25px]"><BsPlusCircleFill /></span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            <input type="file" className="opacity-0" name="noteVideoURL" onChange={handleVideoUpload} />
                                        </label>
                                    </div>
                                </div>
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                        Url externa
                                    </label>
                                    <input
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                        id="popupTitleInput"
                                        type="text"
                                        placeholder="Escribe la Url externa..."
                                        name="popupTitle"
                                        onChange={handleChangeUrlExterna}
                                        value={urlExterna}
                                    />
                                </div>
                                <div className="flex justify-end pt-[5px]">
                                    <button className="bg-arena-orange px-[90px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" type="submit">
                                        {popupElement ? "Guardar cambios" : "Agregar aviso"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default PopupForm;