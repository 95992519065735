import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";

const arrDocuments: any = {
  terms_conditions: require("../../assets/documents/terms_cond.pdf"),
  privacy: require("../../assets/documents/privacy.pdf"),
  documento: require("../../assets/documents/Documento.pdf")
}

const arrTitles: any = {
  terms_conditions: "Términos y condiciones",
  privacy: "Aviso de privacidad",
  documento: "Documentación"
}

const PDFViewer = ({ documentStr, closeModal }: { documentStr: string, closeModal: any }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [fileStr, setFileStr] = useState<any>(null);
  const [titleStr, setTitleStr] = useState<string>("");

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const changePage = (offset: any) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  }

  useEffect(() => {
    setFileStr(arrDocuments[`${documentStr}`]);
    setTitleStr(arrTitles[`${documentStr}`]);
  }, [documentStr]);

  return (
    <>
      <div className="block">
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none w-[90vw] md:w-[730px] lg:w-[80%]  m-auto">
          <div className="lg:h-[800px] border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
            <div className="flex items-center justify-between p-[5px] border-b border-solid border-gray-500 rounded-t">
              <span className="pl-4 txt-arena-white font-bold">{titleStr}</span>
              <button
                className="p-[12px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                onClick={() => closeModal(false)}
              >
                X
              </button>
            </div>
            {/* <div className="flex"> */}

            {/* </div> */}
            <div className="relative p-[15px]">
              <div className="w-auto h-[90vw] md:w-[622px] md:h-[500px] lg:w-[80%] lg:h-[600px] overflow-auto bg-arena-black m-auto rounded-md">
                <Document className="lg:w-[100%] justify-center" file={fileStr} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page scale={5.50/2.80} className="bg-arena-transparent  w-auto h-auto" pageNumber={pageNumber} />
                </Document>
              </div>
              <div className="txt-arena-white">
                <p className="py-1 text-[12px]">Página {pageNumber || (numPages ? 1 : "--")} de {numPages || "--"}</p>
                <div className="space-x-[50px]">
                  <button className="text-[14px] hover:text-[13px] hover:text-orange-500 duration-150" type="button" disabled={pageNumber <= 1} onClick={previousPage}>Anterior</button>
                  <button className="text-[14px] hover:text-[13px] hover:text-orange-500 duration-150" type="button" disabled={pageNumber >= numPages} onClick={nextPage}>Siguiente</button>
                </div>
                {titleStr === "Documentación" ?
                  <div className="flex justify-end">
                    <a href={arrDocuments.documento} download="Documentación Usuario Administrador Arena.pdf">
                      <div className="flex items-center bg-arena-orange py-[5px] px-[15px] space-x-[40px] rounded-sm txt-arena-white cursor-pointer">
                        Descargar
                      </div>
                    </a>
                  </div>
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>

        <div onClick={() => closeModal(false)} className="opacity-50 fixed inset-0 z-40 bg-black"></div>
      </div>
    </>
  );
}

export default PDFViewer;