import { useEffect, useState } from "react";
import { validateMail, validatePhone, validateString } from "../../../utils/Validators";
import { addUser, updateUser } from "../../../services/Users";
import Select from "../../../components/select/Select";
import UserModel from "../../../models/User";
import PopNotify from "../../../components/Pop/PopNotify";
import Loading from "../../../components/loading/Loading";

interface PopInfo {
    status: boolean,
    type: string
}

const UserForm = ({ userElement, arrTypes, onRefresh } : { userElement: UserModel, arrTypes: Array<any>, onRefresh: any }) => {
    const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [popStatus, setPopStatus] = useState<PopInfo>({ status: false, type: "" });
    const [user, setUser] = useState({
        userName: { value: "", isCorrect: false },
        userLastName: { value: "", isCorrect: false },
        userType: { value: "", isCorrect: false },
        userLoginEmail: { value: "", isCorrect: false },
        userPassword: { value: "", isCorrect: false },
    });

    const onResponseSelect = (opt: any) => {
        let arrCurrent: Array<Object> = [];
        if (opt.target.name === "userType") {
            arrCurrent = arrTypes;
        }
        setUser({...user, [opt.target.name]: { value: Object(arrCurrent[opt.target.value]).value, isCorrect: true }});
    }

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        let validateInput: boolean = true;
        
        if (evt.target.name === "userName" || evt.target.name === "userLastName" || evt.target.name === "userAddress") {
            validateInput = validateString(evt.target.value);
        } else if (evt.target.name === "userLoginEmail") {
            validateInput = validateMail(evt.target.value);
        } else if (evt.target.name === "userPhone") {
            validateInput = validatePhone(evt.target.value);
        } else {
            validateInput = true;
        }

        if (!validateInput) {
            document.getElementsByName(evt.target.name)[0].style.borderColor="red";
            setUser({...user, [evt.target.name]: { value: evt.target.value, isCorrect: false }});
        } else {
            document.getElementsByName(evt.target.name)[0].style.borderColor="rgb(113, 113, 122)";
            setUser({...user, [evt.target.name]: { value: evt.target.value, isCorrect: true }});
        }
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        if (
            user.userName.isCorrect && user.userLastName.isCorrect && user.userLoginEmail.isCorrect &&
            user.userType.isCorrect && user.userPassword.isCorrect
        ) {
            if (user.userPassword.value === password) {
                setLoadingStatus(true);
                const userData: UserModel = {
                    userName: user.userName.value,
                    userUID: userElement ? userElement.userUID : "",
                    userLastName: user.userLastName.value,
                    userType: user.userType.value,
                    userLoginEmail: user.userLoginEmail.value,
                    userPassword: user.userPassword.value,
                    userIsActive: true
                }
                new Promise<void>((resolve, reject) => {
                    if (userElement !== null) {
                        updateUser(userData).then(res => {
                            if (res && res.data) {
                                setLoadingStatus(false);
                                setPopStatus({...popStatus, status: true, type: "success"});
                                onRefresh(true);
                            } else {
                                setLoadingStatus(false);
                                setPopStatus({...popStatus, status: true, type: "error"});
                            }
                        });
                        setTimeout(function() {
                            resolve();
                        }, 1000);
                    } else {
                        addUser(userData).then(res => {
                            if (res && res.data) {
                                setLoadingStatus(false);
                                onRefresh(true);
                                setPopStatus({...popStatus, status: true, type: "success"});
                                setPassword("");
                                setUser({...user,
                                    userName: { value: "", isCorrect: false },
                                    userLastName: { value: "", isCorrect: false },
                                    userType: { value: "", isCorrect: false },
                                    userLoginEmail: { value: "", isCorrect: false },
                                    userPassword: { value: "", isCorrect: false },
                                });

                                let userTypeInput = document.getElementById("userTypeInput") as HTMLSelectElement | null;
                                if (userTypeInput !== null) userTypeInput.value = "";
                            } else {
                                setLoadingStatus(false);
                                setPopStatus({...popStatus, status: true, type: "error"});
                            }
                        });
                        setTimeout(function() {
                            resolve();
                        }, 1000);
                    }
                });
            } else {
                setPopStatus({...popStatus, status: true, type: "warning"});
            }
        } else {
            setPopStatus({...popStatus, status: true, type: "warning"});
        }
    }

    useEffect(() => {
        if (userElement !== null) {
            const newUser = {
                userUID: { value: userElement.userUID, isCorrect: true },
                userName: { value: userElement.userName, isCorrect: true },
                userLastName: { value: userElement.userLastName, isCorrect: true },
                userType: { value: userElement.userType, isCorrect: true },
                userLoginEmail: { value: userElement.userLoginEmail, isCorrect: true },
                userPassword: { value: userElement.userPassword, isCorrect: true },
            }
            setUser(newUser);
            setPassword(userElement.userPassword);

            // Se identifican los campos
            let userTypeInput = document.getElementById("userTypeInput") as HTMLSelectElement | null;
            
            // Se ingresan los valores
            if (userTypeInput !== null) userTypeInput.value = `${arrTypes.findIndex((el, i) => el.value === userElement.userType)}`;
        }
    }, [userElement]);

    return (
        <>
            { loadingStatus ? <Loading /> : null }
            {
                popStatus.status ?
                    <PopNotify type={popStatus.type} onClose={() => { setPopStatus({...popStatus, status: false, type: ""}) }} />
                : null
            }

            <div className="block">
                <form onSubmit={handleSubmit}>
                    <div className="text-left">
                        <div className="md:grid md:grid-cols-2 md:gap-4">
                            <div>
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                        Nombre
                                    </label>
                                    <input
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                        id="nameInput"
                                        type="text"
                                        placeholder="Nombre..."
                                        name="userName"
                                        onChange={handleChange}
                                        value={user.userName.value}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="mailLabel">
                                        Correo
                                    </label>
                                    <input
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                        id="userLoginInput"
                                        type="text"
                                        placeholder="Correo electronico..."
                                        name="userLoginEmail"
                                        onChange={handleChange}
                                        value={user.userLoginEmail.value}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="mailLabel">
                                        Contraseña
                                    </label>
                                    <input
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                        id="userPasswordInput"
                                        type="password"
                                        placeholder="contraseña..."
                                        name="userPassword"
                                        onChange={handleChange}
                                        value={user.userPassword.value}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="nameLabel">
                                        Apellidos
                                    </label>
                                    <input
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                        id="nameInput"
                                        type="text"
                                        placeholder="Apellidos..."
                                        name="userLastName"
                                        onChange={handleChange}
                                        value={user.userLastName.value}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="locationLabel">
                                        Tipo de usuario
                                    </label>
                                    <Select className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black" options={arrTypes} onResponse={onResponseSelect} name="userType" id="userTypeInput" placeholder="Tipo de usuario..." />
                                </div>
                                <div className="mb-4">
                                    <label className="block txt-arena-orange text-[12px] font-bold" htmlFor="mailLabel">
                                        Repetir contraseña
                                    </label>
                                    <input
                                        className="appearance-none border-b border-zinc-500 bg-transparent w-full py-[10px] text-[14px] txt-arena-black"
                                        id="userPassword2Input"
                                        type="password"
                                        placeholder="Repetir contraseña..."
                                        name="userPassword2"
                                        onChange={(evt) => setPassword(evt.currentTarget.value) }
                                        value={password}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-end pt-[5px]">
                            <button className="bg-arena-orange px-[70px] md:px-[140px] py-[10px] txt-arena-white text-[14px] rounded-full" type="submit">
                                {
                                    userElement ? "Guardar cambios" : "Agregar Usuario"
                                }
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}

export default UserForm;