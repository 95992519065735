import { useMemo, useState, useEffect } from "react";
import Table from "../../../components/table/Table";
import styled from "styled-components";
import RealeseModel from "../../../models/Realese";
import RealesesButtonsTable from "./RealesesButtonsTable";

const Styled = styled.div`
    table {
        width: 950px;
        text-align: left;
    }

    table th {
        background-color: rgb(240, 240, 240);
        font-weight: bold;
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    table td {
        font-size: 13px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        padding-right: 12px;
    }

    @media (max-width: 1350px) {
        table { width: 850px; }
    }

    @media (max-width: 1270px) {
        table { width: 750px; }
    }

    @media (max-width: 1180px) {
        table { width: 600px; }
    }

    @media (max-width: 1024px) {
        table { width: 800px; }
    }
`;

const arrConsoleBg: any = {
    "Multiplataforma": "bg-arena-mp",
    "Nintendo Switch": "bg-arena-ndo",
    "Windows PC": "bg-arena-pc",
    "Steam": "bg-arena-pcstm",
    "Playstation 4/Pro": "bg-arena-ps4",
    "Playstation 5": "bg-arena-ps5",
    "Xbox One": "bg-arena-xo",
    "Xbox Series X/S": "bg-arena-xos",
    "Android": "bg-arena-aod",
    "iOS": "bg-arena-ios",
};

const RealesesTable = ({
    realeses,
    esrb,
    plataformas,
    stars,
    onRefresh
} : {
    realeses: Array<RealeseModel>,
    esrb: Array<any>,
    plataformas: Array<any>,
    stars: Array<any>,
    onRefresh: any
}) => {
    const [newRealeses, setNewRealeses] = useState<Array<any>>([]);
    const columns = useMemo(() => [
        {
            Header: 'Nombre',
            accessor: 'gameTitle',
            width: '40%'
        },
        {
            Header: 'ESRB',
            accessor: 'gameESRB',
            width: '25%'
        },
        {
            Header: 'Plataforma',
            accessor: 'gamePlatform',
            width: '25%'
        },
        {
            Header: '',
            accessor: 'options',
            width: '10%'
        }
    ], []);

    useEffect(() => {
        const newArr = realeses.map((relElement: RealeseModel, i: number) => {
            let esrbFound = esrb.find(elem => elem.value === relElement.gameESRB);
            if (!esrbFound) esrbFound = { value: "", label: "" };
            return {
                companyID: relElement.companyID,
                dropVideoURL: relElement.dropVideoURL,
                gameBody: relElement.gameBody,
                gameCreatedBy: relElement.gameCreatedBy,
                gameDeveloper: relElement.gameDeveloper,
                gameESRB:
                    <div>
                        <span><b>{esrbFound.value}</b>{' ' + esrbFound.label}</span>
                    </div>,
                gameGenre: relElement.gameGenre,
                gameImageURL: relElement.gameImageURL,
                gamePlatform:
                    <div className={"w-[100px] p-[3px] text-center txt-arena-white text-[9px] font-bold rounded-md " + arrConsoleBg[`${relElement.gamePlatform}`]}>
                        {relElement.gamePlatform}
                    </div>,
                gameReleaseDate: relElement.gameReleaseDate,
                gameScore: relElement.gameScore,
                gameTitle: relElement.gameTitle,
                options:
                    <RealesesButtonsTable
                        onRefresh={onRefresh}
                        esrb={esrb}
                        plataformas={plataformas}
                        stars={stars}
                        realese={relElement}
                    />
            };
        });
        setNewRealeses(newArr);
    }, [realeses]);

    return (
        <>
            <div className="block overflow-x-auto lg:overflow-x-visible">
                <Styled>
                    <Table columns={columns} data={newRealeses} />
                </Styled>
            </div>
        </>
    );
}

export default RealesesTable;