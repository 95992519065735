import TournamentDropModel from "../../models/TournamentDrop";
import IconThrophie from "../../assets/images/tournaments/03_icon_torneos.png";
import IconLocation from "../../assets/icons/tournaments/31_icon_sucursal.svg";
import IconDate from "../../assets/icons/booking/27_icon_calendar.svg";
import IconMenu2 from "../../assets/icons/01_arena_icon_promos.svg";
import { AiOutlineGift, AiOutlineTeam } from "react-icons/ai";
import { BiJoystickAlt } from "react-icons/bi";
import { GiGameConsole } from "react-icons/gi";

import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../context/global_context";

const TournamentPopCard = ({ data, onResponse } : { data: TournamentDropModel, onResponse: any }) => {
    const closeModal = (close: boolean) => {
        onResponse(close);
    }
    const { globalState,setGlobalState } = useContext(GlobalContext)
    const navigate = useNavigate();
    
    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="w-[600px] lg:w-[1000px] justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none m-auto">
                    <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-[5px] border-b border-solid border-gray-500 rounded-t">
                            <div className="flex items-center space-x-2 pl-4 txt-arena-white font-bold">
                                <img src={IconMenu2} />
                                <span>Promociones y torneos / {data.dropName}</span>
                            </div>
                            <button
                                className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                onClick={() => closeModal(false)}
                            >
                                X
                            </button>
                        </div>
                        <div className="relative p-[20px] lg:p-[50px] flex-auto">
                            <div className="lg:flex lg:items-center space-x-[20px] txt-arena-white">
                                <div className="w-[40%] lg:w-[40%] m-auto">
                                    <img className="w-full img-boxs rounded-lg" src={data.dropImageURL} />
                                </div>
                                <div className="w-full lg:w-[60%] space-y-[20px]">
                                    <div className="w-full block">
                                        <img className="w-[70px] absolute top-[130px] lg:top-[15px] right-[50px] lg:right-[100px]" src={IconThrophie} />
                                    </div>
                                    <div className="text-left text-[16px] space-y-[3px] lg:space-y-[5px]">
                                        <h3 className="text-[25px] font-bold pb-[15px]">{data.dropName}</h3>
                                        <div className="pb-[10px]">
                                            <p>{data.dropDescription}</p>
                                        </div>
                                        <div className="flex items-center space-x-[10px]">
                                            <span className="text-[25px] text-center"><BiJoystickAlt /></span>
                                            <p>{data.dropGameTitle}</p>
                                        </div>
                                        <div className="flex items-center space-x-[10px]">
                                            <span className="text-[25px] text-center"><GiGameConsole /></span>
                                            <p>{data.dropConsole}</p>
                                        </div>
                                        <div className="flex items-center space-x-[10px]">
                                            <span className="text-[25px] text-center"><AiOutlineTeam /></span>
                                            <p>{data.dropGroupMode}</p>
                                        </div>
                                        <div className="flex items-center space-x-[10px]">
                                            <span className="text-[25px] text-center"><AiOutlineGift /></span>
                                            <p>{data.dropReward}</p>
                                        </div>
                                        <div className="flex items-center space-x-[10px]">
                                            <img className="w-[25px]" src={IconDate} />
                                            <p>{data.dropDate}</p>
                                        </div>
                                        <div className="flex items-center space-x-[10px] cursor-pointer lineHover--white" onClick={()=>{
                                            setGlobalState({...globalState, name_location: data.dropLocation})
                                            navigate("/venues/detail");
                                        }}>
                                            <img className="w-[25px]" src={IconLocation} />
                                            <span>{data.dropLocation}</span>
                                        </div>
                                        <div className="pt-[10px]">
                                            <p>{data.drpoTerms}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile */}
            <div className="md:hidden block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none w-[85vw] m-auto">
                    <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
                        <div className="flex items-center justify-between p-[5px] border-b border-solid border-gray-500 rounded-t">
                            <div className="flex items-center space-x-2 pl-4 txt-arena-white font-bold">
                                <img src={IconMenu2} />
                                <span>Promociones y torneos</span>
                            </div>
                            <button
                                className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                onClick={() => closeModal(false)}
                            >
                                X
                            </button>
                        </div>
                        <div className="relative flex-auto">
                            <div className="p-[2vw]">
                                <div className="space-x-[2vw] txt-arena-white">
                                    <div className="w-full">
                                        <img className="w-[15vw] absolute top-[0vw] right-[4vw]" src={IconThrophie} />
                                    </div>
                                    <div className="w-[70vw] pt-[4vw] m-auto">
                                        <img className="img-boxs rounded-lg" src={data.dropImageURL} />
                                    </div>
                                    <div className="w-full space-y-[3vw]">
                                        <div className="text-left text-[4.5vw] space-y-[3vw] px-[2vw]">
                                            <h3 className="text-[6vw] font-bold pb-[2vw] pt-[4vw]">{data.dropName}</h3>
                                            <div className="pb-[2vw]">
                                                <p>{data.dropDescription}</p>
                                            </div>
                                            <div className="flex items-center space-x-[3vw]">
                                                <span className="text-[6vw] text-center"><BiJoystickAlt /></span>
                                                <p>{data.dropGameTitle}</p>
                                            </div>
                                            <div className="flex items-center space-x-[3vw]">
                                                <span className="text-[6vw] text-center"><GiGameConsole /></span>
                                                <p>{data.dropConsole}</p>
                                            </div>
                                            <div className="flex items-center space-x-[3vw]">
                                                <span className="text-[6vw] text-center"><AiOutlineTeam /></span>
                                                <p>{data.dropGroupMode}</p>
                                            </div>
                                            <div className="flex items-center space-x-[3vw]">
                                                <span className="text-[6vw] text-center"><AiOutlineGift /></span>
                                                <p>{data.dropReward}</p>
                                            </div>
                                            <div className="flex items-center space-x-[3vw]">
                                                <img className="w-[6.5vw]" src={IconDate} />
                                                <p>{data.dropDate}</p>
                                            </div>
                                            <div className="flex items-center space-x-[3vw]">
                                                <img className="w-[6.5vw]" src={IconLocation} />
                                                <span>{data.dropLocation}</span>
                                            </div>
                                            <div className="pt-[3vw]">
                                                <p>{data.drpoTerms}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black" onClick={() => closeModal(false)}></div>
        </>
    );
}

export default TournamentPopCard;