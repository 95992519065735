import { useState, useEffect } from "react";
import { IDCompany } from "../../global/global";
import { sendBirthdayMail } from "../../services/Birthday";
import { validateMail, validatePhone, validateString } from "../../utils/Validators";
import { FaQuestionCircle } from "react-icons/fa";
import { getCurrentStringDate } from "../../utils/Formats";
import { Fade } from "react-awesome-reveal";
import BirthdayModel from "../../models/Birthday";
import Select from "../../components/select/Select";

const BirthdayForm = ({
    duration,
    venues,
    events,
    onWarning,
    onSuccess,
    onStatus,
    onClosePop,
    statusPop,
    onLoading
}: {
    duration: Array<Object>,
    venues: Array<Object>,
    events: Array<any>,
    onWarning: any,
    onSuccess: any,
    onStatus: any,
    onClosePop: any,
    statusPop: any,
    onLoading: any
}) => {
    const [terms, setTerms] = useState<boolean>(false);

    const [currentDate, setCurrentDate] = useState<string>("");
    const [birthday, setBirthday] = useState({
        companyID: { value: `${IDCompany}`, isCorrect: true },
        userName: { value: "", isCorrect: false },
        userMail: { value: "", isCorrect: false },
        userPhone: { value: "", isCorrect: false },
        numberOfPeople: { value: 0, isCorrect: false },
        partyType: { value: "Cumpleaños", isCorrect: true },
        venueID: { value: "", isCorrect: false },
        partyMessage: { value: "", isCorrect: false },
        partyDate: { value: "", isCorrect: false },
        partyHour: { value: "", isCorrect: false },
        partyTime: { value: "", isCorrect: false }
    });

    const rooms = [
        { label: "Next Level Room", value: "Next Level Room" },
        { label: "Retro Room", value: "Retro Room" },
        { label: "Platinum Room", value: "Platinum Room" },
        { label: "Versus Zone", value: "Versus Zone" },
        { label: "Immersive Zone", value: "Immersive Zone" },

    ]

    const [reservation, setReservation] = useState({
        hoursR: { value: "", isCorrect: false },
        minutesR: { value: "", isCorrect: false },
        reserveRooms: { value: "", isCorrect: false },
        friendsR: { value: "", isCorrect: false }
    })
    const friendsOptions = [
        // { label: "Solo", value: '0' },
        // { label: "01", value: '1' },
        { label: "02", value: '2' },
        { label: "03", value: '3' },
        { label: "04", value: '4' },
        { label: "05", value: '5' },
        { label: "06", value: '6' },
        { label: "07", value: '7' },
        { label: "08", value: '8' },
        { label: "09", value: '9' },
        { label: "10", value: '10' },
    ];

    const hours = [
        { label: '12', value: "12" },
        { label: '13', value: "13" },
        { label: '14', value: "14" },
        { label: '15', value: "15" },
        { label: '16', value: "16" },
        { label: '17', value: "17" },
        { label: '18', value: "18" },
        { label: '19', value: "19" },
        { label: '20', value: "20" },
    ]
    const minutes = [
        { label: '00', value: "00" },
        { label: '01', value: "01" },
        { label: '02', value: "02" },
        { label: '03', value: "03" },
        { label: '04', value: "04" },
        { label: '05', value: "05" },
        { label: '06', value: "06" },
        { label: '07', value: "07" },
        { label: '08', value: "08" },
        { label: '09', value: "09" },
        { label: '10', value: "10" },
        { label: '11', value: "11" },
        { label: '12', value: "12" },
        { label: '13', value: "13" },
        { label: '14', value: "14" },
        { label: '15', value: "15" },
        { label: '16', value: "16" },
        { label: '17', value: "17" },
        { label: '18', value: "18" },
        { label: '19', value: "19" },
        { label: '20', value: "20" },
        { label: '21', value: "21" },
        { label: '22', value: "22" },
        { label: '23', value: "23" },
        { label: '24', value: "24" },
        { label: '25', value: "25" },
        { label: '26', value: "26" },
        { label: '27', value: "27" },
        { label: '28', value: "28" },
        { label: '29', value: "29" },
        { label: '30', value: "30" },
        { label: '31', value: "31" },
        { label: '32', value: "32" },
        { label: '33', value: "33" },
        { label: '34', value: "34" },
        { label: '35', value: "35" },
        { label: '36', value: "36" },
        { label: '37', value: "37" },
        { label: '38', value: "38" },
        { label: '39', value: "39" },
        { label: '40', value: "40" },
        { label: '41', value: "41" },
        { label: '42', value: "42" },
        { label: '43', value: "43" },
        { label: '44', value: "44" },
        { label: '45', value: "45" },
        { label: '46', value: "46" },
        { label: '47', value: "47" },
        { label: '48', value: "48" },
        { label: '49', value: "49" },
        { label: '50', value: "50" },
        { label: '51', value: "51" },
        { label: '52', value: "52" },
        { label: '53', value: "53" },
        { label: '54', value: "54" },
        { label: '55', value: "55" },
        { label: '56', value: "56" },
        { label: '57', value: "57" },
        { label: '58', value: "58" },
        { label: '59', value: "59" },
    ]

    const onResponseSelect = (opt: any) => {
        let arrCurrent: Array<Object> = [];

        if (opt.target.name === "partyTime") {
            arrCurrent = duration;
        } else if (opt.target.name === "venueID") {
            arrCurrent = venues;
        }

        setBirthday({ ...birthday, [opt.target.name]: { value: Object(arrCurrent[opt.target.value]).value, isCorrect: true } });
    }


    const onResponseHour = (opt: any) => {
        console.log(Object(hours[opt.target.value]).value)
        setReservation({ ...reservation, hoursR: { value: Object(hours[opt.target.value]).value, isCorrect: true } });
    }
    const onResponseMinutes = (opt: any) => {
        console.log(Object(minutes[opt.target.value]).value)
        setReservation({ ...reservation, minutesR: { value: Object(minutes[opt.target.value]).value, isCorrect: true } });
    }
    const onResponseRooms = (opt: any) => {
        setReservation({ ...reservation, reserveRooms: { value: Object(rooms[opt.target.value]).value, isCorrect: true } });
    }
    const onResponseFriens = (opt: any) => {
        console.log(Object(friendsOptions[opt.target.value]).value)
        setReservation({ ...reservation, friendsR: { value: Object(friendsOptions[opt.target.value]).value, isCorrect: true } });
    }

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (evt.target.name === "partyDate") {
            if (birthday.venueID.isCorrect) {
                if (new Date().getFullYear() <= (new Date(evt.target.value).getFullYear() + 1)) {
                    let currentVenue: string = "";

                    let selectVenue: any = null;
                    if (window.innerWidth <= 640) {
                        selectVenue = document.getElementById("select-arenaMC") as HTMLSelectElement | null;
                    } else {
                        selectVenue = document.getElementById("select-arenaDC") as HTMLSelectElement | null;
                    }
                    if (selectVenue !== null) currentVenue = `${Object(venues[Number(selectVenue.value)]).label}`;

                    const eventFound = events.find((evtEle: any) => {
                        if (evtEle.eventDate === evt.target.value) {
                            const venueExist = evtEle.eventVenue.find((vneEle: string) => vneEle === currentVenue);
                            if (venueExist) return evtEle
                        }
                    });

                    if (eventFound) {
                        onWarning("No se puede reservar en esa fecha ya que existe actualmente un evento de Arena Movistar");

                        let inputDate: any = null;
                        if (window.innerWidth <= 640) {
                            inputDate = document.getElementById("inputdate-arenaMC") as HTMLSelectElement | null;
                        } else {
                            inputDate = document.getElementById("inputdate-arenaDC") as HTMLSelectElement | null;
                        }
                        if (inputDate !== null) inputDate.value = "";
                    } else {
                        document.getElementsByName(evt.target.name)[0].style.borderColor = "rgb(243 244 246)";
                        setBirthday({ ...birthday, [evt.target.name]: { value: evt.target.value, isCorrect: true } });
                    }
                }
            } else {
                onWarning("Favor de seleccionar primero la Sucursal");
                let inputDate: any = null;
                if (window.innerWidth <= 640) {
                    inputDate = document.getElementById("inputdate-arenaMC") as HTMLSelectElement | null;
                } else {
                    inputDate = document.getElementById("inputdate-arenaDC") as HTMLSelectElement | null;
                }
                if (inputDate !== null) inputDate.value = "";
            }
        } else {
            let validateInput: boolean = true;

            if (evt.target.name === "userName" || evt.target.name === "partyType") {
                validateInput = validateString(evt.target.value);
            } else if (evt.target.name === "userMail") {
                validateInput = validateMail(evt.target.value);
            } else if (evt.target.name === "userPhone") {
                validateInput = validatePhone(evt.target.value);
            } else {
                validateInput = true;
            }

            if (!validateInput) {
                document.getElementsByName(evt.target.name)[0].style.borderColor = "red";
                setBirthday({ ...birthday, [evt.target.name]: { value: evt.target.value, isCorrect: false } });
            } else {
                document.getElementsByName(evt.target.name)[0].style.borderColor = "rgb(243 244 246)";
                setBirthday({ ...birthday, [evt.target.name]: { value: evt.target.value, isCorrect: true } });
            }
        }
    }

    const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();
        console.log(birthday, "obj")
        if (terms) {
            if (
                birthday.userName.isCorrect &&
                birthday.userMail.isCorrect &&
                birthday.userPhone.isCorrect &&
                // birthday.numberOfPeople.isCorrect &&
                birthday.partyType.isCorrect &&
                birthday.partyMessage.isCorrect &&
                birthday.partyDate.isCorrect &&
                // birthday.partyHour &&
                reservation.reserveRooms.isCorrect &&
                // reservation.friendsR.isCorrect && 
                birthday.partyTime.value &&
                birthday.venueID.isCorrect
            ) {
                onLoading(true);
                if (reservation.hoursR.isCorrect && reservation.minutesR.isCorrect && reservation.friendsR.isCorrect) {
                    const birthdayData: BirthdayModel = {
                        companyID: birthday.companyID.value,
                        venueID: birthday.venueID.value,
                        userName: birthday.userName.value,
                        userMail: birthday.userMail.value,
                        userPhone: birthday.userPhone.value,
                        numberOfPeople: reservation.friendsR.value,
                        partyType: birthday.partyType.value,
                        partyMessage: birthday.partyMessage.value,
                        partyDate: birthday.partyDate.value,
                        partyHour: `${reservation.hoursR.value}:${reservation.minutesR.value}`,
                        partyTime: birthday.partyTime.value,
                        reserveRoom: reservation.reserveRooms.value
                    }
                    
                    new Promise<void>((resolve, reject) => {
                        sendBirthdayMail(birthdayData).then(res => {
                            onLoading(false);
                            if (res && res.data) {
                                setBirthday({
                                    ...birthday,
                                    companyID: { value: `${IDCompany}`, isCorrect: false },
                                    userName: { value: "", isCorrect: false },
                                    userMail: { value: "", isCorrect: false },
                                    userPhone: { value: "", isCorrect: false },
                                    numberOfPeople: { value: 0, isCorrect: false },
                                    partyType: { value: "Cumpleaños", isCorrect: true },
                                    venueID: { value: "", isCorrect: false },
                                    partyMessage: { value: "", isCorrect: false },
                                    partyDate: { value: "", isCorrect: false },
                                    partyHour: { value: "", isCorrect: false },
                                    partyTime: { value: "", isCorrect: false }
                                });
                                setReservation({ ...reservation, 
                                    hoursR: { value: '', isCorrect: false }, 
                                    minutesR: { value: '', isCorrect: false }, 
                                    reserveRooms: { value: '', isCorrect: false } 
                                });
                                setTerms(false);
                                let venueArena = document.getElementById("select-arena") as HTMLSelectElement | null;
                                if (venueArena !== null) venueArena.value = "";

                                const partyTimeInput = document.getElementsByName("partyTime") as any;
                                if (partyTimeInput !== null) for (let i = 0; i < partyTimeInput.length; i++) partyTimeInput[i].value = "";

                                const venueIDInput = document.getElementsByName("venueID") as any;
                                if (venueIDInput !== null) for (let i = 0; i < venueIDInput.length; i++) venueIDInput[i].value = "";

                                const termsInput = document.getElementsByName("terms") as any;
                                if (termsInput !== null) for (let i = 0; i < termsInput.length; i++) termsInput[i]['checked'] = false;

                                onStatus("success");
                            } else {
                                onStatus("error");
                            }
                        });
                        setTimeout(function () {
                            resolve();
                        }, 1000);
                    });
                } else {
                    onLoading(false);
                    onWarning(true, "Favor de seleccionar un horario.");
                }
            } else {
                onStatus("warning");
            }
        } else {
            onWarning("Favor de llenar todo el formulario");
        }
    }

    useEffect(() => {
        setCurrentDate(getCurrentStringDate());

    }, []);

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block w-full justify-center">
                <form onSubmit={handleSubmit}>
                    <div className="space-y-[25px] px-[35px] text-left">
                        <Fade cascade damping={0.1}>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="nameLabel">
                                    Tu nombre completo
                                    <span title="Eg. Luis López" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="nameInput"
                                    type="text"
                                    name="userName"
                                    onChange={handleChange}
                                    value={birthday.userName.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="emailLabel">
                                    Tu correo electrónico
                                    <span title="Eg. luislopez@ejemplo.com" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="emailInput"
                                    type="text"
                                    name="userMail"
                                    onChange={handleChange}
                                    value={birthday.userMail.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="phoneLabel">
                                    Teléfono
                                    <span title="Eg. 55 0000 0000" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="phoneInput"
                                    type="text"
                                    name="userPhone"
                                    onChange={handleChange}
                                    value={birthday.userPhone.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="peopleLabel">
                                    Número de personas
                                    <span title="Eg. 15 personas" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                {/* <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="peopleInput"
                                    type="text"
                                    name="numberOfPeople"
                                    onChange={handleChange}
                                    value={birthday.numberOfPeople.value}
                                /> */}
                                <Select
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="select-arenaDC"
                                    options={friendsOptions}
                                    onResponse={onResponseFriens}
                                    name="venueID"
                                    placeholder="Selecciona la sucursal..."
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="venueLabel">
                                    Sucursal
                                    <span title="Selecciona la sucursal del cumpleaños" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <Select
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="select-arenaDC"
                                    options={venues}
                                    onResponse={onResponseSelect}
                                    name="venueID"
                                    placeholder="Selecciona la sucursal..."
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="venueLabel">
                                    Salas
                                    <span title="Selecciona la sucursal del cumpleaños" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <Select
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="select-arenaDC"
                                    options={rooms}
                                    onResponse={onResponseRooms}
                                    name="venueID"
                                    placeholder="Selecciona la sucursal..."
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="messageLabel">
                                    Mensaje
                                    <span title="Eg. Es un cumpleaños para adolescentes" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="messageInput"
                                    type="text"
                                    name="partyMessage"
                                    onChange={handleChange}
                                    value={birthday.partyMessage.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="dateLabel">
                                    Día
                                    <span title="Día del evento dd/mm/aaaa" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="inputdate-arenaDC"
                                    type="date"
                                    min={currentDate}
                                    name="partyDate"
                                    onChange={handleChange}
                                    value={birthday.partyDate.value}
                                />
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="hourLabel">
                                    Hora
                                    <span title="Horario 12:00PM - 09:00PM" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                {/* <input
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    id="hourInput"
                                    type="time"
                                    name="partyHour"
                                    onChange={handleChange}
                                    value={birthday.partyHour.value}
                                /> */}
                                <div className="flex  items-center">
                                    <Select
                                        className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline w-[70px]"
                                        options={hours}
                                        onResponse={onResponseHour}
                                        name="numberOfHours"
                                        id="select-arena"
                                        placeholder="Hrs"
                                    />
                                    <div className="txt-arena-white flex mx-2">  :  </div>
                                    <Select
                                        className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline w-[70px]"
                                        options={minutes}
                                        onResponse={onResponseMinutes}
                                        name="numberOfMinutes"
                                        id="select-arena"
                                        placeholder="Min"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="flex items-center txt-arena-orange text-[14px] font-bold" htmlFor="timeLabel">
                                    Duración
                                    <span title="Selecciona el tiempo de duración del evento" className="pl-2 text-[10px] font-bold">
                                        <FaQuestionCircle />
                                    </span>
                                </label>
                                <Select
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                    options={duration}
                                    onResponse={onResponseSelect}
                                    name="partyTime"
                                    id="select-arena"
                                    placeholder="Selecciona la duración..."
                                />
                            </div>
                            <div className="flex space-x-[12px] py-[12px] m-auto w-[70%] text-[14px] txt-arena-white">
                                <input type="checkbox" name="terms" onChange={() => { setTerms(!terms) }} />
                                <p>
                                    {'Acepto el '}
                                    <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "privacy" }) }}>
                                        aviso de privacidad
                                    </button>
                                    {' y, '}
                                    <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "terms_conditions" }) }}>
                                        términos y condiciones
                                    </button>
                                </p>
                            </div>
                            <div>
                                <div className="pb-[12px] px-[35px] m-auto scale-100 hover:scale-110 ease-in duration-150">
                                    <button className="btn-arena-primary" type="submit">
                                        <span className="tracking-[2px]">ENVIAR</span>
                                    </button>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </form>
            </div>

            {/* Mobile */}
            <form onSubmit={handleSubmit} className="md:hidden">
                <div className="space-y-[5.2vw] px-[3.2vw] text-left">
                    <Fade cascade damping={0.1}>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="nameLabel">Tu nombre</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="nameInput"
                                type="text"
                                name="userName"
                                onChange={handleChange}
                                value={birthday.userName.value}
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="emailLabel">Tu correo electrónico</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="emailInput"
                                type="text"
                                name="userMail"
                                onChange={handleChange}
                                value={birthday.userMail.value}
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="phoneLabel">Teléfono</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="phoneInput"
                                type="text"
                                name="userPhone"
                                onChange={handleChange}
                                value={birthday.userPhone.value}
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="peopleLabel">Número de personas</label>
                            {/* <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="peopleInput"
                                type="text"
                                name="numberOfPeople"
                                onChange={handleChange}
                                value={birthday.numberOfPeople.value}
                            /> */}
                            <Select
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[12px] text-[16px] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="select-arenaDC"
                                options={friendsOptions}
                                onResponse={onResponseFriens}
                                name="venueID"
                                placeholder="Selecciona la sucursal..."
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="venueLabel">
                                Sucursal
                            </label>
                            <Select
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="select-arenaMC"
                                options={venues}
                                onResponse={onResponseSelect}
                                name="venueID"
                                placeholder="Selecciona la sucursal..."
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="messageLabel">Mensaje</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="messageInput"
                                type="text"
                                name="partyMessage"
                                onChange={handleChange}
                                value={birthday.partyMessage.value}
                            />
                        </div>
                        <div>
                            <label className="block txt-arena-orange text-[3.5vw] font-bold" htmlFor="dateLabel">Día</label>
                            <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="inputdate-arenaMC"
                                type="date"
                                min={currentDate}
                                name="partyDate"
                                onChange={handleChange}
                                value={birthday.partyDate.value}
                            />
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="hourLabel">
                                Hora
                            </label>
                            {/* <input
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                id="hourInput"
                                type="time"
                                name="partyHour"
                                onChange={handleChange}
                                value={birthday.partyHour.value}
                            /> */}
                            <div className="flex  items-center">
                                <Select
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline w-[70px]"
                                    options={hours}
                                    onResponse={onResponseHour}
                                    name="numberOfHours"
                                    id="select-arena"
                                    placeholder="Hrs"
                                />
                                <div className="txt-arena-white flex mx-2">  :  </div>
                                <Select
                                    className="appearance-none border-b border-gray-100 bg-transparent w-full py-2 text-gray-100 leading-tight focus:outline-none focus:shadow-outline w-[70px]"
                                    options={minutes}
                                    onResponse={onResponseMinutes}
                                    name="numberOfMinutes"
                                    id="select-arena"
                                    placeholder="Min"
                                />
                            </div>
                        </div>
                        <div>
                            <label className="txt-arena-orange text-[3.5vw] font-bold" htmlFor="timeLabel">
                                Duración
                            </label>
                            <Select
                                className="appearance-none border-b border-gray-100 bg-transparent w-full py-[2vw] text-[4vw] text-gray-100 leading-tight focus:outline-none focus:shadow-outline"
                                options={duration}
                                onResponse={onResponseSelect}
                                name="partyTime"
                                id="select-arena"
                                placeholder="Ingresa la duración..."
                            />
                        </div>
                        <div className="flex space-x-[1vw] py-[2vw] m-auto w-[60vw] text-[3.3vw] txt-arena-white">
                            <input type="checkbox" name="terms" onChange={() => { setTerms(!terms) }} />
                            <p>
                                {'Acepto el '}
                                <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "privacy" }) }}>
                                    aviso de privacidad
                                </button>
                                {' y, '}
                                <button className="txt-arena-orange font-bold" type="button" onClick={() => { statusPop({ status: true, documentStr: "terms_conditions" }) }}>
                                    términos y condiciones
                                </button>
                            </p>
                        </div>
                        <div>
                            <div className="pb-[10vw] px-[5vw] m-auto">
                                <button className="btn-arena-primary" type="submit">ENVIAR</button>
                            </div>
                        </div>
                    </Fade>
                </div>
            </form>
        </>
    );
}

export default BirthdayForm;