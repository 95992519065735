import { useState } from "react";
import { ReactComponent as MenuIcon } from "../../assets/icons/01_hamburguesa_icon.svg";
import SidebarButtons from "./SidebarButtons";
import "./Sidebar.css";

const Sidebar = () => {
    const [showSidebar, setShowSidebar] = useState(false);

    return (
        <>
      {showSidebar ? (
        <button
          className="flex justify-start txt-arena-white top-6 z-50 ml-0 md:text-[18px]"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          CERRAR X
        </button>
      ) : (
        <button
          className="flex justify-between txt-arena-white"
          type="button"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          <MenuIcon />
          <span className="hidden p-3 lg:flex">Menú</span>
        </button>
      )}

      <SidebarButtons onClose={() => setShowSidebar(false) } status={showSidebar} />
    </>
    );
}

export default Sidebar;