import { useEffect, useState } from "react";

const VenuesMapPop = ({ mapStr, onCloseModal } : { mapStr: string, onCloseModal: any }) => {
    const [newStrMap, setNewStrMap] = useState<string>("");

    useEffect(() => {
        let newSizeMap: string = "";

        if (window.innerWidth < 400) {
            newSizeMap = `width="300" height="300"`;
        } else {
            newSizeMap = `width="600" height="420"`;
        }

        mapStr = mapStr.replace(`width="400" height="300"`, newSizeMap);
        mapStr = mapStr.replace(`width="600" height="450"`, newSizeMap);
        mapStr = mapStr.replace(`width="600" height="500"`, newSizeMap);
        mapStr = mapStr.replace(`width="800" height="600"`, newSizeMap);
        
        setNewStrMap(mapStr);
    }, [mapStr]);

    return (
        <>
            <div className="block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[320px] md:w-[620px]">
                        <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-[5px] lg:p-[10px] border-b border-solid border-gray-500 rounded-t">
                                <button
                                    className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                    onClick={() => onCloseModal(false)}
                                >
                                    X
                                </button>
                            </div>
                            <div className="relative flex-auto p-[10px]">                                
                                <div className="m-auto">
                                    <div id="divVenuesMapURL" dangerouslySetInnerHTML={{__html: newStrMap}} ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default VenuesMapPop;