import { useState } from "react";

const VenuesImages = ({ imageVenues, imageIndex, onCloseModal } : { imageVenues: Array<string>, imageIndex: number, onCloseModal: any }) => {
    const [imageCurrent, setImageCurrent] = useState<number>(imageIndex);
    const [arrImages] = useState<Array<string>>(imageVenues);

    return (
        <>
            {/* Desktop */}
            <div className="hidden md:block">
                <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="w-[600px] lg:w-[800px]">
                        <div className="border-0 rounded-lg shadow-lg flex-col w-full glassmorphism outline-none focus:outline-none">
                            <div className="flex items-start justify-between p-[5px] lg:p-[10px] border-b border-solid border-gray-500 rounded-t">
                                <button
                                    className="p-[10px] ml-auto border-0 txt-arena-white float-right text-[20px] leading-none outline-none focus:outline-none"
                                    onClick={() => onCloseModal(false)}
                                >
                                    X
                                </button>
                            </div>
                            <div className="relative p-[30px] space-y-[10px] flex-auto">
                                <div className="w-[500px] lg:w-[600px] m-auto">
                                    <img className="w-auto m-auto" src={arrImages[imageCurrent]} />
                                </div>
                                <div className="w-[500px] lg:w-[600px] flex justify-center items-center space-x-[10px] rounded-lg m-auto">
                                    {
                                        arrImages.map((imageElement: any, i: number) => {
                                            return (
                                                <div key={i}>
                                                    <button type="button" onClick={() => setImageCurrent(i)}>
                                                        <img className="w-[120px] lg:w-[145px] rounded-lg" src={imageElement} />
                                                    </button>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </>
    );
}

export default VenuesImages;